*,
html {
  box-sizing: border-box;
}
@font-face {
  font-family: camphor;
  src: url(../assets/fonts/camphor.otf);
}
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #333;
  line-height: 1.5;
  overflow-x: hidden;
  background-color: #fff !important;
  font-family: 'camphor', sans-serif;
}
a,
a:hover,
a:focus {
  color: inherit;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  text-decoration: inherit;
}
p {
  margin: 0;
}
.form-control:focus {
  border-color: none !important;
  box-shadow: none !important;
}
/*new header*/
@font-face {
  font-family: magettasBold;
  src: url(../assets/fonts/magettasbold.otf);
}
@font-face {
  font-family: googlesens;
  src: url(../assets/fonts/GoogleSans.ttf);
}
.close:focus {
  outline: none;
}
.navbar {
  width: 100%;
  z-index: 9999;
}
.navbar-brand {
  font-family: magettasBold !important;
  font-size: 30px;
  color: #1ec8c8 !important;
}
.text_black {
  color: #333;
}
.text_black a {
  color: #fff !important;
  text-decoration: underline !important;
  margin-left: 15px !important;
}
.btn-primary {
  background: #1ec8c8 !important;
  color: #fff !important;
  border: 1px solid #1ec8c8 !important;
  cursor: pointer;
}
.btn-primary:hover {
  background: #ff9600 !important;
  border: 1px solid #ff9600 !important;
  color: #fff;
  border: none;
  cursor: pointer;
}
.btn-danger {
  color: #fff !important;
}
.btn-primary.focus,
.btn-primary:focus,
.btn-danger:focus,
.btn-scheduale:focus,
.ex_btn_no:focus {
  box-shadow: none !important;
  border-color: none !important;
  outline: none !important;
}

.nav_bg {
  background: #fff;
}
.btn-bg {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  background: #1ec8c8;
}
.btn-bg:hover {
  color: #fff !important;
}
.logo_img {
  width: 40px;
}

/*slider*/
.slider_bgimage {
  /* background-image: url("../assets/images/home/slider_bg.jpg"); */
  background: #007c7c;
  min-height: 350px;
  background-size: cover;
  padding: 35px;
  background-position: 40%;
  border-radius: 40px;
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.slider_bgimage .sliderImg img{
  position: sticky;
  top: 0;
  margin-top: -95px;
  margin-bottom: -35px;
}
.bg_title h2 {
  margin: 20px auto;
  color: #fff;
  text-align: center;
  left: 0;
  right: 0;
  font-size: 32px;
}
.bg_title h2 b {
  color: #98ffff;
}
.bg_title_search h2 {
  position: absolute;
  top: 15%;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  left: 0;
  right: 0;
  font-size: 30px;
}
.bg_title_search h2 b {
  color: #FFC107;
}
.bg_title_dash h2 {
  position: absolute;
  top: 45%;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  left: 0;
  right: 0;
  font-size: 30px;
}
.bg_title_dash h2 b {
  color: #baffff;
}

.location,
.specialization,
.go_search {
  height: 40px;
  padding: 5px 15px;
}
.search .detect {
  position: absolute;
  top: 3px;
  right: 15px;
  background: #ececec;
  padding: 5px 10px;
  border-radius: 3px;
}
.loc_sec{
  position: relative;
}
.loc_sec span,
.search2_icon {
  position: absolute;
  padding: 10px;
  font-size: 20px;
}
.search .location, .search .specialization, .search .insurance {
  border-radius: 4px;
  border: 1px solid #999;
  padding: 0 100px 0 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.search .specialization {
  border: 1px solid #999;
}
.search_left_icon {
  background: #1ec8c8;
  padding: 9px;
  font-size: 22px;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
}
.search_left_icon:hover {
  background: #ff9600;
}
.location:focus,
.specialization:focus {
  outline: none !important;
}
.go_search {
  padding: 7px 15px 10px 15px;
  font-size: 24px;
  border-radius: 4px;
}

.insurance {
  height: 40px !important;
  padding: 5px 25px 5px 5px;
  border-radius: 0px;
  border: 0;
  border-left: 1px solid #999;
}
.insurance_option {
  background: #eee;
  padding: 15px !important ;
}
.insurance_popup_ul li a p:hover {
  color: #1ec8c8;
}
.drop_icon {
  position: absolute;
  top: 8px;
  right: 12px;
  cursor: pointer;
}
/*search popup*/
.search_popup {
  display: none;
}
.search_popup_show {
  display: block;
}
.search_popup {
  border: 1px solid #888;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0px 2px 15px #0000004f;
  z-index: 9;
  position: absolute;
  top: 41px;
  left: 15px;
  right: 15px;
}
.popup_hr_margin {
  margin: 0px;
}
.search_popup h4 {
  background: #eee;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
}
.popular_text p {
  background: #eee;
  padding: 10px;
  border-radius: 19px;
  margin: 0 10px 8px 10px;
}
.search_popup_ul {
  padding: 0;
  list-style: none;
  cursor: pointer;
  margin-bottom: 0px;
}
.search_popup_ul li {
  padding: 5px 0;
  border-bottom: 0.5px solid #ddd;
}
.search_popup_ul li:hover {
  background-color: #eee;
}
.search_popup_ul li a p:hover {
  color: #1ec8c8;
}
.search_popup_ul li i {
  background: #1ec8c88c;
  padding: 8px;
  border-radius: 50%;
}
.speciality_text {
  color: #666;
  font-size: 12px;
}
/*search popup*/
.insurance_popup {
  display: none;
  overflow: auto;
  height: 715%;
  border: 1px solid #888;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0px 2px 15px #0000004f;
  z-index: 9;
  position: absolute;
  top: 41px;
  left: 0;
  right: 0;
  transition: all 0.4s ease-in-out;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
}
.insurance_popup_show {
  display: block;
}
.insurance_popup {
  background-color: #fff;
  box-shadow: 0px 2px 15px #0000004f;
  z-index: 9;
  position: absolute;
  top: 41px;
  left: 0;
  right: 0;
}
.popup_hr_margin {
  margin: 0px;
}
.insurance_popup_ul {
  padding: 0;
  list-style: none;
  cursor: pointer;
  margin-bottom: 0px;
}
.insurance_popup_ul li {
  padding: 10px 10px;
}
.insurance_popup_ul li:hover {
  background-color: #eee;
}
/*app*/
.padd-130 {
  padding-top: 130px;
}
.get_app_bg {
  background: #fff;
}
.app_title {
  font-size: 38px;
  font-weight: 800;
  letter-spacing: 4px;
}
.app_title span {
  color: #1ec8c8;
}
.app_info {
  padding-left: 25px;
}
.app_info li {
  font-size: 21px;
  margin: 10px 0 10px 0;
}
.store_img a {
  cursor: pointer;
}
.app_demo {
  text-align: right !important;
}

/*doctor*/
.doctor_bg {
  background: #fff;
  margin-top: 25px;
  padding: 50px 0;
}
.doctor_title {
  font-size: 38px;
  font-weight: 800;
  letter-spacing: 3px;
}
.doctor_subtitle {
  font-size: 24px;
}
.get_started {
  padding: 10px 20px 10px 20px;
}
.get_started:hover {
  text-decoration: none;
}

.padd-95 {
  padding-top: 95px;
}
.footer_bg {
  background: #007c7c;
}
.footer_tile {
  font-weight: 800;
  color: #fff;
}
hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-top: 1px solid #666;
  width: 100%;
}
.quick_ul {
  padding-left: 20px;
}
.quick_ul li {
  margin: 10px 0px 10px 0px;
  color: #fff;
  cursor: pointer;
}
.contact {
  padding-left: 20px;
  line-height: 1.7;
}
.contact li {
  color: #fff;
}
.social-icons {
  padding-left: 20px;
  list-style: none;
  display: inline-flex;
  padding: 0;
}
.social-icons li a {
  color: #fff;
  width: 45px;
  height: 45px;
  line-height: 40px;
  font-size: 18px;
  padding: 5px;
}
.social-icons li a i {
  height: 50px;
  width: 50px;
  background: #1ec8c8;
  line-height: 50px;
  text-align: center;
  border-radius: 50px;
}
.social-icons li a i:hover {
  background: #ff9600;
}


/*join now*/
.joinnow_bgimage {
  background-image: url("../assets/images/joinnow/joinnow_slider.jpg");
  height: 650px;
  background-size: cover;
}
.list_patient {
  position: absolute;
  top: 63%;
  right: 25%;
  padding: 10px;
}

.navbar {
  margin-bottom: 0px !important;
  border: 1px solid #ddd;
}
.media-heading {
  font-size: 17px !important;
}
.overlay-theme-colored-9:before {
  background-color: #1ec8c894 !important;
}
.header1 {
  padding-top: 70px;
}

.minheight {
  min-height: 770px;
}

/* login */
.login_area {
  background: #fff;
  padding-top: 15px;
}
.login h4,
.login p {
  padding: 0 0 10px 0;
  color: #333;
}
.login {
  padding: 35px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 25px;
}

/* otp page */
.margt_90 {
  margin-top: 0px;
}
.resend {
  font-size: 12px;
  margin-top: 10px;
}
.resend span {
  color: blue;
  text-decoration: underline;
}

/*doctore registration*/
.reg_area {
  height: 450px;
  background: #ddd;
  padding-top: 62px;
}
/* dashboard */
.main_tabs {
  text-align: center;
}
.main_tabs a {
  display: block;
  height: 40px;
  padding: 10px;
  padding: 5px;
  color: #fff;
}
.main_tabs a:hover{
  background: #fff;
  color: #333;
}
.direction_row {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard_box{
  padding: 10px 15px;
}
.dashboard_box .box {
  width: 100%;
  min-height: 225px;
  text-align: center;
  background: #fff;
  border: 1px solid #d1d1d1;
  box-shadow: 0 0 5px #0000001f;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.dashboard_box .box2 ul {
  list-style: none;
}
.appointmentList .img_block{
  width: 100px;
  margin-right: 10px;
}
.dashboard_box .waiting {
  font-size: 30px;
  font-weight: 600;
  color: #1ec8c8;
}
.filter {
  /* background: #1ec8c8; */
  position: relative;
  padding: 5px 15px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter.form-control{
  background-color: #ffffff57;
  color: #333 !important;
  border: 1px solid #ced4da75;
}
.margb_60 {
  margin-bottom: 0px;
}


/* all appointment */

#custom-search-input {
  margin: 0;
  margin-top: 17px;
  padding: 0;
  margin-right: 15px;
}

#custom-search-input .search-query {
  padding-right: 10px;
  padding-right: 4px \9;
  padding-left: 10px;
  padding-left: 4px \9;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

#custom-search-input button {
  border: 0;
  background: none;
  /** belows styles are working good */
  padding: 2px 5px;
  margin-top: 2px;
  position: relative;
  left: -28px;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #d9230f;
}

.search-query:focus + button {
  z-index: 3;
}

.drblock {
  padding: 10px 15px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px #00000029;
  border-radius: 4px;
}
.drblock .img_block{
  width: 100px;
  margin-right: 10px;
}
.drblock .img_block img{
  width: 100%;
  height: auto;
  object-fit: cover;
}
.header {
  position: sticky;
  width: 100%;
  z-index: 999;
  box-shadow: 0 5px 10px #0000001c;
  display: block;
  top: 0;
}
.img_block img {
  border: 2px solid #1ec8c8;
  border-radius: 50%;
}
.docinfo {
  width: 100%;
}
.schedule_btn {
  padding: 5px 15px;
  border: 1px solid #333;
  background: transparent;
  margin-top: 25px;
  text-decoration: inherit;
  font-weight: 600;
}
.pagination .page-item.active .page-link {
  background: #1ec8c8;
  border: 1px solid #1ec8c8;
  color: #fff;
}
.pagination .page-item .page-link {
  color: #1ec8c8;
}
.m_font_10 {
  font-size: 14px;
}
.btn-scheduale {
  border: 1px solid;
}
.btn-scheduale:hover {
  background: #1ec8c8;
  color: #fff;
}

/* new appointment */

.newappoint {
  padding: 0 15px;
  background: #fff;
}

.blood_group h6 {
  padding: 7px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
  font-size: 13px;
}
.blood_btn {
  padding: 5px 10px;
  background: transparent;
  box-shadow: 0 0 3px #0000002e;
  border-radius: 4px;
  width: 100%;
  margin: 0 15px;
  margin-bottom: 10px;
  cursor: pointer;
}
.emergen_btn {
  padding: 5px 0px;
  background: transparent;
  box-shadow: 0 0 3px #0000002e;
  border-radius: 4px;
  width: 100%;
  margin: 0 15px;
  margin-bottom: 10px;
  cursor: pointer;
}
.ex_btn {
  padding: 5px 0px;
  background: transparent;
  box-shadow: 0 0 3px #0000002e;
  border-radius: 4px;
  width: 100%;
  margin: 0 15px;
  margin-bottom: 10px;
  cursor: pointer;
}
.police_btn {
  padding: 5px 0px;
  background: transparent;
  box-shadow: 0 0 3px #0000002e;
  border-radius: 4px;
  width: 100%;
  margin: 0 15px;
  margin-bottom: 10px;
  cursor: pointer;
}
.ex_btn_no {
  padding: 5px 0px;
  background: transparent;
  box-shadow: 0 0 3px #0000002e;
  border-radius: 4px;
  width: 20%;
  margin-bottom: 10px;
}

.blood_btn:hover,.police_btn:hover,.ex_btn:hover,.emergen_btn:hover {
  color: #1ec8c8;
  box-shadow: 0 0 3px #1ec8c8;
}
.boot_btn {
  padding: 5px 15px;
  background: #1ec8c8;
  color: #fff;
  margin-top: 25px;
  text-decoration: inherit;
  font-weight: 600;
}
.box ul {
  list-style: none;
  padding: 0;
}


.tab-wrap {
  position: relative;
  display: flex;
}

input[type="radio"][name="tabs"] {
  position: absolute;
  z-index: -1;
}
input[type="radio"][name="tabs"]:checked + .tab-label-content label {
  color: white;
}
input[type="radio"][name="tabs"]:checked + .tab-label-content .tab-content {
  display: block;
}
input[type="radio"][name="tabs"]:nth-of-type(1):checked ~ .slide {
  left: calc((100% / 2) * 0);
}
input[type="radio"][name="tabs"]:nth-of-type(2):checked ~ .slide {
  left: calc((100% / 2) * 1);
}
input[type="radio"][name="tabs"]:nth-of-type(3):checked ~ .slide {
  left: calc((100% / 2) * 2);
}
input[type="radio"][name="tabs"]:nth-of-type(4):checked ~ .slide {
  left: calc((100% / 2) * 3);
}
input[type="radio"][name="tabs"]:first-of-type:checked ~ .slide {
  left: 0;
}


.slide {
  background: #1ec8c8;
  width: calc(100% / 2);
  height: 4px;
  position: absolute;
  left: 0;
  top: calc(100% - 8px);
  transition: left 0.3s ease-out;
}

.tab-label-content {
  width: 100%;
}
.tab-label-content .tab-content {
  position: absolute;
  left: 16px;
  line-height: 130%;
  display: none;
}


.profile-pic {
  max-width: 100%;
  display: block;
  width: 100%;
  object-fit: cover;
}

.file-upload {
  display: none;
}
.circle {
  border-radius: 50% !important;
  width: 150px;
  height: 150px;
  border: 5px solid #1ec8c8;
  margin: auto;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
  position: relative;
}
img {
  max-width: 100%;
  height: auto;
}
.p-image {
  position: absolute;
  right: 0;
  bottom: 10px;
  background: #1ec8c8;
  color: #fff;
  color: #fff;
  border-radius: 50%;
  line-height: 50%;
  padding: 5px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.p-image:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
  color: #fff !important;
}
.upload-button {
  font-size: 1.2em;
}

.upload-button:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: #999;
}

table td ul {
  list-style: none;
  padding: 0;
}
/*  help tab */
div.bhoechie-tab-container {
  z-index: 10;
  background-color: #ffffff;
  padding: 0 !important;
  border-radius: 4px;
  -moz-border-radius: 4px;
  border: 1px solid #ddd;
  margin-top: 20px;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.175);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  opacity: 0.97;
  filter: alpha(opacity=97);
}
div.bhoechie-tab-menu {
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}
div.bhoechie-tab-menu div.list-group {
  margin-bottom: 0;
}
div.bhoechie-tab-menu div.list-group > a {
  margin-bottom: 0;
}
div.bhoechie-tab-menu div.list-group > a .glyphicon,
div.bhoechie-tab-menu div.list-group > a .fa {
  color: #5a55a3;
}
div.bhoechie-tab-menu div.list-group > a:first-child {
  border-top-right-radius: 0;
  -moz-border-top-right-radius: 0;
}
div.bhoechie-tab-menu div.list-group > a:last-child {
  border-bottom-right-radius: 0;
  -moz-border-bottom-right-radius: 0;
}
div.bhoechie-tab-menu div.list-group > a.active,
div.bhoechie-tab-menu div.list-group > a.active .glyphicon,
div.bhoechie-tab-menu div.list-group > a.active .fa {
  background-color: #1ec8c8;
  background-image: #1ec8c8;
  color: #ffffff;
}
div.bhoechie-tab-menu div.list-group > a.active:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -13px;
  border-left: 0;
  border-bottom: 13px solid transparent;
  border-top: 13px solid transparent;
  border-left: 10px solid #1ec8c8;
}

div.bhoechie-tab-content {
  background-color: #ffffff;
  /* border: 1px solid #eeeeee; */
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

div.bhoechie-tab div.bhoechie-tab-content:not(.active) {
  display: none;
}
.list-group-item.active {
  border-color: #1ec8c8 !important;
}
.help_icon {
  font-size: 25px;
  margin-bottom: 15px;
}

/*5-6-18*/
.menuactive {
  background: #fff !important;
  color: #333 !important;
  border: 3px solid #1ec8c8;
}
.copyright {
  background: #00adad;
}
.copyright_p {
  color: #fff;
  padding: 15px 0;
}
.subheader {
  /* border: 1px solid #ddd; */
  background: #1ec8c8;
}
.options li:hover {
  cursor: pointer;
}

.modal-open .modal {
  z-index: 9999 !important;
}

.modal_icon {
  font-size: 45px;
  color: #ff6600;
}
.modal_icon2 {
  font-size: 45px;
  color: green;
}
.modal_icon3 {
  font-size: 25px;
  color: #1ec8c8;
  border: 1px solid;
  padding: 5px;
}
.modal_icon3_a {
  font-size: 21px;
}
.pl_15 {
  padding-left: 15px;
}
.search_icon {
  background: #1ec8c8;
  padding: 12px;
  border-radius: 4px 0px 0px 4px;
  color: #fff;
  height: 40px;
  margin-right: -2px;
  z-index: 9;
}
.search_icon1 {
  position: absolute;
  top: 0;
  left: 0;
  background: #1ec8c8;
  padding: 11px;
  border-radius: 4px 0px 0px 4px;
  color: #fff;
  z-index: 99;
}
.input-group_span {
  background: #ddd;
  padding: 7px;
  border-radius: 4px 0 0 4px;
}
.circle2 {
  border-radius: 1000px !important;
  overflow: hidden;
  width: 80px;
  height: 80px;
  border: 2px solid #1ec8c8;
  position: absolute;
  /* right: 60%; */
  left: 10px;
}
.ms-choice {
  border: none !important;
}
.ms-choice:focus {
  outline: unset !important;
}
.ms-drop ul > li label {
  margin-bottom: 0 !important;
  height: unset !important;
  text-align: left !important;
  background: #d3d3d3 !important;
  color: #333 !important;
  padding: 5px 10px;
}
.ms-drop {
  left: 0;
}
.ms-drop ul {
  padding: 0 !important;
}
.ms-parent {
  width: 500px !important;
  float: left !important;
}
.td_padd {
  padding: 7px !important;
}

.active_bg {
  background-color: #666;
  color: white;
}
.blood_btn.selected {
  background: #1ec8c8;
  color: #fff;
  font-weight: 800;
}
.emergen_btn.selected {
  background: #1ec8c8;
  color: #fff;
  font-weight: 800;
}
.ex_btn.selected {
  background: #1ec8c8;
  color: #fff;
  font-weight: 800;
}
.police_btn.selected {
  background: #1ec8c8;
  color: #fff;
  font-weight: 800;
}
.circle_profile {
  border: 2px solid;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile_image {
  position: absolute;
  right: 10%;
  top: 100px;
  background: #1ec8c8;
  color: #fff;
  color: #fff;
  border-radius: 50%;
  line-height: 50%;
  padding: 5px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.profile_image i {
  cursor: pointer;
  font-size: 25px;
}

.nav-tabs .nav-link.active {
  color: #1ec8c8 !important;
}

.dropdown_profile {
  position: relative;
  display: inline-block;
}
.dropdown_profile2 {
  position: relative;
  display: inline-block;
  /*    border: 1px solid #fff;*/
  padding: 0 1px;
}
.dropdown_profile2:hover .dropdown_profile-content {
  display: block !important;
}
.dropdown_profile-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  text-align: left;
  right: 0px;
}
.dropdown_profile-content li, .dropdown_profile-content ul{
  padding: 0;
}
.dropdown_profile-content ul{
  border: none;
  box-shadow: 0 2px 5px #00000030;
  border-radius: 5px;
  width: 210px;
  left: -15%;
}
.dropdown_profile-content a {
  display: block;
  padding: 10px 15px;
}

.dropdown_profile:hover .dropdown_profile-content {
  display: block;
}
.ms-drop {
  z-index: 9 !important;
}

.login_btn {
  background: #1ec8c8;
  color: #fff !important;
  width: 50%;
}

.timing_display {
  display: inline-flex;
}
.timing_display p {
  margin-right: 10px;
  font-size: 15px;
}

/*timeline*/

.timeline {
  position: absolute;
  box-sizing: border-box;
  width: 94%;
  height: 0.7rem;
  background-color: #f0efed;
  background-clip: content-box;
  border-width: 1px 0;
  border-style: solid;
  border-color: #fff;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.1), inset 0 0 0.4em rgba(0, 0, 0, 0.1);
}

.entry {
  float: left;
  max-width: 250px;
  position: relative;
  text-align: left;
  /*  margin: 0 1rem;*/
  margin-top: 0.7rem;
}
.entry .dot {
  cursor: pointer;
  position: absolute;
  width: 70px;
  height: 70px;
  left: 50%;
  margin-left: -7rem;
  margin-top: -42px;
  border-radius: 100%;
  box-sizing: border-box;
  background-color: #1ec8c8 !important;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
}
.entry .find {
  background: url(../assets/images/aaa.png) no-repeat;
  background-position: -9px -11px;
  transition: background 0.5s;
  transition: all 0.6s ease-out;
}
.entry .appoint {
  background: url(../assets/images/aaa.png) no-repeat;
  background-position: -96px -11px;
  transition: background 0.2s;
  transition: all 0.6s ease-out;
}
.entry .track {
  background: url(../assets/images/aaa.png) no-repeat;
  background-position: -190px -6px;
  transition: background 0.2s;
  transition: all 0.6s ease-out;
}
.entry:hover .find {
  background-position: 0 -105px;
  transition: background 0.2s;
  width: 85px;
  height: 85px;
  transform: scale(1.01);
  transition: all 0.2s ease-out;
  margin-top: -49px;
}
.entry:hover .appoint {
  background-position: -89px -103px;
  transition: background 0.2s;
  width: 85px;
  height: 85px;
  transform: scale(1.01);
  transition: all 0.2s ease-out;
  margin-top: -49px;
}
.entry:hover .track {
  background-position: -183px -99px;
  transition: background 0.2s;
  width: 85px;
  height: 85px;
  transform: scale(1.01);
  transition: all 0.2s ease-out;
  margin-top: -49px;
}
.howto_text {
  width: 100%;
}
.mainTitle ul, .mainTitle ul li{
  list-style: none;
  padding: 0;
}
.mainTitle h4{
  font-size: 35px;
  font-weight: 600;
  color: #fff;
}
.mainTitle h4 .color_change{
  color: #FFC107;
}

.label {
  display: inline-block;
  position: relative;
  /*  background-color: #b2ffff;*/
  background-clip: padding-box;
  margin-top: 3rem;
  padding: 0.4rem 0.8rem 0.8rem;
  border-radius: 0.2rem;
  -moz-transition: margin-top 0.2s linear, background-color 0.2s linear,
    border-bottom 0.2s linear;
  -o-transition: margin-top 0.2s linear, background-color 0.2s linear,
    border-bottom 0.2s linear;
  -webkit-transition: margin-top 0.2s linear, background-color 0.2s linear,
    border-bottom 0.2s linear;
  transition: margin-top 0.2s linear, background-color 0.2s linear,
    border-bottom 0.2s linear;
  border-bottom: 0 solid transparent;
}
.label .time {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0.4rem;
  padding: 0 0.3rem 0.3rem;
  letter-spacing: 1px;
  display: inline-block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}
.label .detail {
  font-size: 15px;
  text-align: left;
}
.label:before {
  content: "";
  display: block;
  position: absolute;
  background-color: inherit;
  background-clip: content-box;
  box-sizing: border-box;
  width: 1rem;
  height: 1rem;
  left: 50%;
  margin-top: -0.9rem;
  margin-left: -0.5rem;
  transform-origin: center;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
}
.label a {
  text-decoration: none;
  color: #54a6ff;
  font-size: 13px;
  font-weight: 600;
}
.label a:hover {
  text-decoration: none;
  color: #1ec8c8;
  font-size: 15px;
  font-weight: 600;
}

.entry.life .label {
  background-color: #e1f0e4;
}

.entry.life:hover .label {
  background-color: #d0e8d5;
}

/*.entry.study .label {
  background-color: #b2ffff;
}*/

/*.entry.study:hover .label {
  background-color: #d0dbe8;
}*/
.hori_timeline {
  padding: 60px 0;
  background-color: #fff;
}
.hori_timeline2 {
  padding: 60px 20px;
}
.dot div {
  text-align: center;
}
.dot div i {
  font-size: 30px;
  margin-top: 5px;
  color: #008383;
}

/*map*/
.located_box {
  position: absolute;
  height: 195px;
  width: 351px;
  background-color: #fff;
  box-shadow: 0px 2px 15px #8888884f;
  top: 256%;
  left: 2%;
  padding: 20px;
}
.mapIcone {
  font-size: 30px;
  color: #1ec8c8;
}
.located_box h4 {
  font-size: 24px;
  font-weight: 600;
  margin-left: 16px;
}

/*about us*/
.padd_50 {
  padding: 50px;
}
.title_img_text {
  color: #ff9600 !important;
}
.mission {
  background: url(../assets/images/about/mission.png);
  height: 100%;
  width: 100%;
  background-size: cover;
  position: absolute;
}
.mission_text {
  position: absolute;
  top: 0;
  margin-left: 44%;
  padding: 5%;
}
.mission_text h3 {
  font-size: 31px;
  letter-spacing: 2px;
  color: #4a4a4a;
}
.mission_text h4 {
  color: #1ec8c8;
  font-size: 33px;
  font-weight: 600;
  letter-spacing: 2px;
}
.mission_text p {
  line-height: 1.5;
  margin-top: 30px;
}
.aboutus_text {
  text-align: center;
  padding: 61% 100px 50px 100px;
}
.aboutus_text h3 {
  font-size: 31px;
  letter-spacing: 2px;
  color: #4a4a4a;
}
.aboutus_text h4 {
  color: #1ec8c8;
  font-size: 33px;
  font-weight: 600;
  letter-spacing: 2px;
}
.aboutus_text p {
  line-height: 1.5;
}

/*team*/
#team h3 {
  color: #1ec8c8;
  font-size: 33px;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 45px;
}
#team {
  background-color: #f2f2f2;
  padding: 50px 100px 50px 100px;
}
#team .card {
  border: none;
  background: #ffffff;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  border-radius: 0.25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.mainflip {
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 1s;
  -moz-transition: 1s;
  transform:perspective(1000px);
  -moz-transform:perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
  -webkit-transform:perspective(1000px);
  -ms-transform:perspective(1000px);
  -o-transform:perspective(1000px);
}

.frontside {
  position: relative;
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  z-index: 2;
  margin-bottom: 30px;
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
}

.backside {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 1s;
  -moz-transform-style: preserve-3d;
  -o-transition: 1s;
  -o-transform-style: preserve-3d;
  -ms-transition: 1s;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
  min-height: 250px;
}

.backside .card a {
  font-size: 18px;
  color: #007b5e !important;
}

.frontside .card .card-title,
.backside .card .card-title {
  color: #007b5e !important;
}

.frontside .card .card-body img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

/*contact us*/
.contact_us {
  padding-top: 93px;
}
.bg_conatct_title {
  background-color: #1ec8c8;
}
.title_img_text_con {
  box-shadow: 0px 2px 15px #a0a0a0;
  position: absolute;
  top: 42%;
  right: 15%;
  /* text-align: center; */
  padding: 10px 40px 10px 20px;
  background: #fff;
}
.title_img_text_con h5 {
  color: #1ec8c8;
  font-weight: 600;
  font-size: 30px;
}
.form_padd {
  padding: 25px 250px 25px 250px;
}
/*tnc*/
.tnc-section {
  padding-top: 20px;
}
.tnc_content h4 {
  font-size: 18px;
  font-weight: 600;
}
.tnc_content p {
  font-size: 15px;
  margin-bottom: 15px;
  line-height: 1.5;
}
.tnc_content p a {
  color: #007bff;
  text-decoration: underline;
}

/*how its work*/
.how_it_work_section {
  padding: 40px 0 50px 0;
}
.faq {
  padding: 60px 0 60px 0;
  background: #f8f8f8;
}
.card-header {
  cursor: pointer;
}
.section_title {
  font-size: 38px;
  font-weight: 600;
  color: #1ec8c8;
}

.placement {
  margin-left: 75%;
}
.img_zoom {
  -webkit-transition: all 1s ease; /* Safari and Chrome */
  -moz-transition: all 1s ease; /* Firefox */
  -ms-transition: all 1s ease; /* IE 9 */
  -o-transition: all 1s ease; /* Opera */
  transition: all 1s ease;
  width: 100%;
  height: 300px;
}

.img_zoom:hover {
  -webkit-transform: scale(1.05); /* Safari and Chrome */
  -moz-transform: scale(1.05); /* Firefox */
  -ms-transform: scale(1.05); /* IE 9 */
  -o-transform: scale(1.05); /* Opera */
  transform: scale(1.05);
  cursor: pointer;
}
.title_mobile {
  font-size: 30px;
  letter-spacing: 2px;
}
.section1_p {
  line-height: 1.5;
}
.each-guide-number {
  display: inline-block;
  width: 72px;
  height: 72px;
  line-height: 72px;
  font-size: 40px;
  background-color: #1ec8c8;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
}
.each-guide {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 280px;
}
.each-guide:last-child {
  margin-right: 0;
}
.each-guide .subheading {
  font-weight: normal;
  font-size: 22px;
  line-height: 1.38;
  color: #2d2d32;
  margin-top: 31px;
  margin-bottom: 17px;
  font-weight: 600;
}
.each-guide .description {
  font-size: 18px;
  line-height: 1.43;
  color: #2d2d32;
}
.guidance-steps .left {
  text-align: left !important;
}
.guidance-steps .middle {
  text-align: center !important;
}
.guidance-steps .right {
  text-align: right !important;
}



#mobileapp {
  position: absolute;
  top: 52px;
  right: 51px;
  width: 248px;
  height: 440px;
}
#mobileapp .item {
  width: 248px;
  height: 440px;
}

.main_area {
  min-height: 650px;
  padding-top: 0;
  padding-bottom: 15px;
}

/* blog page */
.mt_62 {
  margin-top: 62px;
}
.blogcard {
  box-shadow: 0 0 12px #00000024;
  transition: all 0.4s ease-in-out;
}
.blogtext {
  padding: 10px 15px;
}
.blog_dis {
  line-height: 1.7;
}
.blogcard:hover {
  box-shadow: 0 2px 20px #0000002e;
  transform: scale(1.01);
  transition: all 0.4s ease-in-out;
}
.blogdetail,
.sideblog {
  box-shadow: 0 0 12px #00000024;
}
.recernt_post {
  background-color: #fff;
  box-shadow: 0 0 12px #00000024;
  padding: 20px;
}
.recernt_post h4 {
  text-align: center;
}
.recent_post_ul {
  padding: 0;
}
.recent_post_ul li {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}
.recreny_post_img {
  border: 2px solid #1ec8c8;
}
.recreny_post_text h5 {
  font-size: 16px;
  font-weight: 600;
}
.recreny_post_text i {
  font-size: 14px;
}
/*blog_detail*/
.mt_48 {
  margin-top: 48px;
}

/*file upload*/
.file-uploader {
  background-color: #dedede !important;
  border-radius: 3px;
  color: #242424;
}

.file-uploader__message-area {
  font-size: 18px;
  padding: 1em;
  text-align: center;
  color: #377a65;
}

.file-list {
  background-color: white;
  font-size: 16px;
}

.file-list__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-list li {
  height: 50px;
  line-height: 50px;
  margin-left: 0.5em;
  border: none;
  overflow: hidden;
}

.removal-button {
  width: 20%;
  border: none;
  background-color: #d65d38;
  color: white;
}
.removal-button::before {
  content: "X";
}
.removal-button:focus {
  outline: 0;
}

.file-chooser {
  padding: 1em;
  transition: background-color 1s, height 1s;
}
.file-chooser p {
  font-size: 18px;
  padding-top: 1em;
}

.file-uploader {
  max-width: 400px;
  height: auto;
}
.file-uploader * {
  display: block;
}
.file-uploader input[type="submit"] {
  margin-top: 2em;
  float: right;
}

.file-list {
  margin: 0 auto;
  max-width: 90%;
}

.file-list__name {
  max-width: 70%;
  float: left;
}

.removal-button {
  display: inline-block;
  height: 100%;
}

.file-chooser {
  width: 90%;
  margin: 0.5em auto;
}

.file-chooser__input {
  margin: 0 auto;
}

.file-uploader__submit-button {
  width: 100%;
  border: none;
  font-size: 1.5em;
  padding: 1em;
  background-color: #b3b3b3;
  color: white;
}
.file-uploader__submit-button:hover {
  background-color: #a7d7c8;
}

.file-list li:after,
.file-uploader:after {
  content: "";
  display: table;
  clear: both;
}

.hidden {
  display: none;
}
.hidden input {
  display: none;
}

.error {
  background-color: #d65d38;
  color: white;
}
.profile_ver {
  text-align: left;
  padding: 15px 0;
  color: red;
}
/*table*/
.r_profile_timetable,
.r_profile_timetable_tr,
.r_profile_timetable_td,
.r_profile_timetable_th {
  border: 1px solid #999 !important;
}
.r_profile_timetable_th {
  background-color: #eee;
}
th,
td {
  padding: 10px 20px;
}

.pagenation_active {
  background-color: #eee;
}

/* 4-8-18 */
/* map */
#googleMap {
  width: 100%;
  height: 400px;
  margin: 25px 0;
}


.forgot_pass {
  color: #fff;
  text-align: left !important;
  display: inherit;
}
.desk_form{
  display: flex !important;
  align-items: center;
}
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */

  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.primary_text {
  color: #1ec8c8;
  font-size: 15px;
}
.ex_btn_display {
  padding: 5px;
  border: 1px solid #999;
  border-radius: 4px;
  background: transparent;
  cursor: unset !important;
}

.scrolling_ins {
  height: 300px;
  overflow-y: auto;
}

.icon-action-change {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

/*new*/
/*deshbord*/
.top_box_bg {
  background: #fff;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  padding: 15px;
  min-height: 100%;
}
.top_box_bg i {
  font-size: 30px;
  margin-bottom: 10px;
  color: #1ec8c8;
}
.sub_font {
  color: #777;
}
.sub_font i {
  color: #1ec8c8;
}
.title_name i {
  color: #1ec8c8;
}
.sub_font strong {
  color: #333;
}
.bg_whit {
  background-color: #fff;
  margin-bottom: 15px;
}
.img_block i {
  font-size: 60px;
}
.scroll_upcoming {
  margin-top: 15px;
  padding: 0 15px;
}

/*myappointment*/
.color_icon_cancle {
  color: #b50000;
  font-size: 35px;
}
.color_icon_reschedule {
  color: #1ec8c8;
  font-size: 35px;
}
.box p a {
  cursor: pointer;
}

/*index*/
.user_login_btn,
.hospital_login_btn {
  background: #eee;
  padding: 10px 5px;
  color: #fff !important;
  margin: 25px 0 0 0;
  font-size: 24px;
  text-decoration: none;
  color: #333 !important;
}
.login_bg {
  background: #1ec8c8;
  position: absolute;
  width: 100%;
  height: 100%;
}
.login_bg div h4 {
  color: #fff;
}
.login_bg div {
  margin: 100px 15px 0 15px;
}
/*desription*/
.dis_bg {
  background: #fff;
  padding: 15px 5px;
}
/*profile*/
.cursor_pointer {
  cursor: pointer;
}
.font_13 p {
  font-size: 13px;
  margin-bottom: 5px;
}
.font_13 {
  font-size: 16px;
}
.table_pad th,
.table_pad td {
  padding: 8px;
}
.crol_note {
  height: 80px;
  overflow-y: auto;
}
.scroll_allappointment {
  margin: 10px 15px;
}
.scroll_allappointment .card{
  margin-bottom: 10px;
}
.pos_fix {
  position: fixed;
  z-index: 99;
  background: #1ec8c8;
  padding: 0;
  border-bottom: 1px solid #ddd;
  left: 0;
  right: 0;
}
.mar_top {
  margin-top: 63px;
}
.mrg_top {
  margin-top: 65px;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 150px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: left;
  right: 5px;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.dropdown-content a {
  cursor: pointer;
  display: block;
  padding: 5px 10px;
  color: #333;
}
.dropdown-content a:hover {
background: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.str_small strong {
  font-size: 12px;
}
.searchBtn{
  border-radius: 4px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.searchBtn ion-icon{
  font-size: 22px;
  margin-right: 5px;
}
.docSpeciality{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

}
.docSpecialitySec{
  padding: 50px 0;
  background: #e2ffff;
}
.docSpecialitySec .heading{
  margin-bottom: 25px;
}
.docSpecialitySec .heading h5{
  font-size: 25px;
  font-family: 600;
}
.docSpecialitySec .heading p{
  font-size: 18px;
}

.specialityBlock{
  text-align: center;
  background: #fff;
  padding: 25px 30px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  box-shadow: 0 3px 5px #00000017;
}
.specialityBlock .specialityImg{
  width: 100px;
}
.specialityBlock img{
  width: 100%;
  margin-bottom: 5px;
}
.specialityBlock p{
  font-weight: 600;
  margin-bottom: 5px;
}
.specialityBlock a{
  color: #1ec8c8;
  font-weight: 600;
}

.section-header {
  margin-bottom: 30px;
}
.section-header h2 {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 0;
  font-weight: bold;
}
.section-header h2 span {
  color: #1ec8c8;
}
.blog.grid-blog {
  padding: 0;
}
.blog {
  border: 1px solid #E5E5E5;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 1.5rem;
  position: relative;
}
.grid-blog .blog-image {
  margin-bottom: 0;
  overflow: hidden;
}
.blog-image, .blog-image > a, .blog-image img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
}
.blog.grid-blog .blog-image img {
  border-radius: 8px 8px 0 0;
}
.blog-image img {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
  -o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
  transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.grid-blog .blog-content {
  padding: 10px;
  position: relative;
}
.post-author img {
  border-radius: 100%;
  width: 28px;
  flex: 0 0 28px;
  margin-right: 5px;
}
.grid-blog .entry-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.entry-meta {
  color: #757575;
  font-size: 12px;
  list-style: none;
  margin-bottom: 15px;
  padding: 0;
}
.grid-blog .blog-content .read-link {
  font-size: 12px;
  text-decoration: underline;
}
.btn-secondary {
  background-color: #E5F1FB;
  border: 1px solid #E5F1FB;
  color: #000;
  border-radius: 8px;
}
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary.active, .btn-secondary:active, .open > .dropdown-toggle.btn-secondary {
  background-color: #1ec8c8;
  border: 1px solid #1ec8c8;
}
.view-all .btn {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 30px;
}
.btn{
  cursor: pointer;
  text-decoration: none;
  color: #333;
  display: inline-block;
  text-align: center;
  padding: 5px 15px;
  border-radius: 4px;
  font-weight: 600;
}
.searchBtn{
  padding: 8px 15px;
  margin: 0px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
}
.mainArea{
  padding: 0 15px;
}
.appointmentList{
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  /* box-shadow: 0 1px 5px #0000002b; */
}

.features{
  margin-bottom: 45px;
}
.features .featureSec{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.features .featureSec .featureBlock{
  width: 17%;
  border: 1px solid #f0f0f5;
  box-shadow: 0 4px 8px rgb(0 0 0 / 12%);
  border-radius: 24px;
  cursor: pointer;
  overflow: hidden;
  margin: -1px;
}
.features .featureSec .featureBlock .featureImg{
  padding: 10px 10px 0 10px;
  overflow: hidden;
  min-height: 230px;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: end;
}
.features .featureSec .featureBlock .featureImg img{
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.features .featureSec .featureBlock .detail{
  padding: 15px;
  background: #fff;
  min-height: 105px;
}
.features .featureSec .featureBlock .detail h5{
  font-weight: 700;
  font-size: 22px;
}
.features .featureSec .featureBlock:nth-child(1){
  background: rgb(214, 168, 255);
}
.features .featureSec .featureBlock:nth-child(2){
  background: rgb(168, 255, 235);
}
.features .featureSec .featureBlock:nth-child(3){
  background: rgb(255, 168, 210);
}
.features .featureSec .featureBlock:nth-child(4){
  background: rgb(255, 254, 168);
}
.features .featureSec .featureBlock:nth-child(5){
  background: rgb(168, 229, 255);
}
.nav-tabs .nav-link{
  color: #333;
  font-weight: 600;
}
.dashboardSearch{
  background: #ddd;
  top: 70px;
  position: sticky;
  z-index: 99;
  width: 100%;
  padding: 10px 0;
  height: 115px;
}
.addStaffTab .nav-link{
  background: #ddd;
  color: #333;
}
.addStaffTab .nav-pills .nav-link.active{
  background: #1ec8c8 !important;
}
.w-85{
  width: 85%;
}
.w-15{
  width: 15%;
}
.listingBlock{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listingBlock .img_block{
  width: 100px;
}
#staff .nav-tabs{
  border: none !important;
}
#staff .tab-content{
  padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 10px 0;
}
#location .locationCard{
  border: 1px solid #ddd;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 10px;
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 2px 7px #0000004d;
}
.shadow-sm{
  box-shadow: 0 2px 7px #0000004d !important;
}
#location .locationCard .time{
  margin-top: 10px;
}
#location .locationCard .time p{
  font-weight: 600;
}
#location .locationCard .time p span{
  font-weight: 400 !important;
}
.liveappointmentDetail li{
  line-height: 1.8;
}
.liveappointmentDetail{
  padding: 0;
  list-style: none;
}
.card .cardTitle{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  border-bottom: 1px solid #ddd;
}
.card .cardBody{
  padding: 5px 15px;
}
.btn-sm{
  padding: 5px;
  font-size: 12px;
}

select{
  -webkit-appearance: listbox !important;
}
.accordion .card .card-header:hover {
  background: #eee;
}
.uploadProfile{
  position: relative;
  width: 20%;
  margin: auto;
}
.profile-pic {
  width: 200px;
  max-height: 200px;
  display: inline-block;
}

.file-upload {
  display: none;
}

.circle {
  border-radius: 100% !important;
  overflow: hidden;
  width: 128px;
  height: 128px;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

img {
  max-width: 100%;
  height: auto;
}

.p-image:hover {
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
}

.upload-button {
  font-size: 1.2em;
}

.upload-button:hover {
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  color: #999;
}

.nav-tabs li .nav-link{
  margin-right: 5px;
}
.modal-header .close{
  border-radius: 5px;
    border: 1px solid #333;
    padding: 5px 10px;
    margin-right: 0;
}
.modal-header .close:hover{
  background: red;
  color: #fff;
}
.shadowCustom{
  box-shadow: 0 2px 7px #00000087;
}
#docVarification .docInfo{
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 5px;
}
#docVarification .cardImg img{
  width: 60% !important;
  margin: auto;
  display: flex;
}
#docVarification .card{
  box-shadow: 0 2px 7px #0000003d;
  overflow: hidden;
}
small, .small {
  font-size: 14px;
}
label {
  display: inline-block;
  margin-bottom: 0.2rem !important;
}
.pageParmission .btn-group-toggle{
  margin-right: 5px !important;
}
.searchWrapper {
  border-radius: 4px;
  padding: 5px;
  position: relative;
  background: #fff;
}
.scroll_allappointment .card .text-left{
  width: 25%;
}
label{
  font-weight: 600;
}
.customCheckboxBtn p{
  padding: 5px 0px;
  background: transparent;
  box-shadow: 0 0 3px #0000002e;
  border-radius: 4px;
  width: 100%;
  margin: 0 15px;
  margin-bottom: 10px;
  cursor: pointer;
}
.customCheckboxBtn p:hover {
  color: #1ec8c8;
  box-shadow: 0 0 3px #1ec8c8;
}
.customCheckboxBtn p.selected {
  background: #1ec8c8;
  color: #fff;
  font-weight: 800;
}
#permission a.card:hover{
  background: #f4f4f4;
}
.removeInsurance{
  border: 1px solid #c41313;
  padding: 8px;
  cursor: pointer;
  text-decoration: none;
  color: #333;
  display: inline-block;
  text-align: center;
  padding: 5px 20px;
  border-radius: 4px;
  font-weight: 600;
  color: #c41313;
}
.shadow-lg {
  box-shadow: 0 0px 15px #000000a8 !important;
}
.docinfo .moreBtns{
  margin-top: 5px;
}
.addDoctormodel .modal-body{
  overflow-x: hidden;
}
.sharedBadge{
  position: absolute;
  transform: rotate(-45deg);
  left: -17px;
  top: 7px;
  border-radius: 0;
  width: 70px;
}
#permission a{
  position: relative;
  overflow: hidden;
}
#viewMedicalHistoryModal .tab-content{
  min-height: 250px;
}
#appointmentShare .selectAppointment{
  position: absolute;
  top: 10px;
}
#selectProfile a.active{
  background: #ff9600 !important;
  border: 1px solid #ff9600 !important;
  color: #fff;
}
#subcriptionPayment .card{
  overflow: hidden;
}
#subcriptionPayment .card.active{
  border: 2px solid #1ec8c8;
}

#subcriptionPayment .card.active .badge{
  position: absolute;
    top: 15px;
    left: -15px;
    transform: rotate(-45deg);
}
.editDoctor{
  position: absolute;
  right: 5px;
  top: 5px;
}
.deleteDoctor{
  position: absolute;
  right: 45px;
  top: 5px;
}
.timeInterval ul{
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.timeInterval ul li{
  width: 150px;
  padding: 7px;
  margin: 3px;
  overflow: hidden;
  border: 1px solid #1ec8c8;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-align: center;
  color: #1ec8c8;
  font-weight: 600;
}
.timeInterval ul li.booked{
  background: #ddd;
  color: rgb(121, 121, 121);
}
input{
  position: relative;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background-position: right;
  background-size: auto;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
}

.calendar {
  min-width: 375px;
}
.calendar td {
  padding: 15px 0 !important;
  min-width: 50px;
}
.calendar-wrap .btn-link {
  color: #fff;
}
.calendar .btn-link {
  color: #fff;
  text-decoration: none !important;
  border-radius: 50px;
  min-width: 50px;
}
.calendar .btn-link:hover, .calendar .btn-link.active {
  background-color: #fff;
  color: #222;
}

.avatar-group {
  position: relative;
  z-index: 2;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}
.avatar-circle {
    border-radius: 50%;
}
.selectTimeSlot .timeSlot{
  max-height: 300px;
  overflow: auto;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
.rdrInputRanges{
  display: none;
}
.react-calendar{
  width: 100% !important;
}
.confirmBtn{
  display: flex;
}
.confirmBtn button{
  margin-right: 5px;
  width: 100%;
  border: 1px solid #6c757d;
  color: #6c757d;
}
.confirmBtn .confirm{
  width: 100%;
  border: 1px solid #1ec8c8;
  color: #fff;
  background: #1ec8c8;
}
.confirmBtn button:hover{
  border: 1px solid #1ec8c8;
  background: transparent;
  color: #1ec8c8;
}


/* switch btn */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked + .slider {
  background-color: #2196F3;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.invoice-details h4 {
  color: #666;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 1.875rem;
  text-transform: uppercase
}
.invoice-container {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  margin: 0 auto 1.875rem;
  max-width: 900px;
  padding: 1.5rem
}

.invoice-details,
.invoice-payment-details>li span {
  float: left;
  text-align: left
}
.disabled{
  pointer-events: none;
  opacity: .5;
}
.adminsubheader{
  flex-wrap: wrap;
}
.adminsubheader .main_tabs{
  border-bottom: 1px solid #fff;
  width: 200px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.adminsubheader .main_tabs a{
  display: inline-block;
  white-space: nowrap;
}
.dataTables_wrapper .top{
  display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.dataTables_wrapper .top label{
  margin-bottom: 0 !important;
}
.dataTables_filter{
  order: 2;
}
.dataTables_length{
  order: 1;
}
.dataTables_paginate{
  order: 3;
}
.dataTables_filter [type='search']{
  width: 350px;
  border: 1px solid #ddd;
}
.dataTables_wrapper .dataTables_length select{
  border: 1px solid #ddd !important;
}
.dt-buttons button{
  background: #fff;
    border: 1px solid #1ec8c8;
    border-radius: 4px;
    color: #1ec8c8;
    padding: 4px 10px;
}
.dt-buttons button:hover{
    background: #1ec8c8;
    border: 1px solid #1ec8c8;
    color: #fff;
}
.dataTables_paginate .paginate_button{
  background: #fff !important;
  border: 1px solid #1ec8c8 !important;
  border-radius: 4px !important;
  color: #1ec8c8 !important;
  padding: 5px 10px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
}
.dataTables_paginate .paginate_button.current{
  background: #1ec8c8 !important;
    border: 1px solid #1ec8c8 !important;
    color: #fff !important;
}
table.table td h2 a {
  color: #1ec8c8;
  font-weight: 500;
}
table.table td h2 span {
  color: #000;
  display: block;
  font-size: 12px;
  margin-top: 7px;
}
table.table td h2.table-avatar {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}
table.dataTable tbody tr, table tbody tr{
  border-style: none;
  border: 1px solid #EBEBEB;
}
.editAppointment .form-control{
  height: 30px;
  padding: 5px 10px;
  line-height: 1.5;
  margin-bottom: 5px;
}
.profileEdit{
  position: absolute;
  top: 5px;
  right: 5px;
}



/* ========= */

.primary-button{
  background-color: #1ec8c8 !important;
  color: #FFF  !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 10px !important;
  min-height: 36px !important;
  padding: 0px 15px !important;
  text-transform: none !important;
  margin-top: 0;

}

.css-1z10yd4-MuiFormControl-root-MuiTextField-root{
  margin-top: 0 !important;
}