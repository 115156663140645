/* .doctor_image{
    padding-right: 40%;
} */
.tempFullscreen{
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    background: #fff;
    height: 100vh;
}
.tempHide{
    display: none !important;
}