.login-form{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-form form{
  margin: auto;
  border: 1px solid #ddd;
  padding: 25px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.primary_button{
  border-radius: 8px;
  background-color: #1ec8c8 !important;
  padding: 5px 15px !important;

}