@media only screen and (max-width: 768px) {
    .search_left_icon {
        padding: 5px;
    }

    .location,
    .specialization,
    .insurance {
        height: 40px !important;
        font-size: 15px;
    }

    .insurance {
        border-radius: 3px;
        border: 0;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
    }

    .drop_icon {
        right: 28px;
    }

    .insurance_popup {
        left: 15px;
        right: 15px;
    }

    .search .detect {
        top: 4px;
        right: 20px;
    }

    hr {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .go_search {
        width: 100%;
    }

    .padd-130 {
        padding-top: 50px;
    }

    .app_title {
        font-size: 30px;
        text-align: center;
    }

    .app_info li {
        font-size: 19px;
    }

    .app_demo {
        text-align: center !important;
    }

    .get_app {
        text-align: center;
    }

    .store_img a {
        text-align: center;
    }

    .doctor_image img {
        position: relative;
    }

    .doctor_image {
        text-align: center;
    }

    .doctor_bg {
        background: #fff;
    }

    .padd-95 {
        padding-top: 0px;
    }

    .doctor_title {
        font-size: 30px;
        font-weight: 800;
        letter-spacing: 0px;
    }

    .slider_bgimage {
        padding: 30px 20px;
        flex-direction: column;
    }

    .label .time,
    .label .detail,
    .label a {
        font-size: 12px !important;
    }

    .header2 {
        padding-top: 65px;
    }

    .login_area {
        height: 450px;
        background: unset !important;
        padding-top: 0 !important;
    }

    .minheight {
        min-height: 640px;
    }

    .scroll_upcoming {
        margin-top: 5px !important;
    }

    .login {
        padding: 30px 15px;
        box-shadow: 0 0px 40px #00000052;
        width: 100%;
        height: auto;
        margin-top: 10px;
    }

    .margt_90 {
        margin-top: 90px;
    }

    .store_img {
        text-align: center;
    }

    .direction_row p {
        font-size: 11px;
    }

    .dashboard_box .waiting {
        font-size: 18px;
        font-weight: 600;
        color: #1ec8c8 !important;
    }

    .dashboard_box .box {
        margin: 10px 0;
    }

    .dashboard_box .d-flex {
        flex-wrap: wrap;
    }

    .pagination li a {
        font-size: 12px;
    }

    .pagination li:focus,
    .pagination li:active {
        outline: none !important;
    }

    .main_tabs a {
        display: block;
        height: 40px;
        padding: 10px;
    }

    .drblock {
        padding: 2px 15px;
        margin-left: 10px;
        margin-right: 10px;
    }

    #custom-search-input .search-query {
        /* margin-left: 10px; */
        height: 30px;
        font-size: 12px;
    }
    .btn-scheduale {
        border: 1px solid;
        padding: 0.375rem 0.25rem;
    }

    #custom-search-input {
        margin: 0;
        margin-top: 10px;
        padding: 0 7px;
    }

    .page-link {
        padding: 0.2rem 0.75rem;
    }

    .newappoint {
        padding: 5px 15px;
    }

    .menuactive {
        background: #1ec8c8;
        color: #fff;
    }

    .subheader {
        display: none !important;
    }

    .mobile_header {
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        background: #1ec8c8;
    }

    .mobile_header .main_tabs a {
        font-size: 10px;
    }

    .padd_25 {
        padding: 2px 5px 2px 5px;
    }

    .label {
        margin-top: 0 !important;
    }

    .mainTitle ul {
        color: #fff;
        font-size: 20px;
        line-height: 50px;
        list-style: none;
        padding-left: 0;
    }

    .mainTitle ul li {
        list-style: none;
        margin-bottom: 10px;
    }

    .mainTitle img {
        width: 40px;
        margin-right: 10px;
    }

    .mainTitle .color_change {
        font-weight: 600;
        color: #63eded;
    }

    .searchBtn {
        font-size: 25px;
        border-radius: 3px !important;
    }

    .entry .dot {
        margin-top: 0;
        left: 15px;
        margin-left: 0;
    }

    .entry {
        max-width: 100%;
        width: 100%;
    }

    .entry .find,
    .entry .appoint,
    .entry .track {
        transform: scale(0.7) !important;
    }

    .entry:hover .find,
    .entry:hover .appoint,
    .entry:hover .track {
        transform: scale(0.7) !important;
        transition: all 0.2s ease-out;
        margin-top: 0px;
    }

    .padd_50 {
        padding: 20px 0;
    }

    .aboutus_text {
        padding: 185% 0 10% 0;
    }

    #team {
        padding: 50px 0 !important;
    }

    .mission_text {
        margin-left: 0;
        background-color: #0000002b;
    }

    .mission_text p {
        margin-top: 5px;
        color: #fff;
    }

    .mission_text h3 {
        font-size: 25px;
        color: #fff;
    }

    .mission_text h4 {
        font-size: 23px;
    }

    #mobileapp {
        right: 82px !important;
    }

    .hori_timeline {
        padding: 15px 0;
    }

    .timeline {
        display: none;
    }

    .tnc_content h4 {
        font-size: 18px;
        font-weight: 600;
    }

    .tnc_content p {
        font-size: 15px;
        margin-bottom: 15px;
        line-height: 1.5;
    }

    .tnc_content p a {
        color: #007bff;
        text-decoration: underline;
    }

    .aboutus-title {
        font-size: 33px;
    }

    .tnc_content {
        margin-top: 15px;
    }

    .aboutus-section {
        padding-top: 68px;
    }

    .title_img_text {
        position: absolute;
        top: 10%;
        padding: 0px;
    }

    .title_img_text h5 {
        color: #1ec8c8;
        font-size: 36px;
        font-weight: 600;
    }

    .form_padd {
        padding: 10px;
    }

    .recent_post_ul li {
        padding: 10px;
    }

    .footer_bg {
        text-align: center;
    }

    .footer_bg ul {
        list-style: none;
    }

    .social-icons,
    .contact,
    .quick_ul,
    iframe {
        margin-top: 15px;
    }

    .login_area {
        padding-top: 15px;
    }

    .search_icon1 {
        padding: 7px;
    }

    .desk_form {
        display: flex !important;
        align-items: center;
    }

    .desk_form input {
        height: 30px;
        font-size: 12px;
    }

    .main_tabs a {
        height: 60px;
        padding: 5px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .box,
    .box2,
    .box3 {
        text-align: center;
    }

    .box p,
    .box2 p,
    .box3 p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .top_box_bg p {
        font-size: 12px;
    }

    .hori_timeline2 {
        padding: 0px;
    }

    .img_block i {
        font-size: 50px;
    }

    .dashboard_box .box {
        margin-bottom: 9px;
    }

    .entry {
        margin-top: 0px;
    }
    .blood_group h6 {
        margin-bottom: 0px;
    }

    .profile_ver {
        padding: 5px 0;
    }

    .scrolling_ins {
        height: 150px;
    }

    .file-uploader__message-area {
        padding: 0px;
    }

    .file-chooser {
        margin: 0;
    }

    .file-uploader input[type="submit"] {
        margin-top: 0;
    }

    .file-uploader__submit-button {
        font-size: 15px;
        padding: 5px;
    }
    .font_13 p {
        font-size: 9px !important;
    }

    .font_13 {
        font-size: 10px !important;
    }

    .table_pad th,
    .table_pad td {
        padding: 5px !important;
        font-size: 10px !important;
    }

    .scroll_allappointment {
        height: 380px !important;
        overflow-y: auto;
        margin-top: 2px;
    }

    .circle {
        width: 100px;
        height: 100px;
        right: 10%;
        left: 0%;
        border: 2px solid #1ec8c8;
    }

    .p-image {
        right: 8%;
        top: 60px;
    }

    .table-striped tr td {
        font-size: 12px;
    }

    .td_padd {
        padding: 0px !important;
    }

    .navbar-brand {
        margin-right: 0px;
    }

    .bg_title h2 {
        top: 17%;
    }

    .search_popup {
        left: 15px;
        right: 15px;
    }

    .bg_title h2 {
        font-size: 30px;
        line-height: 1.5;
        top: 11%;
        text-align: left;
    }

    .bg_title_search h2 {
        font-size: 20px;
        line-height: 1.5;
        top: -8%;
    }

    .bg_title_dash h2 {
        font-size: 20px;
        line-height: 1.5;
        top: 23%;
    }

    .search_left_icon {
        width: 100%;
        text-align: center;
        margin-top: 8px;
        border-radius: 3px;
    }
    .slider_bgimage .sliderImg img {
        margin-top: 0;
        margin-bottom: -31px;
    }
    .howto_text{
        margin-left: 75px;
        width: auto;
    }
    .features .featureSec{
        flex-direction: column;
    }
    .features .featureSec .featureBlock{
        margin-bottom: 25px;
        width: 100%;
    }
    .features .featureSec .featureBlock .featureImg{
        height: auto;
    }
    .features .featureSec .featureBlock .featureImg img{
        height: 240px;
    }
    .docSpeciality{
        justify-content: space-around;
    }
    .specialityBlock{
        margin-bottom: 25px;
    }
    .mainTitle{
        text-align: center;
    }
    .slider_bgimage{
        margin-top: 40px;
    }
    .appointmentList .img_block{
        width: 70px;
    }
    .nav-tabs{
        display: flex;
        justify-content: center;
    }
    .main_area{
        padding-bottom: 70px;
    }
    .liveappointmentDetail li{
        justify-content: center;
    }
    .liveappointmentDetail{
        text-align: center;
    }
    #navbarSupportedContent{
        background: #fff;
        padding: 10px;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        box-shadow: 0 3px 7px #00000045;
    }
    .dashboardSearch{
        top: 70px;
    }
    .search .location, .search .specialization, .search .insurance{
        padding: 0 90px 0 30px;
    }
    .bg_title_search h2{
        top: 20%;
    }
    .slider_bgimage{
        min-height: 325px;
        margin-top: 15px !important;
        overflow: hidden;
    }
    .bg_title_search h2{
        font-size: 30px;
    }
    .dashboardSearch{
        height: auto;
    }
    #myappointment .m-100, #permission .m-100{
        width: 100% !important;
    }
    .listingBlock{
        flex-direction: column;
    }
    .listingBlock .d-flex{
        flex-direction: row;
        flex-wrap: nowrap;
    }
    #userLogin select{
        width: 42%;
        font-size: 12px;
        height: 38px;
        padding: 5px;
    }
    #userLogin .w-75{
        width: 58% !important;
    }
    .customAnchor {
        font-size: 16px;
    }
    #selectProfile{
        position: absolute;
        left: 0;
        right: 0;
        padding: 10px;
        top: 73px;
        background: #fff;
        box-shadow: 0 4px 5px #0000002b;
        border-radius: 4px;
    }
    #selectProfile a{
        display: block !important;
    }
    .nav_bg{
        padding: 7px 10px;
    }
    #msform select{
        width: 42%;
        font-size: 12px;
        height: 38px;
        padding: 5px;
    }
    #msform .w-75{
        width: 58% !important;
    }
    .mainTitle h4 {
        font-size: 30px;
        font-weight: 600;
        color: #fff;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: portrait) {
    #mobileapp {
        right: 82px !important;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
    #mobileapp {
        right: 62px !important;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: portrait) {
    #mobileapp {
        right: 56px !important;
    }
}