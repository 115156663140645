
.formBlock {
    background: #fff;
    padding: 10px;
    margin: auto;
    min-height: 250px
}

.customAnchor{
    color: #007bff  !important;
    text-decoration: none !important;
    background-color: transparent !important;
}

.customP{
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
}

a:hover {
    text-decoration: none;
}

.multi_step_form {
    background: #fff;
    display: block;
    width: 100%;
}

.multi_step_form #msform {
    position: relative;
    background: #ffffff;
    z-index: 1;
}

.multi_step_form #msform .tittle {
    text-align: center;
    padding-bottom: 55px;
}

.multi_step_form #msform .tittle h2 {
    font: 500 24px/35px "Roboto", sans-serif;
    color: #3f4553;
    padding-bottom: 5px;
}

.multi_step_form #msform .tittle p {
    font: 400 16px/28px "Roboto", sans-serif;
    color: #5f6771;
}

.multi_step_form #msform fieldset {
    border: 0;
    padding: 0;
    position: relative;
    width: 100%;
    left: 0;
    right: 0;
}

.multi_step_form #msform fieldset:not(:first-of-type) {
    display: none;
}

.multi_step_form #msform .form-control.placeholder,
.multi_step_form #msform .product_select.placeholder {
    color: #5f6771;
}

.multi_step_form #msform .form-control:-moz-placeholder,
.multi_step_form #msform .product_select:-moz-placeholder {
    color: #5f6771;
}

.multi_step_form #msform .form-control::-moz-placeholder,
.multi_step_form #msform .product_select::-moz-placeholder {
    color: #5f6771;
}

.multi_step_form #msform .form-control::-webkit-input-placeholder,
.multi_step_form #msform .product_select::-webkit-input-placeholder {
    color: #5f6771;
}

.multi_step_form #msform .form-control:focus,
.multi_step_form #msform .form-control:hover,
.multi_step_form #msform .product_select:focus,
.multi_step_form #msform .product_select:hover {
    border-color: #1ec8c8;
}

.multi_step_form #msform .form-control:focus.placeholder,
.multi_step_form #msform .product_select:focus.placeholder {
    color: transparent;
}

.multi_step_form #msform .form-control:focus:-moz-placeholder,
.multi_step_form #msform .product_select:focus:-moz-placeholder {
    color: transparent;
}

.multi_step_form #msform .form-control:focus::-moz-placeholder,
.multi_step_form #msform .product_select:focus::-moz-placeholder {
    color: transparent;
}

.multi_step_form #msform .form-control:focus::-webkit-input-placeholder,
.multi_step_form #msform .product_select:focus::-webkit-input-placeholder {
    color: transparent;
}

.multi_step_form #msform .product_select:after {
    display: none;
}

.multi_step_form #msform .product_select:before {
    content: "";
    position: absolute;
    top: 0;
    right: 20px;
    font: normal normal normal 24px/48px Ionicons;
    color: #5f6771;
}

.multi_step_form #msform fieldset .product_select .list {
    width: 100%;
}

.multi_step_form #msform fieldset .done_text {
    padding-top: 40px;
}

.multi_step_form #msform fieldset .done_text .don_icon {
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 22px;
    margin-bottom: 10px;
    background: #1ec8c8;
    display: inline-block;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
}

.multi_step_form #msform fieldset .done_text h6 {
    line-height: 23px;
}

.multi_step_form #msform fieldset .code_group {
    margin-bottom: 60px;
}

.multi_step_form #msform fieldset .code_group .form-control {
    border: 0;
    border-bottom: 1px solid #a1a7ac;
    border-radius: 0;
    display: inline-block;
    width: 30px;
    font-size: 30px;
    color: #5f6771;
    padding: 0;
    margin-right: 7px;
    text-align: center;
    line-height: 1;
}

.multi_step_form #msform fieldset .passport {
    margin-top: -10px;
    padding-bottom: 30px;
    position: relative;
}

.multi_step_form #msform fieldset .passport .don_icon {
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 22px;
    position: absolute;
    top: 4px;
    right: 0;
    background: #1ec8c8;
    display: inline-block;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
}

.multi_step_form #msform fieldset .passport h4 {
    font: 500 15px/23px "Roboto", sans-serif;
    color: #5f6771;
    padding: 0;
}

.multi_step_form #msform fieldset .input-group {
    padding-bottom: 40px;
}

.multi_step_form #msform fieldset .input-group .custom-file {
    width: 100%;
    height: auto;
}

.multi_step_form #msform fieldset .input-group .custom-file .custom-file-label {
    width: 168px;
    border-radius: 5px;
    cursor: pointer;
    font: 700 14px/40px "Roboto", sans-serif;
    border: 1px solid #99a2a8;
    text-align: center;
    transition: all 300ms linear 0s;
    color: #5f6771;
}

.multi_step_form #msform fieldset .input-group .custom-file .custom-file-label i {
    font-size: 20px;
    padding-right: 10px;
}

.multi_step_form #msform fieldset .input-group .custom-file .custom-file-label:focus,
.multi_step_form #msform fieldset .input-group .custom-file .custom-file-label:hover {
    background: #1ec8c8;
    border-color: #1ec8c8;
    color: #fff;
}

.multi_step_form #msform fieldset .input-group .custom-file input {
    display: none;
}

.multi_step_form #msform fieldset .file_added {
    text-align: left;
    padding-left: 190px;
    padding-bottom: 60px;
}

.multi_step_form #msform fieldset .file_added li {
    font: 400 15px/28px "Roboto", sans-serif;
    color: #5f6771;
}

.multi_step_form #msform fieldset .file_added li a {
    color: #1ec8c8;
    font-weight: 500;
    display: inline-block;
    position: relative;
    padding-left: 15px;
}

.multi_step_form #msform fieldset .file_added li a i {
    font-size: 22px;
    padding-right: 8px;
    position: absolute;
    left: 0;
    transform: rotate(20deg);
}
.multi_step_form #msform #progressbar {
    margin-bottom: 30px;
    overflow: hidden;
}

.multi_step_form #msform #progressbar li {
    list-style-type: none;
    color: #99a2a8;
    font-size: 9px;
    width: calc(100%/2);
    float: left;
    position: relative;
    font: 500 13px/1 "Roboto", sans-serif;
}

.multi_step_form #msform #progressbar li:nth-child(2):before {
    content: '';
    background-image: url(../../../assets/icons/file-regular.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 17px !important;
    background-position: center !important;
}

.multi_step_form #msform #progressbar li:nth-child(3):before {
    content: '';
    background-image: url(../../../assets/icons/file-regular.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 17px !important;
    background-position: center !important;
}

.multi_step_form #msform #progressbar li:before {
    content: '';
    background-image: url(../../../assets/icons/mobile-alt-solid.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 17px !important;
    background-position: center !important;
    font: normal normal normal 30px/50px Ionicons;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: block;
    background: #eaf0f4;
    border-radius: 50%;
    margin: 0 auto 10px;
}

.multi_step_form #msform #progressbar li:after {
    content: "";
    width: 100%;
    height: 10px;
    background: #eaf0f4;
    position: absolute;
    left: -50%;
    top: 21px;
    z-index: -1;
}

.multi_step_form #msform #progressbar li:last-child:after {
    width: 150%;
}

.multi_step_form #msform #progressbar li.active {
    color: #1ec8c8;
}

.multi_step_form #msform #progressbar li.active:after,
.multi_step_form #msform #progressbar li.active:before {
    background: #1ec8c8;
    color: white;
}

.multi_step_form #msform .action-button {
    background: #1ec8c8;
    color: white;
    border: 0 none;
    border-radius: 5px;
    cursor: pointer;
    font: 700 14px "Roboto", sans-serif;
    border: 1px solid #1ec8c8;
    margin: 0 5px;
    text-transform: uppercase;
    display: inline-block;
    padding: 10px 15px;
}

.multi_step_form #msform .action-button:focus,
.multi_step_form #msform .action-button:hover {
    background: #405867;
    border-color: #405867;
}

.multi_step_form #msform .previous_button {
    background: transparent;
    color: #99a2a8;
    border-color: #99a2a8;
}

.multi_step_form #msform .previous_button:focus,
.multi_step_form #msform .previous_button:hover {
    background: #405867;
    border-color: #405867;
    color: #fff;
}

