
/*-----------------
	3. Bootstrap Classes
-----------------------*/

.btn.focus, .btn:focus {
    box-shadow: unset;
}
.btn-white {
	background-color: #fff;
	border: 1px solid #ccc;
	color: #272b41;
}
.btn.btn-rounded {
	border-radius: 50px;
}
.bg-primary,
.badge-primary {
	background-color: #1ec8c8 !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #09dca4 !important;
}
.bg-success,
.badge-success {
	background-color: #00e65b !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #00cc52 !important;
}
.bg-info,
.badge-info {
	background-color: #1ec8c8 !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #028ee1 !important;
}
.bg-warning,
.badge-warning {
	background-color: #ffbc34 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #e9ab2e !important;
}
.bg-danger,
.badge-danger {
	background-color: #ff0100 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #e63333 !important;
}
.bg-white {
	background-color: #fff;
}
.bg-purple,
.badge-purple {
    background-color: #9368e9 !important;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
	color: #1ec8c8 !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
	color: #41B883 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
	color: #EB5757 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
	color: #1ec8c8 !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
	color: #ffbc34 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
	color: #7460ee !important;
}
.text-muted {
    color: #757575 !important;
}
.btn-primary {
	background-color: #1ec8c8;
	border: 1px solid #1ec8c8;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
	background-color: #0469c8;
	border: 1px solid #0469c8;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
	background-color: #0469c8;
	border: 1px solid #0469c8;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
	background-color: #0469c8;
	border-color: #0469c8;
	color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-primary.disabled, .btn-primary:disabled {
    background-color: #1ec8c8;
    border-color: #1ec8c8;
    color: #fff;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-secondary {
	background-color: #E5F1FB;
	border: 1px solid #E5F1FB;
	color: #000;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.active,
.btn-secondary:active,
.open > .dropdown-toggle.btn-secondary {
	background-color: #0469c8;
	border: 1px solid #0469c8;
}
.btn-secondary.active.focus,
.btn-secondary.active:focus,
.btn-secondary.active:hover,
.btn-secondary.focus:active,
.btn-secondary:active:focus,
.btn-secondary:active:hover,
.open > .dropdown-toggle.btn-secondary.focus,
.open > .dropdown-toggle.btn-secondary:focus,
.open > .dropdown-toggle.btn-secondary:hover {
	background-color: #0469c8;
	border: 1px solid #0469c8;
}
.btn-secondary.active:not(:disabled):not(.disabled),
.btn-secondary:active:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle {
	background-color: #0469c8;
	border-color: #0469c8;
	color: #fff;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #daba00;
    border-color: #daba00;
    color: #333;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success {
	background-color: #00e65b;
	border: 1px solid #00e65b
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
	background-color: #00cc52;
	border: 1px solid #00cc52;
	color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
	background-color: #00cc52;
	border: 1px solid #00cc52
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
	background-color: #00cc52;
	border-color: #00cc52;
	color: #fff;
}
.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success.disabled, .btn-success:disabled {
    background-color: #00e65b;
    border-color: #00e65b;
    color: #fff;
}
.btn-info {
	background-color: #009efb;
	border: 1px solid #009efb
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
	background-color: #028ee1;
	border-color: #028ee1;
	color: #fff;
}
.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-info.disabled, .btn-info:disabled {
    background-color: #009efb;
    border-color: #009efb;
    color: #fff;
}
.btn-warning {
	background-color: #ffbc34;
	border: 1px solid #ffbc34
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle {
	background-color: #e9ab2e;
	border-color: #e9ab2e;
	color: #fff;
}
.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffbc34;
    border-color: #ffbc34;
    color: #fff;
}
.btn-danger {
	background-color: #ff0100;
	border: 1px solid #ff0100;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
	background-color: #e63333;
	border: 1px solid #e63333;
	color: #FFF;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #e63333;
	border-color: #e63333;
	color: #fff;
}
.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-danger.disabled, .btn-danger:disabled {
    background-color: #f62d51;
    border-color: #f62d51;
    color: #fff;
}
.btn-light.active:focus:not(:disabled):not(.disabled),
.btn-light:active:focus:not(:disabled):not(.disabled),
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-dark.active:focus:not(:disabled):not(.disabled),
.btn-dark:active:focus:not(:disabled):not(.disabled),
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-outline-primary {
	color: #1ec8c8 !important;
	border-color: #1ec8c8 !important;
}
.btn-outline-primary.active {
	color: #fff !important;
	border-color: #1ec8c8 !important;
}
.btn-outline-primary:hover,.btn-outline-primary:focus {
	background: #6c7171 !important;
	border-color: #6c7171 !important;
	color: #fff !important;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
	box-shadow: none;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
	color: #1ec8c8;
	background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	background-color: #1ec8c8;
	border-color: #1ec8c8;
	color: #fff !important;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-secondary {
    color: #55a3e5;
    border-color: #E5F1FB;
}
.btn-outline-secondary:hover {
	background-color: #1ec8c8;
	border-color: #1ec8c8;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
	box-shadow: none;
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
	color: #1ec8c8;
	background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
	background-color: #E5F1FB;
	border-color: #E5F1FB;
	color: #000;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-success {
	color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:hover {
	background-color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:focus, .btn-outline-success.focus {
	box-shadow: none;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
	color: #00e65b;
	background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
	background-color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-info {
	color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:hover {
	color: #fff;
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:focus, .btn-outline-info.focus {
	box-shadow: none;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
	background-color: transparent;
	color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-warning {
	color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:hover {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
	box-shadow: none;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
	background-color: transparent;
	color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-danger {
	color: #ff0100;
	border-color: #ff0100;
}
.btn-outline-danger:hover {
	color: #fff;
	background-color: #ff0100;
	border-color: #ff0100;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
	box-shadow: none;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
	background-color: transparent;
	color: #ff0100;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
	background-color: #ff0100;
	border-color: #ff0100;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-light {
    color: #ababab;
    border-color: #e6e6e6;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #ababab;
}
.add-btn {
    max-width: 140px;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #1ec8c8;
	border-color: #1ec8c8;
}
.pagination > li > a,
.pagination > li > span {
	color: #1ec8c8;
}
.page-link:hover {
	color: #1ec8c8;
}
.page-link:focus {
	box-shadow: unset;
}
.page-item.active .page-link {
	background-color: #1ec8c8;
	border-color: #1ec8c8;
}
.dropdown-menu {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    box-shadow: inherit;
	font-size: 14px;
    transform-origin: left top 0;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #0de0fe;
}
.navbar-nav .open .dropdown-menu {
	border: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}
.card {
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    margin-bottom: 0;
}
.card-body {
	padding: 1rem;
}
.card-title {
    margin-bottom: 15px;
}
.card-header {
	border-bottom: 1px solid #f0f0f0;
	padding: 10px;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #f0f0f0;
	padding: 1rem 1.5rem;
}
.card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
	border-radius: 8px 8px 0px 0;
	margin: 0;
}
.card .card-header .card-title {
    margin-bottom: 0;
}
.btn-light {
	border-color: #e6e6e6;
	color: #a6a6a6;
}
.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #1ec8c8;
	text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today::before{
	border-bottom-color: #1ec8c8;
}
.bg-info-light {
	background-color: rgba(197, 229, 255, 0.25);
	color: #1ec8c8;
}
.bg-info-light:hover {
	background-color: #1ec8c8;
	color: #FFF;
}
.bg-primary-light {
	background-color: rgba(17, 148, 247,0.12) !important;
	color: #2196f3 !important;
}
.bg-danger-light {
	background-color: rgba(255, 168, 167, 0.25);
	color: #e63c3c;
	transition: all 0.4s ease;
}
.bg-danger-light:hover {
	background-color: #e63c3c;
	color: #FFF;
}
.bg-warning-light {
	background-color: rgba(255, 152, 0, 0.12);
	color: #EB5757 !important;
	transition: all 0.4s ease;
}
.bg-warning-light:hover {
	background-color: rgba(255, 152, 0, 1);
}
.bg-success-light {
	background-color:  rgba(133, 255, 201, 0.25);
	color: #41B883;
	transition: all 0.4s ease;
}
.bg-success-light:hover {
	background-color:  #41B883;
	color: #FFF;
}
.bg-purple-light {
	background-color: rgba(197, 128, 255,0.12) !important;
	color: #c580ff !important;
}
.bg-default-light {
	background-color: rgba(40, 52, 71,0.12) !important;
	color: #283447 !important;
}
.text-xs {
	font-size: .75rem !important;
}
.text-sm {
	font-size: .875rem !important;
}
.text-lg {
	font-size: 1.25rem !important;
}
.text-xl {
	font-size: 1.5rem !important;
}
.form-control:focus {
	border-color: #bbb;
	box-shadow: none;
	outline: 0 none;
}
.form-group {
	margin-bottom: 0.5rem;
}
.slider-btn{
    background: #1ec8c8;
    color: #fff;
    border: 1px solid #1ec8c8;
    padding: 8px 16px !important;
    -webkit-transition:all .3s ease 0s;
    -moz-transition:all .3s ease 0s;
    -o-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
}
.slider-btn:hover{
    background: #fff;
    color: #1ec8c8;
    border: 1px solid #fff;
    padding: 8px 16px !important;
}
.slider-btn-1{
    color: #fff;
    border: 1px solid #0d2b77;
    background: #2E3842;
    padding: 8px 16px !important;
    -webkit-transition:all .3s ease 0s;
    -moz-transition:all .3s ease 0s;
    -o-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
}
.slider-btn-1:hover{
    color: #2E3842;
    border: 1px solid #fff;
    background: #fff;
    padding: 8px 16px !important;
}

/*-----------------
	14. Login
-----------------------*/

.account-page {
	background-color: #fff;
}
.account-page .content {
    padding: 50px 0;
}
.login-right {
	background-color: #fff;
	border: 1px solid #E5E5E5;
	border-radius: 10px;
	padding: 25px;
}
.login-header {
	margin-bottom: 30px;
}
.login-header p {
	margin-bottom: 0;
	color: #455A64;
}
.login-header h3 {
    font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #1ec8c8;
    margin-bottom: 20px;
}
.login-header h3 a {
    color: #455A64;
    float: right;
    font-size: 14px;
	font-weight: 500;
}
.login-right .forgot-link {
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	text-decoration: underline;
	color: #005BEA;
	margin-bottom: 30px;
}
.login-right .dont-have {
    color: #000;
    margin-top: 25px;
    font-size: 15px;
}
.login-right .dont-have a {
	text-decoration: underline;
	color: #005BEA;
}
.login-btn {
    font-size: 20px;
    font-weight: 600;
    min-width: 250px;
    padding: 14px 30px;
}
.login-or {
	color: #000;
	margin-bottom: 30px;
	margin-top: 30px;
	padding-bottom: 10px;
	padding-top: 10px;
	position: relative;
}
.or-line {
	background-color: #000;
	height: 1px;
	margin-bottom: 0;
	margin-top: 0;
	display: block;
}
.span-or {
	background-color: #fff;
	display: block;
	left: 50%;
	margin-left: -20px;
	position: absolute;
	text-align: center;
	top: -3px;
	width: 42px;
}
.forgot-link {
    color: #3d3d3d;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 10px;
	font-weight:400;
}
.btn-facebook, .btn-google {
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
	border-radius: 10px;
    color: #000;
    font-weight: bold;
	font-size: 15px;
    padding: 12px 12px;
}

.btn-facebook img, .btn-google img {
	margin-right: 12px;
}
.social-login .btn:hover, .social-login .btn:focus {
	color: #000;
}
.login-page .login-right {
    width: 100%;
    position: relative;
    font-family: 'Poppins', sans-serif;
    height: 100vh;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
	border: 0;
    padding: 0;
    border-radius: 0;
}
.login-bottom-copyright {
	color: #A4A4A4;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-self: flex-end;
	-webkit-align-self: flex-end;
	padding-bottom: 25px;
	width: 100%;
}
.login-right .inner-right-login {
    max-width: 355px;
    margin: 0 auto;
    width: 100%;
}
.login-header .logo-icon {
    margin: 40px auto 20px;
    text-align: center;
}
.step-list {
	padding: 10px 0;
	text-align: center;
}
.step-list ul {
	list-style: none;
	padding: 0;
	margin: 0;
	display: inline-block;
}
.step-list ul li {
	float: left;
	padding-right: 30px;
	margin: 5px 0;
}
.step-list ul li:last-child {
	padding-right: 0;
}
.step-list ul li a {
	background-color: #e9f5f7;
	width: 40px;
	height: 40px;
	border-radius: 10px;
	font-size: 14px;
	color: #808080;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
}
.step-list ul li a:hover {
	background-color: #0de0fe;
	color: #fff;
}
.step-list ul li a.active {
	background-color: #0de0fe;
	color: #fff;
}
.step-list ul li a.active-done {
	background-color: #0de0fe;
	color: #fff;
}
.step-list ul li a.active-done {
	background-repeat: no-repeat;
	background-position: center;
	background-size: 18px;
	font-size: 0;
}
.profile-pic-col {
	padding-top: 40px;
	text-align: center;
}
.profile-pic-col h3 {
	color: #000;
	font-size: 20px !important;
}
.profile-pic-upload {
	width: 150px;
	background: rgba(175, 213, 254, 0.4);
	border-radius: 20px;
	padding: 24px;
	margin: 40px auto 0;
	position: relative;
}
.profile-pic-upload input[type="file"] {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}
.profile-pic-upload .cam-col {
	width: 70px;
	height: 70px;
	margin: 0 auto;
	background-color: #fff;
	border-radius: 70px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	overflow: hidden;
}
.profile-pic-upload span {
	color: #000;
	font-size: 13px;
	display: inline-block;
	margin-top: 15px;
}
.select-gender-col [type="radio"]:checked,
.select-gender-col [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.select-gender-col [type="radio"]:checked + label,
.select-gender-col [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
.select-gender-col [type="radio"]:checked + label:before,
.select-gender-col [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 5px 20px 60px 10px rgba(0, 4, 107, 0.07);
}
.select-gender-col [type="radio"]:checked + label:after,
.select-gender-col [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 100%;
    height: 100%;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border:2px solid #0de0fe;
}
.select-gender-col [type="radio"]:not(:checked) + label:after {
    opacity: 0;
}
.select-gender-col [type="radio"]:checked + label:after {
    opacity: 1;
}
.select-gender-col .gender-icon {
	position: relative;
}
.select-gender-col label {
	padding: 15px 20px !important;
	text-align: center;
}
.select-gender-col span {
	position: relative;
	color: #000;
	display: inline-block;
	margin-top: 10px;
	font-size: 16px;
	width: 100%;
}
.gender-icon img {
    max-height: 90px;
}
.pregnant-col .remember-me-col {
	color: #000;
	font-size: 16px;
}
.pregnant-col .remember-me-col .custom_check .checkmark {
	border-color: #0de0fe;
}
.step-process-col {
	text-align: left;
}
.login-page .login-right .step-process-col label {
	color: #0de0fe;
}
.login-right .step-process-col .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #808080;
}

.login-page .login-right .step-process-col .form-control {
	color: #808080;
}
.checklist-col span {
	font-size: 16px;
}
.checklist-col .custom_check .checkmark {
	width: 24px;
	height: 24px;
}
.checklist-col .custom_check .checkmark:after {
	left: 6px;
	top: 3px;
}
.checklist-col .remember-me-col {
	padding: 10px 0;
	position: relative;
}
.checklist-col.pregnant-col .remember-me-col .custom_check .checkmark {
	border-color: #B4ABCA;
}
.checklist-col.pregnant-col .remember-me-col .custom_check {
	padding-left: 25px;
}
.otp-col {
	color: #fff;
}
.otp-col h3 {
	color: #fff;
	font-size: 20px;
	margin-bottom: 15px;
}
.dnt-recive-call {
	color: #fff;
}
.dnt-recive-call a {
	color: #fff;
}
.otp-box .digit-group input {
	width: 45px;
	height: 45px;
	background-color: rgba(245,245,245,0.2);
	border: none;
	text-align: center;
	font-size: 16px;
	color: white;
	margin: 0 3px;
	box-shadow: 5px 20px 60px 10px rgba(0, 4, 107, 0.07);
	border-radius: 8px;
	color: #000;
}
.otp-box {
	margin: 25px 0;
}
.otp-box .digit-group input:focus {
	background-color: #fff;
	border: 0;
	outline: 0;
}
.otp-box .splitter {
	padding: 0 5px;
	color: white;
	font-size: 24px;
}
.otp-box .prompt {
	margin-bottom: 20px;
	font-size: 20px;
	color: white;
}
.otp-box input[type="text"].active {
	background-color: #fff;
	color: #000;
}
.increment-decrement .input-groups {
	border: 0;
	background-color: transparent;
	height: 35px;
	margin-right: 0px;
	display: block;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
}
.increment-decrement .input-groups .quantity-field {
	width: 40px;
	text-align: center;
	background-color: transparent;
	float: left;
	border: 0;
}
.increment-decrement .input-groups .quantity-field:focus {
	border: 0;
	outline: 0;
}
.increment-decrement .input-groups input[type="button"] {
	background-color: transparent;
	font-size: 20px;
	border: 1px solid #0de0fe;
	border-radius: 4px;
	height: 24px;
	width: 24px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	margin-top: 0px;
	line-height: 0;
	padding: 0;
	color: #0de0fe;
}
.increment-decrement .input-groups input[type="button"]:focus-visible {
	outline: 0;
}
.increment-decrement .input-groups input[type="button"].button-minus {
	float: left;
}
.increment-decrement .input-groups input[type="button"].button-plus {
	float: right;
}
.country-select {
	position: relative;
}
.country-select .select-col {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
}
.country-select .select-col .flagstrap {
	height: 100%;
}
.country-select .select-col .btn-info.dropdown-toggle {
	border: 0;
	color: #0de0fe;
	border-radius: 10px 0px 0px 10px;
	background-color: #fff;
	height: 100%;
	font-size: 0;
	width: 79px;
	padding: 0 12px;
	text-align: left;
}
.country-select .select-col .btn-info.dropdown-toggle:after {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 14px;
	height: 7px;
	border: 0;
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}
.country-select .select-col .dropdown-menu {
	font-size: 0;
	width: 79px;
	min-width: inherit;
	margin-top: 0;
	padding: 0;
	border-radius: 10px;
}
.country-select .select-col .dropdown-menu li {
	margin: 14px 13px;
}
.country-select .select-col .dropdown-menu li:first-child {
	display: none;
}
.country-select .select-col .dropdown-menu li a {
	display: inline-block;
	cursor: pointer;
}

.country-select .select-col .btn-info.dropdown-toggle span {
	font-size: 0;
}
.login-right .country-select .select-col .select2-container--default .select2-selection--single {
	background-color: #fff;
	border-radius: 10px 0 0 10px;
	width: 79px;
}
.login-right .country-select .select-col .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #0de0fe;
}

.country-select .number-col input {
	padding-left: 96px;
}
.location-text h5 {
	color: #000;
	margin-top: 5px;
}
.location-search {
	margin:12px 0;
}
.login-page .login-right .search-location-input-box input {
	background-repeat: no-repeat;
	background-position: 10px 50%;
	background-size: 16px;
	padding-left: 38px;
	color: #948CA0;
}
.skip-now-col {
	margin-top: 5px;
}
.skip-now-col a {
	color: #000;
	font-size: 12px;
}
.sort-by-col .select2-container .select2-selection--single {
	border-color: #DCD0FF;
	border-radius: 10px;
	width: 160px;
}

.premium-text {
	font-size: 18px;
}
.plan-details {
	list-style: none;
	padding: 4px 0 0;
	margin: 0;
}
.plan-details li {
	position: relative;
	min-width: 35%;
}
.plan-details li span {
	color: #B4ABCA;
	display: inline-block;
	margin-bottom: 3px;
	font-size: 15px;
}
.plan-chooser-col {
	background-color: rgba(175, 213, 254, 0.4);
	border-radius: 20px;
	padding: 5px 20px;
	margin-bottom: 28px;
}
.plan-chooser-col .memeber-img {
	border-radius: 15px;
	margin-bottom: 10px;
	width: 100%;
}
.plan-chooser-col .memeber-img img {
	border-radius: 15px;
}
.memeber-details h4 {
	margin-bottom: 0;
}
.payment-menthods ul {
	list-style: none;
	padding: 0;
	margin: 0;
	text-align: center;
}
.payment-menthods ul li {
	display: inline-block;
	padding: 6px;
}
.select-gender-col.payment-menthods-choose label {
	min-width: 90px;
	padding: 10px !important;
	text-align: center;
	color: #000;
}
.select-gender-col.payment-menthods-choose label span.credit-card-option {
	min-width: 192px;
	font-size: 15px;
}
.select-gender-col.payment-menthods-choose label img {
	position: relative;
	max-width: 68px;
}
.select-gender-col.payment-menthods-choose [type="radio"]:checked + label::after,
.select-gender-col.payment-menthods-choose [type="radio"]:not(:checked) + label::after {
	border: 1px solid #0de0fe;
}
.card-choose-col {
	max-width: 325px;
	margin: 0 auto;
}
.day-week-month-col ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.day-week-month-col ul li {
	float: left;
}
.day-week-month-col ul li a {
	background-color: #F5FAFF;
	border: 1px solid transparent;
	color: #64627C;
	display: inline-block;
	padding: 10px 20px;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
}
.day-week-month-col ul li a.active {
	border-color: #536CF1;
	color: #536CF1;
	border-radius: 0 !important;
}
.day-week-month-col ul li:first-child a{
	border-radius: 4px 0 0 4px !important;
}
.day-week-month-col ul li:last-child a {
	border-radius: 0px 4px 4px 0px !important;
}
.day-week-month-col ul li a:first-child.active,
.day-week-month-col ul li a:last-child.active {
	border-color: #536CF1;
}
.plan-chooser-col .subscription-list {
	margin: 15px 0;
}

/*-----------------
	15. Home
-----------------------*/

.section-search {
	background: linear-gradient(276.54deg, #E5F1FB 0%, rgba(229, 241, 251, 0) 98.1%);
    position: relative;
}
.section-search-1 {
    min-height: 450px;
    position: relative;
    background-blend-mode: Darken;
}
.doctor-search-section {
	position: relative;
    background-position: 50% 0;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
}
.section-search-2 {
	background: #4d72d0;
    background-repeat: repeat;
}
.section-header {
    margin-bottom: 50px;
}
.section-header h2 {
    font-size: 30px;
	line-height: 36px;
    margin-bottom: 0;
    font-weight: bold;
}
.section-header h2 span {
	color: #1ec8c8;
}
.section-header .sub-title {
    color: #757575;
    font-size: 16px;
    max-width: 600px;
    margin: 15px auto 0;
}
.section-header p {
    color: #808191;
    font-size: 16px;
	font-weight: 500;
	line-height: 19px;
    margin-bottom: 0;
    margin-top: 20px;
}
.banner-wrapper {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
    padding: 80px 0;
}
.search-box .search-btn span {
	display: none;
	font-weight: 500;
}
.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li,
.pharmacy-home-slider .swiper-pagination-bullet  {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 5px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    border-radius: 0;
}
.pharmacy-home-slider .swiper-pagination-bullet {
	background: #C0C0C0;
	width: 20px;
    height: 5px;
    opacity: 1;
    transform: scale(1) !important;
    left: 0 !important;
}
.pharmacy-home-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
	background-color: #0de0fe;
	width: 20px;
    height: 5px;
    opacity: 1;
}
.search-img {
    margin-top: 80px;
    margin-bottom: 80px;
}
.text-blue {
	color: #1ec8c8;
}
.banner-wrapper .banner-header {
	margin-bottom: 30px;
}
.banner-wrapper .banner-header h1 {
    margin-bottom: 25px;
	font-size: 35px;
	font-weight: bold;
	line-height: 55px;
}
.banner-wrapper .banner-header p {
	color: #808191;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0;
}
.search-area {
    background: #ffffff6b;
    padding: 60px 30px;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    max-width: 410px;
    margin: auto;
}
.search-doctor {
    margin: auto;
}
.search-area h2 {
    font-size: 1.7rem;
    font-weight: 600;
}
.search-box-1 .search-btn {
    width: 46px;
    -ms-flex: 0 0 46px;
    flex: 0 0 46px;
    height: 46px;
    background: #fff;
    color: #1ec8c8;
}
.search-box-3{
    background: #1e5c92;
    padding: 40px 20px;
    border-radius: 10px;
    margin-top: -50px;
    position: absolute
}
.btn-search{
	background: #fff;
	border: 1px solid #fff;
	color: #1e5c92;
}
.search-box .search-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.search-box .form-control {
    border: 1px solid #f2f2f2;
    border-radius: 8px;
	height: 50px;
}
.search-box .form-control::-webkit-input-placeholder {
	color: #000;
	font-size: 14px;
}
.search-box .form-control::-moz-placeholder {
	color: #000;
	font-size: 14px;
}
.search-box .form-control:-ms-input-placeholder {
	color: #000;
	font-size: 14px;
}
.search-box .form-control::-ms-input-placeholder {
	color: #000;
	font-size: 14px;
}
.search-box .form-control::placeholder {
	color: #000;
	font-size: 14px;
}
.search-box .search-location {
    -ms-flex: 0 0 200px;
    flex: 0 0 200px;
    margin-right: 12px;
    position: relative;
    width: 200px;
}

.search-box .search-info {
    -ms-flex: 1;
    flex: 1;
    position: relative;
	margin-bottom: 30px;
}

.search-box .search-btn {
	padding: 15px 25px;
	margin-top: 30px;
	font-weight: 500;
	font-size: 14px;
	border-radius: 8px;
}
.search-box .search-btn span {
	display: none;
	font-weight: 500;
}
.search-box .form-text {
    color: #808191;
    font-size: 12px;
	margin-top: 12px;
	text-align: left;
	display: block;
}
.banner-img {
	display: flex;
    align-items: flex-end;
}
.section-specialities {
	background-color: #fff;
	padding: 0 0 50px;
}
.section-specialities .see-more {
	margin-top: 10px;
}
.section-category{
	background-color: #fff;
	padding: 80px 0;
}
.dot-slider .slick-slide {
	height: 600px;
}
.dot-slider .profile-widget {
	padding: 0;
	border: none;
}
.dot-slider .slick-slide img {
    width: 100%;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 5px;
    padding: 0;
    cursor: pointer;
    color: transparent;
	border: 0;
	outline: none;
	background: #C0C0C0;
}
.dot-slider .slick-dots {
	position: absolute;
	top: 30%;
    right: 50px;
    bottom: unset;
    width: unset;
}
.dot-slider .slick-dots li {
	width: 15px;
    height: 15px;
	border-radius: 50%;
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: center;
	margin: 8px 0;
}
.dot-slider .slick-dots li button {
	width: 7px;
    height: 7px;
	border-radius: 50%;
	background-color: #fff;
}
.dot-slider .slick-dots li.slick-active button {
    background-color: #1860CA;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
	display: none;
    font-family: 'slick';
	font-size: 76px;
	line-height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	content: '-';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button {
    background-color: #0de0fe;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #0de0fe;
}
.slick-slide {
	outline: none !important;
}
.specialities-slider .slick-slide {
    display: block;
    padding: 0 12px;
    margin-left: 0;
    margin-right: 20px;
	margin-top: 10px;
}
.specialities-slider .slick-dots {
    margin-top: 44px;
    position: unset;
}
.speicality-img {
	position: relative;
	height: 150px;
	box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
	border-radius: 100%;
	width: 150px;
	background: #fff;
}
.speicality-img img {
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin: 0 auto;
}
.speicality-img span {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: absolute;
    bottom: 10px;
    right: 10px;
    box-shadow: 1px 6px 14px rgba(0,0,0,0.2);
    border-radius: 50%;
    padding: 5px;
    background-color: #fff;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.speicality-img span i {
    font-size: 15px;
	color:#0de0fe;
}
.speicality-item p {
    font-size: 16px;
    font-weight: 500;
    margin: 30px 0 0;
}
.section-doctor {
	background-color: #FFFFFF;
	padding: 50px 0 20px;
}
.section-doctor .section-header {
	margin-bottom: 30px;
}
.section-doctor .section-header p {
	margin-top: 10px;
}
.doctor-slider .slick-slide, .doctor-slider1 .slick-slide {
	display: block;
	margin-left: 0;
	padding: 10px;
	width: 280px;
}
.profile-widget {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    margin-bottom:30px;
    position:relative;
    -webkit-transition:all .3s ease 0s;
    -moz-transition:all .3s ease 0s;
    -o-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
	overflow: hidden;
}
.profile-widget:hover:before {
	content: '';
	background: rgba(128, 129, 145, 0.48);
	border: 1px solid #E5F1FB;
	border-radius: 8px;
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.doc-img {
    position: relative;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
}
.doc-img img {
    border-radius: 8px 8px 0 0;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    width: 100%;
}
.doc-img:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.profile-widget .fav-btn {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #fff;
    width: 30px;
    height: 30px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    border-radius: 3px;
    color: #2E3842;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    visibility: hidden;
	z-index: 99;
}
.profile-widget:hover .fav-btn {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.profile-widget .fav-btn:hover {
	background-color: #fb1612;
	color: #fff;
}
.profile-widget .profile-btn-list {
	position: absolute;
	bottom: 0;
	padding: 20px;
	opacity: 0;
    visibility: hidden;
	z-index: 99;
	width: 100%;
    left: 0;
	-webkit-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(0,100%, 0);
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}
.profile-widget:hover .profile-btn-list {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.pro-content {
    padding: 15px;
}
.pro-content .title {
    font-size: 18px;
    font-weight: 500;
	line-height: 12px;
    margin-bottom: 12px;
}
.profile-widget .pro-content .title a {
    display: inline-block;
}
.profile-widget .verified {
	color: #28a745;
	margin-left: 15px;
}
.profile-widget p.speciality {
    font-size: 12px;
    color: #808191;
    margin-bottom: 12px;
}
.rating {
    list-style: none;
    margin: 0 0 7px;
    padding: 0;
    width: 100%;
}
.rating i {
    color: #dedfe0;
}
.rating i.filled {
    color: #f4c150;
}
.profile-widget .rating {
    color: #c4c4c4;
    font-size: 12px;
    margin-bottom: 20px;
}
.profile-widget .rating i {
	font-size: 14px;
}
.profile-widget .rating .average-rating {
	font-weight: 500;
    font-size: 12px;
    color: #000;
}
.available-info {
    font-size: 13px;
    color: #808191;
    font-weight: 400;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}
.available-info li + li {
	margin-top: 10px;
}
.available-info li i.feather-info{
	color: #1ec8c8;
	margin-left: 13px;
}
.available-info li i {
	margin-right: 10px;
}
.see-more {
	padding: 14px 30px;
	font-size: 14px;
    font-weight: 500;
	border-radius: 8px;
}
.row.row-sm {
    margin-left: -3px;
    margin-right: -3px;
}
.row.row-sm > div {
    padding-left: 3px;
    padding-right: 3px;
}
.view-btn {
    color: #FFFFFF;
    font-size: 13px;
    background: #1ec8c8;
    border: 1px solid #1ec8c8;
    text-align: center;
    display: block;
    font-weight: 500;
	font-size: 13px;
	padding: 15px 0;
	margin-bottom: 10px;
}
.view-btn:hover, .view-btn:focus {
	background-color: #0469c8;
	color: #fff;
}
.book-btn {
	background-color: #FFFFFF;
    border: 1px solid #E5F1FB;
    color: #1ec8c8;
    font-size: 13px;
    text-align: center;
    display: block;
    font-weight: 500;
	padding: 15px 0;
	transition: all 0.4s ease;
}
.book-btn:hover, .book-btn:focus {
	background-color: #0469c8;
	border-color: #0469c8;
	color: #fff;
}
.about-content p {
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	margin: 0;
}
.about-content p + p {
	margin-top: 20px;
}
.about-content a {
    background-color: #0de0fe;
    border-radius: 4px;
	color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-top: 30px;
    min-width: 150px;
    padding: 15px 20px;
    text-align: center;
}
.about-content a:hover, .about-content a:focus {
	background-color: #01cae4;
	border-color: #01cae4;
	color: #fff;
}
.section-features {
	background: #E5F1FB;
	padding: 50px 0;
}
.feature-box {
	border: 1px solid #E5F1FB;
	border-radius: 8px;
	margin-bottom: 30px;
}
.feature-box img {
    border-radius: 8px 8px 0px 0px;
}
.feature-content h3 {
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 0;
}
.feature-content h3 a:hover {
	color: #1ec8c8;
}
.feature-content {
    background: #FFFFFF;
	border-radius: 0 0 8px 8px;
	padding: 20px;
	text-align: center;
}
.features-slider .slick-slide {
	margin-right: 62px;
}
.features-slider .slick-center {
    opacity: 1;
    transform: scale(1.20);
}
.features-slider .slick-list {
    padding: 16px 50px !important
}
.features-slider .slick-dots {
    margin-top: 44px;
    position: unset;
}
.section-blogs {
    background-color: #FFFFFF;
    padding: 0 0 60px;
}
.view-all .btn {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 30px;
}
.full-slide-home {
	background: #e3e3e3;
}
.slide-image {
    width: 100%;
	min-height: 358px;
	display: flex!important;
	justify-content: center;
	flex-direction: column;
	padding: 70px 50px 70px 100px;
	vertical-align: middle;
	background-position: right;
	background-size: contain;
}
.slide-image h2 {
	font-size: 36px;
	font-weight: 500;
}
.slide-ul li:before {
	 font-family: "Font Awesome 5 Free";
   	display: inline-block;
   	padding-right: 6px;
   	vertical-align: middle;
   	content: "\f192";
   	color: #a2a1a1;
   	font-size: 12px;
}
.slide-ul li {
	color: #757575;
	font-size: 13px;
}
.home-tile-section {
	background-color: #FFFFFF;
	padding: 50px 0 30px 0;
}
.book-btn1 {
	background-color: #0de0fe;
	border: 2px solid #0de0fe;
	color: #fff;
	font-size: 16px;
	text-align: center;
	font-weight: 500;
	padding: 6px 10px;
}
.book-btn1:hover{
	background-color: #01cae4;
	border-color: #01cae4;
	color: #ffffff;
}
.doctor-book-card {
	position: relative;
	background: #F9FAFE;
	border: 1px solid #E5F1FB;
	border-radius: 8px;
	display: -ms-flexbox;
    display: flex;
	-webkit-align-items: center;
	-ms-align-items: center;
	align-items: center;
	padding: 15px;
	margin-bottom: 20px;
}
.doctor-book-card-content {
	margin-left: 25px;
}
.doctor-book-card-content a {
	font-size: 12px;
	color: #000;
	text-decoration: underline;
}
.doctor-book-card-content a:hover {
	color: #1ec8c8;
}
.search-box1 form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-ms-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
}
.search-box1 .form-control {
    border: 1px solid #ccc;
    box-shadow: inset 0 0px 0px rgba(0,0,0,.075);
    border-radius: 5px;
    padding-left: 35px;
}
.search-box1 .search-location {
    -ms-flex: 0 0 240px;
    flex: 0 0 240px;
    margin-right: 12px;
    position: relative;
    width: 240px;
}
.section-search-pharmacy .search-box1 .search-btn {
	margin-top: 0;
}

.search-box1 .search-info {
    -ms-flex: 0 0 490px;
    flex: 0 0 490px;
    margin-right: 12px;
    position: relative;
    width: 490px;
}
.search-box1 .search-info .form-control {
	background: #fff url() no-repeat 10px center;
}
.search-box1 .search-btn {
    width: 46px;
    -ms-flex: 0 0 46px;
    flex: 0 0 46px;
    height: 46px;
}
.search-box1 .search-btn span {
	display: none;
	font-weight: 500;
}
.search-box1 .form-text {
    color: #757575;
    font-size: 13px;
}
.section-search1 {
    min-height: 580px;
    background-size: 100% auto;
    position: relative;
    background-blend-mode: Darken;
    padding: 80px 0;
}
.banner-wrapper2 {
    margin: 0 auto;
    max-width: 680px;
    width: 100%;
}
.search-box1 .search-location1 {
    margin-right: 12px;
    position: relative;
    width: 400px;
    margin-bottom: 0;
}
.banner-wrapper2{
	margin: 0 auto;
    max-width: 680px;
    width: 100%;
}
.search-detect {
	padding: 5px 9px 5px 28px;
	position: absolute;
	top: 8px;
	right: 5px;
	font-size: 13px;
	color: #757575;
	border-radius: 5px;
	cursor: pointer;
}
.section-search-pharmacy{
	min-height: inherit !important;
	background: #fff;
	padding: 40px 0;
}
.doctor-book-card h3{
	font-weight: 600;
	font-size: 15px;
	line-height: 18px;
	color: #1ec8c8;
	margin-bottom: 7px;
}
.doctor-book-card p{
	font-size: 13px;
	color: #000;
	margin-bottom: 2px;
}
.full-slide-home .slick-prev{
	left: 30px;
}
.full-slide-home .slick-next{
	right: 30px;
}
.full-slide-home .slick-slide > div > .col-12{
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
}
.section-newsletter {
	background: #E5F1FB;
}
.section-newsletter .section-header {
	margin-bottom: 25px;
}
.newsletter-detail {
	padding: 65px 0;
}
.newsletter {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.newsletter .mail-box {
    -ms-flex: 0 0 350px;
    flex: 0 0 350px;
    margin-right: 12px;
    position: relative;
    width: 350px;
    margin-bottom: 0;
}
.newsletter .form-control {
	height: 50px;
	border: 1px solid #fff;
}
.subscribe-btn {
    border-radius: 8px;
    padding: .375rem 2rem;
}
.newsletter .form-control::-webkit-input-placeholder {
	color: #000;
	font-size: 14px;
}
.newsletter .form-control::-moz-placeholder {
	color: #000;
	font-size: 14px;
}
.newsletter .form-control:-ms-input-placeholder {
	color: #000;
	font-size: 14px;
}
.newsletter .form-control::-ms-input-placeholder {
	color: #000;
	font-size: 14px;
}
.newsletter .form-control::placeholder {
	color: #000;
	font-size: 14px;
}
.category-box {
    text-align: center;
    box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
	border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 10px;
    padding: 20px;
	margin-bottom: 30px;
}
.category-box:hover {
    text-align: center;
    box-shadow: 2px 2px 13px rgba(0, 0, 0, 0);
	border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
	cursor: pointer;
}
.category-image{
	background: #1ec8c8;
    height: 60px;
    margin: auto;
    width: 60px;
    align-items: center;
    padding-top: 7px;
	border-radius: 4px;
}
.category-image img{
	width: 45px;
	height: 45px
}
.category-content{
	margin-top: 20px;
}
.category-content h4{
	font-size: 20px;
    font-weight: 500;
    color: #272b41;
}
.banner-header h2 {
	color: #186DB5;
	font-size: 32px;
	font-weight: 600;
	line-height: 54px;
}
.doctor-search-form input {
	background: #FFFFFF;
    border-radius: 130px;
    min-height: 60px;
    border: 0;
}
.doctor-search-form.trans .form-control {
	color: #fff;
}
.doctor-search-form.trans select {
	background: transparent;
    border-radius: 130px;
    min-height: 60px;
}
.doctor-search-form.trans input {
	background-color: transparent;
    border: 2px solid #c2d6f3 !important;
}
.doctor-search-form.trans input::placeholder, .doctor-search-form.trans select::placeholder {
	color: #fff;
}
.trans .form-custom i {
	color: #fff;
}
.trans .banner-btn {
	background-color: #13a5e5;
}
.no-border {
	border:none !important;
}
.doctor-category {
	position: relative;
    margin-top: -130px;
	z-index: 9;
	padding: 60px 0;
}
.pop-box {
	background-color: #ffffff;
    padding: 15px 15px 0;
    border-radius: 5px;
	box-shadow: 0px 4px 44px rgba(219, 219, 219, 0.25);
}
.pop-box .top-section {
	background-color: #E5F6FD;
    padding: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 150px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.pop-box .top-section i {
	background-color: #fff;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    font-size: 30px;
    color: #1ec8c8;
    border: 5px solid #f5f8f9;
}
.pop-box .body-section {
	justify-content: center;
	text-align: center;
	padding: 20px;
	min-height: 200px;
	display: flex;
	flex-direction: column;
}
.pop-box .body-section h3 {
	color: #2467CA;
	font-weight: 600;
	font-size: 24px;
}
.pop-box .body-section p {
	color: #E74E84;
	font-weight: 500;
}
.pop-box .body-section .book-btn {
	width: 150px;
	margin: 0 auto;
	border-radius: 30px;
}
.pop-box .top-section.two {
	background-color: #FFF1C9;
}
.pop-box .top-section.two i {
	color: #FFBD07;
}
.pop-box .top-section.three {
	background-color: #FFE0E7;
}
.pop-box .top-section.three i {
	color: #FFAABD;
}
.clinic-specialities {
	padding: 60px 0;
	background-size: cover;
	background-repeat: no-repeat;
}
.section-header-three {
	margin-bottom: 60px;
}

.section-header-three h2 {
	color: #2868C8;
	font-weight: 700;
	font-size: 30px;
	position: relative;
	display: inline-block;
}
.section-header-three h2:before {
	position: absolute;
	content: "";
	width: 13px;
	height: 13px;
	background: #ffd152;
	border-radius: 50%;
	left: -40px;
	box-shadow: 15px 0px 0px -2px #ffd152;
	top: 12px;
 }
 .section-header-three h2:after {
	position: absolute;
	width: 13px;
	height: 13px;
	content: "";
	background: #ffd152;
	border-radius: 50%;
	top: 12px;
	right: -40px;
	box-shadow: -15px 0px 0px -2px #ffd152;
 }
.section-header-three p {
	color: #757575;
    font-size: 16px;
    max-width: 600px;
    margin: 15px auto 0;
}
.clinic-specialities .section-header h2 {
	color: #2868C8;
	font-weight: 700;
	font-size: 30px;
}
.clinic-specialities .section-header h2 img {
	margin: 2px 5px 5px;
}
.special-box {
	background-color: #fff;
	border-radius: 10px;
	height: 160px;
	cursor: pointer;
	margin-bottom: 30px;
	-webkit-transition: ease all 0.4s;
    -ms-transition: ease all 0.4s;
    transition: ease all 0.4s;
}
.special-box:hover {
	background-color: #1C71EF;
	-webkit-transition: ease all 0.4s;
    -ms-transition: ease all 0.4s;
    transition: ease all 0.4s;
}
.special-box .special-body {
	padding: 20px;
}
.special-box .special-body img {
	width: 50px;
    margin-bottom: 15px;
}
.special-box .special-body h4 {
	font-size: 16px;
	margin: 0;
	font-weight: 500;
}
.special-box .special-footer {
	border-top: 1px solid #EAEAEA;
	padding: 15px;
	width: 100%;
	margin: 0;
}
.special-box .special-footer .doc-count {
	font-size: 14px;
	font-weight: 700;
	color: #E74E84;
}
.special-box .special-footer .doc-count span,.special-box .special-footer .clin-count span {
	color: #000;
	font-weight: 500;
}
.special-box .special-footer .clin-count {
	font-size: 14px;
	font-weight: 700;
	color: #1C71EF;
}
.special-box .spc-book {
	background-color: #fff;
    padding: 10px 15px;
    min-width: 120px;
    color: #E74E84;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 600;
	display: none;
}
.special-box .spc-book i {
	background-color: #E74E84;
    color: #fff;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    font-size: 9px;
}
.special-box:hover img, .special-box:hover .special-footer {
	display: none;
}
.special-box:hover h4 {
	color: #fff;
}
.special-box:hover .spc-book {
	margin-top: 10px;
	display: block;
}
.special-box:hover .special-body {
	height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.view-all-more .btn {
	background: #0CE0FF;
	border-radius: 30px;
	border-color: #0CE0FF;
	padding: 9px 30px;
	margin-top: 20px;
}
.browse-section {
	background-color: #E5F6FD;
	padding: 60px 0;
}
.brower-box {
	text-align: center;
    border-radius: 10px;
    border: 6px solid #fff;
    background-color: #ffffff;
	margin-bottom: 20px;
}
.brower-box > div {
	border-radius: 15px;
    background-color: #e5f6fd;
	height: 100%;
	padding: 15px;
}
.brower-img {
	background-color: #1C71EF;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 0 auto 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.brower-img img{
	width: auto;
}
.brower-box h4 {
	font-size: 16px;
	font-weight: 600;
	color: #2264C5;
}
.brower-box p {
	font-size: 14px;
	font-weight: 500;
	color: #E74E84;
	margin: 0;
}
.brower-box p span {
	color: #000;
}
.doc-background {
	background-position: center;
	min-height: 350px;
	background-size: cover;
	padding: 40px 30px;
	display: flex;
    flex-direction: column;
    justify-content: center;
	position: relative;
	z-index: 1;
}
.doc-background::before {
	content: "";
    position: absolute;
    background: rgba(61, 59, 59, 0.84);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
	z-index: -1;
}
.doctor-divison h3 {
	font-size: 28px;
	color: #fff;
	font-weight: 700;
	margin-bottom: 20px;
}
.doctor-divison p {
	font-size: 14px;
	color: #fff;
	font-weight: 600;
	margin-bottom: 30px;
}
.doctor-divison .doc-bok-btn {
	font-size: 14px;
	padding: 6px 12px;
	color: #fff;
	background-color: #1C71EF;
	border-radius: 30px;
	width: 100px;
	text-align: center;
}
.pat-background {
	background-position: center;
	min-height: 350px;
	background-size: cover;
	padding: 40px 30px;
	display: flex;
    flex-direction: column;
    justify-content: center;
	position: relative;
	z-index: 1;
}
.pat-background::before {
	content: "";
    position: absolute;
    background: rgba(28, 113, 239, 0.9);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
	z-index: -1;
}
.pat-background .doc-bok-btn {
	background-color:#FED35D;
}
.book-section {
	padding: 60px 0;
	background-size: cover;
    background-repeat: no-repeat;
}
.book-best-doctors {
	margin-bottom: 30px;
}
.book-best-doctors .book-header img {
	border-radius: 10px 10px 0px 0px;
	width: 100%;
	position: relative;
	display: inline-block;
	z-index: 1;
}
.book-best-doctors .book-header img::before {
	content: "";
    position: absolute;
    background: rgba(61, 59, 59, 0.84);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
}
.book-best-doctors .book-header .img-overlay {
    position: absolute;
    top: 10px;
    right: 20px;
    background: #E74E84;
    border-radius: 50%;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.book-best-doctors .book-header .img-overlay i {
	font-size: 12px;
}
.doctors-body .inner-section > span {
	color: #E74E84;
	font-size: 14px;
}
.book-best-doctors .doctors-body {
	padding: 0;
	box-shadow: 0px -6px 54px rgba(152, 152, 152, 0.25);
	border-radius: 0;
}
.book-best-doctors .doctors-body .inner-section {
	padding: 15px 15px 0;
}
.book-best-doctors .doctors-body .rating i {
	font-size: 12px;
	color: #DFDFDF;
	margin-bottom: 10px;
}
.book-best-doctors .doctors-body .rating i.filled  {
	color: #FED35D;
}
.book-best-doctors .doctors-body .average-ratings {
	background-color: #FED35D;
	font-size: 11px;
	font-weight: 700;
}
.book-best-doctors .doctors-body h4 {
	color: #22396B;
	text-transform: uppercase;
	font-size: 20px;
	margin-bottom: 5px;
}
.book-best-doctors .doctors-body p {
	color: #0098AE;
	font-size: 12px;
	font-weight: 600;
}
.book-best-doctors .loc-details {
	margin: 15px 0 0;
	background-color: #f6faff;
	padding: 15px 10px 0;
}
.book-best-doctors .loc-details .d-flex {
	margin-bottom: 15px;
}
.book-best-doctors .loc-details img {
	color: #22396B;
	width: auto;
    margin-right: 8px;
}
.book-best-doctors .available-info {
	font-weight: 500;
	font-size: 12px;
	text-transform: uppercase;
	clear: both;
    display: block;
    margin: 0;
}
.book-best-doctors .data-info {
	font-weight: 700;
	font-size: 11px;
	color: #2264C5;
}
.book-best-doctors .amt-txt {
	color: #E74E84;
	font-size: 18px;
	font-weight: 600;
}
.book-best-doctors .doctors-body .book-btn {
	font-size: 14px;
    padding: 6px 12px;
    color: #fff;
    background-color: #1C71EF;
    border-radius: 30px;
    width: 100px;
    text-align: center;
	float: right;
}
.latest-blog {
	background-color: #f4fcff;
	padding: 60px 0 30px;
}
.latest-blog .blog-wrap {
	margin-bottom: 30px;
	background-color: #fff;
	border-radius: 10px 10px 0px 0px;
}
.latest-blog .blog-wrap img {
	border-radius: 10px;
}
.latest-blog .blog-wrap .blog-wrap-body {
	padding: 20px 15px;
	background-color: #fff;
}
.latest-blog .blog-wrap .blog-wrap-body h3 {
	font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}
.latest-blog .blog-wrap .blog-wrap-body h3 a:hover {
	color: #2b6ccb;
}
.latest-blog .blog-wrap .blog-wrap-body p{
	font-size: 14px;
    color: #737373;
}
.latest-blog .blog-wrap .blog-wrap-body .blog-footer{
	border-top: 1px solid #1b70ee;
    padding: 15px 0 0;
}
.latest-blog .blog-footer .read-txt, .latest-blog .blog-footer .cal-txt {
	color: #1b70ee;
    font-size: 14px;
    font-weight: 600;
}
.latest-blog .blog-footer .read-txt i {
	margin: 2px 6px;
    position: absolute;
    top: 3px;
}
.latest-blog .blog-footer .cal-txt i {
	margin: 2px 6px;
}
.clinics-section {
	padding: 60px 0 40px;
	background: #fff;
}
.section-heading h2 {
	font-weight: 700;
	font-size: 26px;
	color: #1C5B90;
}
.section-heading p {
	font-size: 14px;
	color: #757578;
	margin-bottom: 0;
}
.section-heading {
	margin-bottom: 30px;
}
.clinics-card {
	position: relative;
	border-radius: 10px;
    overflow: hidden;
}
.clinic-item .clinics-card .clinics-img:after {
	content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(28, 91, 144, 0.9);
}
.clinics-info {
	position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
	text-align: center;
}
.clinics-info img {
    width: 60px !important;
	margin: auto;
	margin-bottom: 10px;
}
.clinics-info span {
    font-weight: 600;
    font-size: 18px;
    color: #fff;
}
.clinics-icon i {
	border-radius: 50%;
    height: 35px;
    width: 35px;
    text-align: center;
    line-height: 35px;
    background: #10DEFD;
    color: #fff;
	margin: auto;
    display: block;
}
.clinics-icon {
    transform: translate(0, -50%);
    -webkit-transition:all .3s ease 0s;
    -moz-transition:all .3s ease 0s;
    -o-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
}
.clinics-img {
	position: relative;
}
.clinic-item:hover .clinics-card .clinics-img:after {
	background: rgba(45, 226, 253, 0.9);
}
.clinic-item:hover .clinics-icon i {
	color: #1C5B90;
	background: #fff;
}
.clinic-item:hover .clinics-icon {
	transform: translate(0, -180%);
}
.specialities-section {
	position: relative;
    background-position: 50% 0;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
}
.specialities-section:after {
	content: "";
    position: absolute;
    background: rgba(25, 69, 107, 0.9);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
}
.section-heading.bg-area h2 {
	color: #fff;
}
.section-heading.bg-area p {
	color: #fff;
}
.specialist-card {
	background: #FFFFFF;
	box-shadow: 0px 4px 24px rgba(183, 183, 183, 0.25);
	border-radius: 163px;
	align-items: center;
	padding: 15px;
	margin-bottom: 25px;
}
.specialist-card:hover {
	background: #E0F2FF;
}
.specialist-img {
    background: #1B5D92;
    padding: 10px;
    height: 55px;
    width: 55px;
    border-radius: 50%;
	margin-right: 10px;
    -webkit-transition:all .3s ease 0s;
    -moz-transition:all .3s ease 0s;
    -o-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
}

.specialist-card:hover .specialist-img {
    background: #10DEFD;
}
.specialist-info h4 {
	font-weight: 600;
	color: #274782;
	margin-bottom: 0;
}
.specialist-info p {
	font-weight: 500;
	color: #000;
	margin-bottom: 0;
}
.specialist-nav i {
	border-radius: 50%;
    height: 35px;
    width: 35px;
    text-align: center;
    line-height: 35px;
    background: #FFFFFF;
	box-shadow: 0px 4px 24px rgba(183, 183, 183, 0.25);
    color: #D84153;
    margin: auto;
    display: block;
    -webkit-transition:all .3s ease 0s;
    -moz-transition:all .3s ease 0s;
    -o-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
}
.specialist-card:hover .specialist-nav i {
	background: #10DEFD;
	color: #fff;
}
.specialities-section {
	padding: 80px 0 55px;
}
.our-doctors-card {
	background: rgba(232, 246, 255, 0.4);
	border: 1px solid rgba(231, 237, 255, 0.5);
	border-radius: 10px;
	padding: 10px;
}
.doctors-body .rating {
    font-size: 12px;
}
.doctors-body .rating i.filled {
    color: #1C5B90;
}
.average-ratings {
	background: #1C5B90;
    border-radius: 70px;
    color: #fff;
    padding: 1px 15px;
	margin-left: 10px;
}
.doctors-body h4 {
	color: #1C1C1C;
	font-weight: 600;
	margin-bottom: .5rem;
}
.doctors-body p {
	color: #204892;
	font-weight: 500;
	margin-bottom: 0;
	font-size: 12px;
}
.doctors-body .location p {
	font-size: 14px;
	color: #000;
}
.doctors-body .location p i {
	color: #1C5B90;
}
.doctors-body .view-btn {
	background: #E8F6FF;
    border: 1px solid #E8EEFF;
    box-sizing: border-box;
    border-radius: 30px;
    color: rgba(41, 68, 129, 0.9);
	padding: 10px 0;
}
.doctors-body .book-btn {
	background: #10DEFD;
	border-radius: 30px;
	padding: 10px 0;
	border: 0;
}
.doctors-body .book-btn:hover {
	border: 0;
}
.doctors-body {
	background: #FFFFFF;
	border-radius: 0px 0px 10px 10px;
	padding: 10px;
}
.doctors-body .location {
    padding: 20px 0;
}
.doctors-header img {
	border-radius: 10px 10px 0 0;
}
.our-doctors-section {
	padding: 60px 0;
	background: #fff;
}
.doctors-header {
	position: relative;
}
.doctors-header .img-overlay {
	position: absolute;
    top: 10px;
    right: 10px;
    background: #1B5D92;
    border-radius: 110px;
    padding: 5px 10px;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
}
.clinic-features-section {
	position: relative;
    background-position: 50% 0;
    background-size: cover;
    background-repeat: no-repeat;
	padding: 60px 0;
}
.clinic-features {
	position: relative;
}
.clinic-features img {
	border-radius: 10px;
}
.clinic-feature-overlay .img-overlay {
    background: #1ec8c8;
    border-radius: 60px;
    padding: 10px 15px;
    text-transform: uppercase;
    color: #E6F4FD;
    font-weight: 600;
	min-width: 150px;
    display: inline-block;
}
.clinic-feature-overlay {
	position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
}
.our-blog-section {
	padding: 60px 0;
	background: #fff;
}
.blogs-info span {
	color: #1ec8c8;
}
.blogs-info h4 {
	color: #000;
	line-height: 28px;
}
.blogs-info p {
	color: #000;
	font-size: 13px;
}
.blogs-time {
	color: #17418E !important;
}
.blogs-overlay {
	align-items: center;
    background: #FFFFFF;
    border-radius: 30px 0px 0px 30px;
    position: absolute;
    bottom: 10px;
    right: 0;
    padding: 5px;
}
.blogs-img {
	position: relative;
}
.blogs-overlay img {
	height: 35px;
	width: 35px !important;
	border-radius: 50%;
	margin-right: 10px;
}
.blogs-writter {
	display: none;
}
.our-blogs {
	display: block;
	transition: .6s;
	border-radius: 10px 10px 0px 0px;
    overflow: hidden;
}
.our-blogs:hover .blogs-writter {
	display: block;
}
.blogs-info {
	background: #FFFFFF;
    border: 1px solid #E8EEFF;
    padding: 15px;
}
.blogs-btn {
	background: #1C5B90;
	border-radius: 30px;
	color: #fff;
	padding: 8px 10px;
    display: block;
    width: 110px;
    text-align: center;
    font-size: 12px;
}
.blogs-nav {
	border: 1px solid #E8EEFF;
    padding: 10px;
    border-top: 0;
}
.form-custom {
	position: relative;
}
.form-custom i {
	position: absolute;
	top: 35%;
    right: 15px;
	color: #DD2D4E;
}
.doctor-search-section {
	padding-top: 95px;
}
.banner-btn {
    background: #1C5B90;
    border-radius: 130px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    padding: 20px 30px;
	width: 100%;
}
.banner-btn:hover {
    background: #10DEFD;
    color: #fff;
}
.doctor-search {
    padding-bottom: 15px;
}
.slide-nav-1 button.owl-next,
.slide-nav-1 button.owl-prev,
.slide-nav-2 button.owl-next,
.slide-nav-2 button.owl-prev,
.slide-nav-3 button.owl-next,
.slide-nav-3 button.owl-prev,
.slide-nav-4 button.owl-next,
.slide-nav-4 button.owl-prev  {
	width: 34px;
	height: 34px;
	background: #10DEFD;
	text-shadow: none;
	top: 4px;
	cursor: pointer;
	border: 0;
	color: #ffffff;
	margin-bottom: 15px;
	border-radius: 8px;
	transform: rotate(-44deg);
}

.owl-nav button.owl-next span,
.owl-nav button.owl-prev span {
	font-size: 34px;
	line-height: 25px;
	transform: rotate(44deg);
	display: flex;
	padding-left: 6px;
	padding-bottom: 3px;
}

.owl-nav button.owl-next {
	margin-left: 20px;
}

.owl-theme .owl-dots,
.owl-nav button {
	margin-top: 30px;
}

.owl-theme .owl-dots .owl-dot span {
	width: 28px;
	height: 6px;
	margin: 5px 3px 0;
	background: #F3F3F3;
	display: block;
	-webkit-backface-visibility: visible;
	transition: opacity .2s ease;
	border-radius: 30px;
	position: relative;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
	background: #00825B;
}
.custom-arrow {
    transform: rotate(44deg);
}
.shapes {
	position: absolute;
	width: 100%;
}
.shape-1 {
    position: absolute;
    top: 0;
    left: 0px;
}
.shape-2 {
	position: absolute;
    top: 0;
    right: 0;
}
.shape-3 {
	position: absolute;
    top: -110px;
    left: 0px;
}
.shape-4 {
	position: absolute;
    top: -110px;
    right: 0;
}
.section-search-3 {
	position: relative;
    background-position: 50% 0;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
	min-height: 450px;
	padding-top: 85px;
}
.section-search-3:after {
    content: "";
    position: absolute;
    background: rgba(24, 96, 202, 0.9);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
}
.header-banner h2 {
	color: #FFFFFF;
	font-weight: 600;
	line-height: 40px;
}
.header-banner p {
	font-size: 17px;
	color: #FFFFFF;
}
.header-highlight {
	font-weight: 700;
	color: #0CE0FF;
}
.section-search-3 .banner-info {
	padding: 130px 0 190px;
}
body .multi-optn {
	background-color: #ffffff;
}
.pharmacy-home-slider .banner-wrapper.doc-form {
	left: 35%;
}
.side-dots .swiper-container {
    position: relative;
    padding: 0 !important;
    height: 500px;
    min-height: inherit;
}
.doc-form {
	position: absolute;
	top: 155px;
    padding: 50px 0;
}
.side-dots img {
	width: 100%;
}
.app-form {
	position: relative;
	padding: 30px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.87);
}
.app-form h2 {
	color: #E74E84;
	font-size: 34px;
	margin-bottom: 30px;
}
.app-form h2 .header-highlight {
	color: #1860CA;
	font-weight: 600;
}
.app-form .doctor-search-form input {
    border-radius: 20px;
}
.app-form .form-custom i {
	color: #0CE0FF;
}
.app-form .banner-btn {
	background-color: #0CE0FF;
	padding: 18px 30px;
	border-radius: 20px;
}
.category-sec {
	margin-top: -30px;
    padding: 0 0 50px 0;
}
.set-category {
	background-color: #1860CA;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 15px;
	border-radius: 20px;
	min-height: 200px;
}
.set-category .img-holder {
	margin-right: 20px;
}
.set-category .img-holder a {
	width: 60px;
    height: 60px;
    display: flex;
    background-color: #fff;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
.set-category .img-holder a img {
	width: 30px;
}
.set-category .info-holder h3 {
	font-size: 26px;
	font-weight: 700;
	color: #fff;
}
.set-category .info-holder p {
	font-size: 14px;
	color: #fff;
	margin-bottom: 30px;
}
.set-category .info-holder .book-now {
	color: #fff;
}
.set-category .info-holder .book-now i {
	margin: 4px 6px;
    position: absolute;
}
.set-category  h1 {
	font-size: 60px;
    position: absolute;
    font-weight: 700;
    bottom: -13px;
    right: 25px;
    color: #fff;
    opacity: 0.1;
	margin: 0;
}
.set-category.light {
	background-color: #0CE0FF;
}
.set-category.lighter {
	background-color: #00BCD1;
}
.clinic-section {
	padding: 50px 0 20px;
}
.sec-header {
	margin-bottom: 40px;
	text-align: center;
}
.sec-header h2 {
    font-size: 30px;
    margin-bottom: 0;
    font-weight: 700;
	color: #22396B;
}
.sec-header .bottom-border {
    border-top: 3px solid #E74E84;
    width: 75px;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;
    display: inline-block;
	border-radius: 150px;
}
.sec-header .bottom-border:after {
    content: "";
    position: absolute;
    width: 6px;
    background: #fff;
    right: 4px;
    height: 3px;
    z-index: 9;
    top: -3px;
}
.clinic-slider .slick-list, .browse-slider .slick-list, .book-slider .slick-list {
	padding-top: 50px;
}
.clinic-slider .slick-prev, .clinic-slider .slick-next, .browse-slider .slick-prev,
.browse-slider .slick-next, .book-slider .slick-prev, .book-slider .slick-next {
	width: 35px;
    height: 35px;
    border-radius: 5px 12px 5px 12px;
    background-color: #E74E84;
    transform: translateX(0%) rotate(-45deg);
    -webkit-transform: translateX(0%) rotate(-45deg );
    -ms-transform: translateX(0%) rotate(-45deg);
	z-index: 999;
}
.clinic-slider .slick-prev:before, .browse-slider .slick-prev:before, .book-slider .slick-prev:before {
	content: '';
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}
.clinic-slider .slick-next:before, .browse-slider .slick-next:before, .book-slider .slick-next:before {
    content: '';
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}
.clinic-slider .slick-prev:before, .clinic-slider .slick-next:before , .browse-slider .slick-prev:before,
.browse-slider .slick-next:before, .book-slider .slick-prev:before, .book-slider .slick-next:before {
	font-family: 'slick';
    font-size: 10px;
    line-height: 1;
    opacity: .75;
    color: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}
.clinic-slider .slick-prev, .browse-slider .slick-prev, .book-slider .slick-prev {
	right: 80px;
    left: unset;
    top: 0;
}
.clinic-slider .slick-next, .browse-slider .slick-next, .book-slider .slick-next {
	right: 15px;
    left: unset;
    top: 0;
}
.clinic-slider .slick-slide, .browse-slider .slick-slide, .book-slider .slick-slide {
    display: block;
    margin-left: 0;
    padding: 10px;
    width: 280px;
	padding-bottom: 0;
}
.clinic-slider .profile-widget, .browse-slider .browse-widget, .book-slider .profile-widget {
	padding: 0;
	border: none;
    background-color: transparent;
    border-radius: 0;
}
.clinic-slider .brower-box {
	background: #FFFFFF;
	border: 1px solid #F2F2F2;
	box-sizing: border-box;
	box-shadow: 0px 4px 44px rgba(238, 238, 238, 0.25);
	border-radius: 10px;
	padding: 30px;
	margin: 0;
	transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}
.clinic-slider .brower-box:hover {
	background-color: #1860CA;
	transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}
.clinic-slider .brower-box a {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 85px;
    width: 85px;
    background-color: #1860CA;
    border-radius: 50% 50% 50% 50% / 60% 60% 35% 35%;
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
	margin: 0 auto;
	margin-bottom: 15px;
}
.clinic-slider .brower-box img {
	width: 70px;
	-ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.clinic-slider .brower-box h4 {
	color: #000;
	font-size: 20px;
	margin-bottom: 13px;
}
.clinic-slider .brower-box p {
	margin-bottom: 20px;
}
.clinic-slider .brower-box .arrow {
	display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0CE0FF;
    width: 35px;
    height: 35px;
    margin: 0 auto;
    border-radius: 50%;
	transform: unset;
}
.clinic-slider .brower-box .img-fluid.hover {
	display: none;
}
.clinic-slider .brower-box .arrow i {
	color: #fff;
}
.clinic-slider .brower-box:hover a {
	background-color: #fff;
}
.clinic-slider .brower-box:hover .img-fluid.hover {
	display: block;
}
.clinic-slider .brower-box:hover .img-fluid {
	display: none;
}
.clinic-slider .brower-box:hover a.arrow {
	background-color: #2868C8;
}
.clinic-slider .brower-box:hover h4, .clinic-slider .brower-box:hover p, .clinic-slider .brower-box:hover p span {
	color: #fff;
}
.browse-speciality {
	padding: 50px 0 35px;
	background-color: #F8FBFF;
}
.browse-widget {
	padding: 0;
}
.browse-widget > img {
	border-radius: 10px;
	width: 100%;
}
.browse-widget .overlay {
	position: absolute;
    top: 0;
    background-color: rgba(34, 100, 197, 0.8);
    width: 100%;
    height: 100%;
    padding: 10px;
	border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
	-webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}
.browse-widget:hover .overlay {
	background-color: rgba(36, 36, 36, 0.5);
}
.browse-widget .overlay h3 {
	font-size: 18px;
	font-weight: 600;
	color: #fff;
}
.browse-widget .overlay .arrows {
	position: absolute;
    bottom: -15px;
    background-color: #0ce0ff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.browse-widget .overlay .arrows i {
	color: #fff;
}
.browse-widget:hover .arrows {
	transform: translate(0, -320%);
	background-color: #ffffff;
}
.browse-widget:hover .arrows i {
	color: #1C5B90;
}
.patient-select {
	padding: 50px 0;
	background-size: cover;
}
.select-box {
	padding: 30px;
	border-radius: 60px 10px 10px 60px;
	border: 5px solid rgba(255, 255, 255, 0.8);
	position: relative;
    z-index: 1;
}
.select-box::before {
	content: "";
    position: absolute;
    background: rgba(16, 222, 253, 0.6);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
	border-radius: 55px 5px 5px 55px;
}
.select-box h2 {
	font-size: 30px;
	font-weight: 700;
	color: #fff;
	margin-bottom: 15px;
}
.select-box .image-holder {
	margin-right: 20px;
}
.select-box .image-holder span {
	background-color: #fff;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
	border: 6px solid rgb(210 237 246);
}
.select-box .image-holder span img {
	width: 55px;
}
.select-box p {
	font-size: 15px;
	color: #fff;
	margin-bottom: 30px;
}
.select-box .book-now {
	background-color: #1C71EF;
    padding: 6px 20px;
    color: #fff;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 600;
	display: block;
    width: 110px;
}
.select-box.theme {
	border-radius: 10px 60px 60px 10px;
}
.select-box.theme::before {
	content: "";
    position: absolute;
    background: rgba(28, 113, 239, 0.6);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
	border-radius: 5px 55px 55px 5px;
}
.select-box.theme .image-holder span {
	border: 6px solid rgb(217 225 240);
}
.select-box.theme .book-now {
	background-color: #20D7F2;
}
.book-doctor {
	padding: 50px 0;
}
.book-slider .profile-widget {
	padding: 0;
	border: none;
	margin-bottom: 70px;
}
.book-slider .profile-widget a {
	border-radius: 10px 10px 0px 0px;
}
.book-slider .profile-widget a img {
	width: 100%;
	border-radius: 10px 10px 0px 0px;
}
.book-slider .pro-content {
	padding: 0;
	position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);;
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 10px 10px 0px 0px;
}
.book-slider .pro-content .amt {
	background-color: #e74e84;
    padding: 3px 8px;
    border-radius: 30px;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
	opacity: 0;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}
.book-slider .pro-content .star {
	background-color: #2264c5;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    right: 0;
    color: #fff;
    font-size: 12px;
}
.book-slider .profile-widget:hover .amt {
	opacity: 1;
}
.book-slider .provider-info h3 a {
	font-size: 22px;
	font-weight: 700;
	color: #22396B;
}
.book-slider .profile-widget .provider-info {
	background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    position: absolute;
    bottom: -40px;
    width: 100%;
    left: 0;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}
.book-slider .profile-widget:hover .provider-info {
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}
.book-slider .provider-info h5 {
	color: #E74E84;
	font-size: 15px;
	font-weight: 600;
	float: left;
}
.book-slider .provider-info .side-arrow {
	position: absolute;
    width: 40px;
    height: 40px;
    background-color: #2868C8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 15px;
    right: 15px;
    color: #fff;
}
.book-slider .provider-info .rating {
	display: none;
    font-size: 12px;
    width: auto;
	text-align: right;
}
.book-slider .provider-info .rating i {
	font-size: 12px;
	color: #DFDFDF;
}
.book-slider .provider-info .rating i.filled {
	color: #FED35D;
}
.book-slider .provider-info .rating span {
	padding: 0 8px;
    background-color: #FED35D;
    border-radius: 30px;
    font-size: 12px;
    color: #fff;
    font-weight: 700;
    margin-left: 5px;
}
.book-slider .profile-widget .content-info {
	display: none;
	padding: 0;
}
.book-slider .profile-widget:hover .side-arrow {
	display: none;
}
.book-slider .profile-widget:hover .rating, .book-slider .profile-widget:hover .content-info {
	display: block;
}
.book-slider .available-info li i {
    font-size: 14px;
	color: #1C5B90;
}
.book-slider .available-info li {
	font-size: 14px;
	font-weight: 500;
	color: #000;
}
.book-slider .content-info .view-btn,.book-slider .content-info .view-btn:hover {
	background-color: #216EE1;
	border: 1px solid #216EE1;
	border-radius: 30px;
	padding: 7px;
	border: none;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
}
.book-slider .content-info .book-btn, .book-slider .content-info .book-btn:hover {
	background-color: #10DEFD;
	border: 1px solid #10DEFD;
	border-radius: 30px;
	padding: 7px;
	border: none;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
}
.book-doctor .view-btn {
	background-color: #1ec8c8;
	font-size: 16px;
	color: #ffffff;
	font-weight: 700;
	border-radius: 30px;
	border: 1px solid #1ec8c8;
	padding: 6px 20px;
}
.aval-features {
	background-color: #2467CA;
}
.aval-features .feature {
	padding: 40px;
    background-repeat: no-repeat;
    background-size: cover;
}
.aval-features .feature .info-box {
	background: rgba(24, 96, 202, 0.8);
    padding: 40px;
}
.aval-features .feature .info-box h2 {
	font-size: 40px;
	font-weight: 700;
	color: #fff;
	margin-bottom: 15px;
}
.aval-features .feature .info-box p {
	color: #ffffff;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 20px;
}
.aval-features .feature .info-box a {
	background: #10DEFD;
	border-radius: 30px;
	width: 120px;
	color: #fff;
	padding: 6px;
}
.aval-features .aval-widget .features-img {
	width: 150px;
    height: 150px;
    border-radius: 80px 60px 50px 50px;
    margin: 0 auto;
}
.aval-features .aval-widget .features-img img {
	border-radius: 80px 60px 50px 50px;
    width: 100%;
    border: 4px solid #FFFFFF;
}
.aval-features .aval-widget .features-img p {
	background: #1ec8c8;
    border-radius: 30px;
    color: #fff;
    text-align: center;
    padding: 6px;
	margin: 20px 0 0;
}
.aval-slider .slick-slide {
	display: block;
    margin-left: 0;
    padding: 10px;
    width: 280px;
}
.aval-slider .aval-widget {
	margin-bottom: 30px;
    position: relative;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}
.aval-slider .slick-list {
    padding: 50px 0;
}
.aval-slider .slick-prev, .aval-slider .slick-next {
	width: 35px;
    height: 35px;
}
.aval-slider .slick-prev {
	left: 5px;
}
.aval-slider .slick-prev:hover, .aval-slider .slick-prev:focus, .aval-slider .slick-next:hover, .aval-slider .slick-next:focus {
	background-color: #fff;
}
.aval-slider .slick-next {
	right: 5px;
}
.aval-slider .slick-prev:before{
	font-family: 'slick';
    font-size: 10px;
    line-height: 1;
    opacity: .75;
    color: #1860CA;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: solid #1860CA;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
	content: '';
    transform: rotate(140deg);
    -webkit-transform: rotate(140deg);
}
.aval-slider .slick-next:before {
    font-family: 'slick';
    font-size: 10px;
    line-height: 1;
    opacity: .75;
    color: #1860CA;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: solid #1860CA;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
	content: '';
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.home-search-section {
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
}
.home-search-section .wrapper-content {
    padding: 90px 0 0;
}
.doctor-search-form select {
    background: #FFFFFF;
    border-radius: 130px;
    min-height: 60px;
	border: none !important;
}
.doctor-search-form select, .doctor-search-form input {
	border: 1px solid rgba(16, 222, 253, 0.1);
	box-shadow: 0px 4px 4px rgba(237, 237, 237, 0.25);
	border-radius: 5px;
}
.home-search-section .banner-header h2 {
	color: #28DAF4;
	font-weight: 500;
	margin-bottom: 30px;
	font-size: 38px;
}
.home-search-section .banner-header h2 .highlighter {
	font-weight: 600;
}
.home-search-section .doctor-form .banner-btn {
	background: #E74E84;
	border: 1px solid #E74E84;
	box-shadow: 10px 4px 14px rgba(247, 247, 247, 0.25);
	border-radius: 5px;
}
.facility-section {
	background-color: #F3F8FF;
	padding: 60px 0;
}
.facility-section .visit-doctor {
	background-color: #fff;
    padding: 20px;
	margin-bottom: 30px;
}
.facility-section .visit-doctor .inner-details {
	position: relative;
    display: flex;
    align-items: center;
}
.facility-section .visit-doctor .inner-details img {
	position: absolute;
    bottom: -20px;
    right: 0;
    width: 50px;
}
.visit-doctor .inner-details .count h1 {
	font-weight: 700;
	font-size: 24px;
	color: #1860CA;
	width: 65px;
	height: 65px;
	border-radius: 50%;
	border: 5px solid #F7FAFF;
	justify-content: center;
	align-items: center;
	display: flex;
	margin-right: 20px;
}
.visit-doctor .inner-details .info h1 {
	font-weight: 700;
	font-size: 24px;
	color: #1860CA;
}
.visit-doctor .inner-details .info p {
	color: #9D9D9D;
	font-size: 14px;
	max-width: 280px;
}
.visit-doctor .inner-details .info a {
	font-size: 14px;
	font-weight: 600;
	color: #1860CA;
}
.visit-doctor .inner-details .info a i {
	margin: 5px 6px;
    position: absolute;
}
.visit-doctor .inner-details.pharmacy .count h1, .visit-doctor .inner-details.pharmacy .info h1, .visit-doctor .inner-details.pharmacy .info a, .visit-doctor .inner-details.pharmacy .info a i {
	color: #0CE0FF;
}
.visit-doctor .inner-details.lab .count h1, .visit-doctor .inner-details.lab .info h1, .visit-doctor .inner-details.lab .info a, .visit-doctor .inner-details.lab .info a i {
	color: #00BCD1;
}
.clinic-speciality {
	background-color: #fff;
	padding: 60px 0;
}
.heading-wrapper {
	margin-bottom: 40px;
	align-items: center;
}
.heading-wrapper h1 {
	max-width: 250px;
	font-weight: 700;
	font-size: 38px;
	color: #6C6C6C;
}
.heading-wrapper .nav-arrow {
	width: 40px;
	height: 40px;
	background-color: #F3F8FF;
	border: 3px solid #1860CA;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: #2264C5;
}
.clinic-wrap {
	position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 10px;
	height: 250px;
}
.clinic-wrap:before {
	content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgb(222 235 255 / 28%);
    border-radius: 5px;
}
.clinic-wrap .wrapper-overlay {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 20px;
}
.clinic-wrap .wrapper-overlay img {
	width: 70px;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border: 5px solid #F7FAFF;
	border-radius: 50%;
	margin-bottom: 30px;
}
.clinic-wrap .wrapper-overlay h3 {
	font-size: 22px;
	color: #E74E84;
	font-weight: 700;
	margin-bottom: 30px;
}
.clinic-wrap .wrapper-overlay .row.row-sm {
	position: absolute;
    width: 100%;
    bottom: 0;
}
.clinic-wrap .wrapper-overlay p {
	font-weight: 700;
	color: #E74E84;
	font-size: 14px;
}
.clinic-wrap .wrapper-overlay p.blue {
	color: rgba(24, 96, 202, 0.9);
}
.clinic-wrap .wrapper-overlay p span {
	font-weight: 500;
	color: #000;
}
.browse-section.brower-category {
	padding: 60px 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}
.browse-section.brower-category .heading-wrapper h1 {
	color: #E5F6FD;
}
.browse-section.brower-category .brower-box > div {
	background-color: #F2F7FF;
	border-radius: 20px 20px 0px 0px;
}
.browse-section.brower-category .brower-img {
	background-color: #28DAF4;
	margin-bottom: 40px;
}
.browse-section.brower-category .brower-box h4{
	color: #28DAF4;
}
.doctor-divison.provider-select .doc-background {
	background-size: cover;
	padding: 60px 30px;
}
.doctor-divison.provider-select .doc-background::before {
    background:  rgba(242, 247, 255, 0.94);
}
.doctor-divison.provider-select .pat-background {
	background-size: cover;
	padding: 60px 30px;
}
.doctor-divison.provider-select .pat-background::before {
    background:  rgba(40, 218, 244, 0.9);
}
.doctor-divison.provider-select .doc-background h3 {
	color: #2357A6;
}
.doctor-divison.provider-select .doc-background p {
	color: #4B4B4B;
}
.doctor-divison.provider-select .pat-background a {
	background-color: #0CE0FF;
}
.doc-booking {
	padding: 60px 0;
	background-color: #f3f8ff;
}
.doc-booking .book-slider .slick-prev, .doc-booking .book-slider .slick-next {
	border: 3px solid #1860CA;
    background-color: #F3F8FF;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	transform: none;
	top: -110px;
	box-shadow: none;
}
.doc-booking .book-slider .slick-prev:before {
	transform: rotate(140deg);
 	-webkit-transform: rotate(140deg)
}
.doc-booking .book-slider .slick-next:before {
	transform: rotate(320deg);
 	-webkit-transform: rotate(320deg)
}
.doc-booking .book-slider .slick-prev:before, .doc-booking .book-slider .slick-next:before{
	font-family: 'slick';
    font-size: 10px;
    line-height: 1;
    opacity: .75;
    color: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: solid #1860ca;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}
.doc-booking .book-slider .slick-list {
    padding-top: 0;
}
.doc-booking .book-slider .pro-content .amt {
	opacity: 1;
    position: absolute;
    left: -7px;
    padding: 6px 12px;
    border-radius: 0px 100px 100px 0px;
    background-color: #28DAF4;
}
.doc-booking .book-slider .profile-widget .content-info,.doc-booking .book-slider .provider-info .rating {
	display: block;
	text-align: left;
}
.doc-booking .book-slider .profile-widget a img, .doc-booking .book-slider .pro-content {
    border-radius: 10px;
}
.doc-booking .book-slider .profile-widget .provider-info {
	bottom: 0;
    background-color: #1376CA;
    border-radius: 0 0 10px 10px;
}
.doc-booking .book-slider .profile-widget .provider-info h3 a {
	color: #28DAF4;
}
.doc-booking .book-slider .profile-widget {
	margin-bottom: 0;
}
.doc-booking .book-slider .provider-info h5 {
	color: #fff;
	float: unset;
}
.doc-booking .book-slider .profile-widget a.book-btn {
	border-radius: 30px;
    background-color: transparent;
    width: 100px;
}
/*-----------------
	16. Search
-----------------------*/

.breadcrumb-bar {
    background: #F9FAFD;
	box-shadow: 0px 1px 0px #C5E5FF;
    padding: 15px 0;
}
.breadcrumb-bar .breadcrumb-title {
    color: #1ec8c8;
    font-size: 25px;
    font-weight: 700;
    margin: 0 0 8px;
}
.page-breadcrumb ol {
    background-color: transparent;
    font-size: 14px;
	font-weight: 500;
    margin-bottom: 0;
    padding: 0;
}
.page-breadcrumb ol li a {
    color: #000;
}
.page-breadcrumb ol li.active {
    color: #000;
}
.page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
	color: #000;
	font-size: 14px;
}
.sort-by {
    float: right;
}
.sort-title {
    color: #fff;
    font-size: 14px;
    margin-right: 10px;
}
.custom-short-by .sort-title{
	color: #2e3842;
}
.custom-short-by > div{
	margin-right: 20px;
}
.custom-short-by > div:first-child{
	margin-right: 0;
}
.sortby-fliter {
	display: inline-block;
	width: 120px;
}
.cal-icon {
	position: relative;
	width: 100%;
}
.cal-icon:after {
	color: #979797;
    content: '\f073';
	display: block;
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 13px;
}
.custom_check {
	color: #666;
	display: inline-block;
	position: relative;
	font-size: 14px;
	font-size: .9375rem;
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.custom_check input {
	position: absolute;
	opacity: 0;
	cursor: pointer
}
.custom_check input:checked ~ .checkmark {
	background-color: #1ec8c8;
	border: 1px solid #1ec8c8;
}
.custom_check .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: 1px solid #dcdcdc;
	background-color: #fff;
	border-radius: 3px;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}
.custom_check .checkmark::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    display: none;
    left: 4px;
    top: 0;
    color: #FFF;
    font-size: 11px;
}
.custom_check input:checked ~ .checkmark:after {
	display: block
}
.custom_radio {
	color: #555;
	display: inline-block;
	position: relative;
	font-size: 14px;
	font-size: 0.9375rem;
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.radio_input .custom_radio + .custom_radio {
	margin-left: 15px;
}
.custom_radio input {
	position: absolute;
	opacity: 0
}
.custom_radio input:checked ~ .checkmark:after {
	opacity: 1
}
.custom_radio .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%
}
.custom_radio .checkmark:after {
	display: block;
	content: "";
	position: absolute;
	opacity: 0;
	top: 3px;
	left: 3px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #ff9b44;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}

.filter-widget {
	margin-bottom: 20px;
}
.filter-widget h4 {
	font-weight: 600;
	margin-bottom: 15px;
}
.filter-widget .custom_check {
	line-height: 18px;
}
.btn-search .btn {
    background-color: #1ec8c8;
    border: 1px solid #1ec8c8;
	border-radius: 8px;
    color: #fff;
    height: 60px;
	font-weight:500;
	font-size:16px;
}
.doctor-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.doc-info-left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.doc-info-right {
    margin-left: auto;
    -ms-flex: 0 0 205px;
    flex: 0 0 205px;
    max-width: 205px;
}
.doctor-img {
    -ms-flex: 0 0 150px;
    flex: 0 0 150px;
    margin-right: 20px;
    width: 150px;
}
.doctor-img img {
	border-radius: 5px;
}
.doc-department {
    color: #808191;
    margin-bottom: 5px;
}
.doc-department img {
    width: 19px;
    display: inline-block;
    margin-right: 10px;
}
.doc-location {
    color: #808191;
    margin-bottom: 35px;
}
.pharmacy-location,
.pharmacy-title{
	color: #2E3842;
}
.doc-location a {
    color: #1ec8c8;
    font-weight: 500;
}
.doctor-widget .doc-name i.verified {
	color: #28A745;
}
.doctor-widget .doc-name {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 3px;
}
.doc-speciality {
    color: #808191;
    margin-bottom: 5px;
}
.doctor-widget .rating i {
	font-size: 14px;
}
.doctor-widget .average-rating {
	font-weight: 500;
	color: #000;
}
.clinic-details {
	margin-bottom: 15px;
}
.clinic-details h5 {
    font-weight: normal;
    color: #757575;
    margin-bottom: 25px;
}
.clinic-details ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.clinic-details ul li {
	display: inline-block;
	padding-right: 5px;
}
.clinic-details ul li:last-child {
	padding-right: 0;
}
.clinic-details ul li a {
	display: inline-block;
}
.clinic-details ul li a img {
	border-radius: 5px;
	width: 40px;
	height: 40px;
    object-fit: cover;
}
.clinic-services {
    color: #272b41;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    font-size: 13px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.clinic-services span {
    border: 1px solid #E5F1FB;
    border-radius: 3px;
    display: inline-block;
    font-size: 12px;
    padding: 5px 18px;
}
.clinic-services span + span {
    margin-left: 5px;
}
.clini-infos {
    margin-bottom: 15px;
}
.clini-infos h2 b.strike{
	text-decoration: line-through;
	font-weight: 500;
}
.clini-infos ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.clini-infos ul li {
    display: block;
    line-height: 30px;
    color: #808191;
}
.clini-infos ul li i {
    font-size: 15px;
    min-width: 20px;
    margin-right: 5px;
}
.clini-infos ul li i.feather-info {
	color: #1ec8c8;
}
.clinic-booking a + a {
	margin-top: 15px;
}
.clinic-booking a {
    background-color: #fff;
    border: 1px solid #E5F1FB;
	border-radius: 8px;
    color: #1ec8c8;
    display: block;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 19px 29px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}
.clinic-booking a.view-pro-btn:hover, .clinic-booking a.view-pro-btn:focus {
	background: #1ec8c8;
	color: #fff;
}
.clinic-booking a.apt-btn {
    background-color: #1ec8c8;
    color: #fff !important;
}
.clinic-booking a.apt-btn:hover, .clinic-booking a.apt-btn:focus {
	background-color: #0469c8;
	border-color: #0469c8;
	color: #fff;
}
.load-more  a {
	background: #1ec8c8;
    border-radius: 8px;
    font-weight: 600;
    padding: 15px 30px;
    font-size: 18px;
}
.load-more {
	margin-bottom: 30px;
}
.doctor-img1{
	margin-right: 20px;
}
.doctor-img1 img{
	border-radius: 4px;
}

/*-----------------
	17. Doctor Profile
-----------------------*/

.clinic-direction {
	color: #757575;
	font-size: 14px;
	margin-bottom: 25px;
}
.clinic-direction a {
    color: #1ec8c8;
    font-weight: 500;
}
.doctor-action {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.doctor-action a + a {
	margin-left: 8px;
}
.doctor-action .fav-btn:hover {
	background-color: #fb1612;
	border-color: #fb1612;
	color: #fff;
}
.msg-btn:hover, .msg-btn:focus {
	background-color: #1ec8c8;
	border-color: #1ec8c8;
	color: #fff;
}
.call-btn:hover, .call-btn:focus {
	background-color: #1ec8c8;
	border-color: #1ec8c8;
	color: #fff;
}
.dct-appoinment .user-tabs .nav-tabs > li > a {
	width: 200px;
}
.user-tabs .nav-tabs > li > a {
	border: 1px solid #9e9e9e;
	border-radius: 0px;
	color: #455A64;
	font-weight: 600;
	padding: 10px 20px;
	text-align: center;
	margin-right: 0;
	font-weight: 500;
	font-size: 18px;
	margin-bottom: 5px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	-ms-border-radius: 0px;
	-o-border-radius: 0px;
}
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active,
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover,
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
	background: #1ec8c8;
	border-color: #1ec8c8;
	color: #FFFFFF !important;
}
.user-tabs .med-records {
	display: inline-block;
	min-width: 130px;
}
.user-tabs .nav-tabs > li > a:hover {
	background-color: #1ec8c8;
	border: 1px solid #1ec8c8;
	color: #FFF;
}
.widget {
	margin-bottom: 30px;
}
.widget-title {
	margin-bottom: 15px;
	font-weight: 600;
}
.about-widget p {
	font-size: 15px;
	line-height: 22px;
	text-align: justify;
	color: #455A64;
}
.experience-box {
	position: relative;
}
.experience-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.experience-list::before {
	background: #808191;
	bottom: 0;
	content: "";
	left: 9px;
	position: absolute;
	top: 8px;
	width: 2px;
}
.experience-list > li {
	position: relative;
}
.experience-list > li:last-child .experience-content {
	margin-bottom: 0;
}
.experience-user .avatar {
	height: 32px;
	line-height: 32px;
	margin: 0;
	width: 32px;
}
.experience-list > li .experience-user {
	background: #fff;
	height: 10px;
	left: 4px;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 4px;
	width: 10px;
}
.experience-list > li .experience-content {
	background-color: #fff;
	margin: 0 0 20px 40px;
	padding: 0;
	position: relative;
}
.experience-list > li .experience-content .timeline-content a.name {
	font-weight: 500;
}
.experience-list > li .time {
	color: #757575;
	display: block;
	font-size: 13px;
}
.before-circle {
    background-color: #1ec8c8;
    border-radius: 50%;
    height: 12px;
    width: 12px;
	font-size: 12px;
    border: 2px solid #1ec8c8;
}
.exp-year {
    color: #1ec8c8;
    margin-bottom: 8px !important;
}
.exp-title {
    font-size: 15px;
	color: #455A64;
}
.awards-widget .experience-list > li:last-child p {
	margin-bottom: 0;
}
.service-list {
    margin-bottom: 30px;
}
.service-list:last-child {
	border-bottom: 0;
	margin-bottom: 0;
	padding-bottom: 0;
}
.service-list ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.service-list ul li {
	float: left;
	color: #263238;
	margin: 6px 0;
	padding-left: 25px;
	position: relative;
	width: 33%;
}
.service-list ul li::before {
    color: #263238;
    content: '\f061';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    left: 0;
    position: absolute;
}
.location-list {
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 20px;
}
.location-list + .location-list {
	margin-top: 20px;
}
.clinic-content .clinic-name {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 3px;
}
.clinic-content .clinic-direction a {
	display: inline-block;
	margin-top: 8px;
}
.timings-days {
    font-weight: bold;
    color: #272b41;
    margin-bottom: 5px;
}
.timings-times span {
	display: block;
	color: #808191;
    font-size: 14px;
}
.location-list .consult-price {
    font-size: 20px;
    font-weight: 500;
    color: #272b41;
}
.review-listing {
    border-bottom: 1px solid #f5f7fc;
    margin-top: 20px;
    padding-bottom: 30px;
}
.review-listing > ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.widget.review-listing > ul > li {
	border: 1px solid #EBEBEB;
    border-radius: 8px;
}
.review-listing > ul li + li {
	margin-top: 20px;
	border-top: 1px dashed #f0f0f0;
}
.review-listing > ul li .comment .avatar {
	border: 1px solid #A6A6A6;
	width: 80px;
    height: 80px;
}
.review-listing > ul li .comment {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    padding: 20px;
}
.review-listing > ul li .comment:last-child {
	margin-bottom: 0;
}
.review-listing > ul li .comment .comment-body {
    margin-left: 16px;
	width: 100%;
}
.review-listing > ul li .comment .comment-body .meta-data {
    position: relative;
    margin-bottom: 10px;
}
.review-listing > ul li .comment .comment-body .meta-data span {
    display: block;
    font-size: 16px;
    color: #757575;
}
.review-listing > ul li .comment .comment-body .meta-data span.comment-author {
    font-weight: 600;
    color: #1ec8c8;
    text-transform: capitalize;
}
.review-listing > ul li .comment .comment-body .meta-data span.comment-date {
    font-size: 12px;
}
.review-listing > ul li .comment .comment-body .meta-data .review-count {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    position: absolute;
    top: 3px;
    right: 0;
    width: auto;
}
.review-listing > ul li .comment .comment-body .comment-content {
    color: #000;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 14px;
}
.review-listing > ul li .comment .comment-body .comment-reply .comment-btn {
    color: #1ec8c8;
    display: inline-block;
    font-size: 14px;
}
.review-listing .recommend-btn {
    float: right;
    color: #263238;
    font-size: 14px;
    padding: 5px 0;
	margin-bottom: 0;
}
.review-listing .recommend-btn a {
    border: 1px solid rgba(128,137,150,0.4);
    border-radius: 4px;
	display: inline-block;
    padding: 4px 12px;
    color: #263238;
    margin-left: 3px;
    margin-right: 3px;
    transition: all .3s;
}
.review-listing .recommend-btn a.like-btn:hover {
	background-color: #28a745;
	border: 1px solid #28a745;
	color: #fff;
}
.review-listing .recommend-btn a.like-btn:hover i,
.review-listing .recommend-btn a.dislike-btn:hover i {
	color: #FFF;
}
.review-listing .recommend-btn a.dislike-btn:hover {
	background-color: #dc3545;
	border: 1px solid #dc3545;
	color: #fff;
}
.review-listing .recommend-btn a.like-btn i {
	color: #28A745;
}
.review-listing .recommend-btn a.like-btn i {
	color: #28A745;
}
.review-listing .recommend-btn a.dislike-btn i {
    color: #EB5757;
}
.review-listing > ul li .comments-reply {
    list-style: none;
    padding-left: 65px;
	border-top: 1px solid #EBEBEB;
}
.recommended {
    color: #28a745;
    font-size: 14px;
    margin: 0;
}
.all-feedback {
    margin-top: 20px;
}
.star-rating {
    direction: rtl;
}
.star-rating input[type=radio] {
    display: none
}
.star-rating label {
    color: #bbb;
    cursor: pointer;
    font-size: 18px;
    padding: 0;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}
.star-rating label:hover,
.star-rating label:hover ~ label,
.star-rating input[type=radio]:checked ~ label {
    color: #f2b600
}
.terms-accept a {
	color: #1ec8c8;
	font-weight: 500;
}
.business-widget {
    background-color: #FFFFFF;
    border: 1px solid #EBEBEB;
	border-radius: 8px;
    margin-bottom: 0;
}
.listing-day {
	-webkit-box-align: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
    color: #000;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    margin-bottom: 10px;
}
.listing-day:last-child {
	margin-bottom: 0;
}
.listing-day.current {
    padding-bottom: 13px;
    margin-bottom: 13px;
}
.listing-day .day {
	font-weight: 500;
}
.listing-day.current .day {
	font-weight: bold;
}
.listing-day.current .day span {
	display: block;
	font-weight: normal;
}
.time-items {
    color: #757575;
}
.time-items > span {
    display: block;
    text-align: right;
}
.time-items > span.open-status {
	margin-bottom: 3px;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
	display: none;
}

/*-----------------
	18. Booking
-----------------------*/

.booking-doc-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.booking-doc-info .booking-doc-img {
    width: 90px;
    margin-right: 15px;
}
.booking-doc-info .booking-doc-img img {
    border-radius: 4px;
    height: 90px;
    width: 90px;
    object-fit: cover;
}
.schedule-widget {
    border-radius: 4px;
    min-height: 100px;
}
.schedule-header h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 3px 0 0;
}
.schedule-header {
    border-bottom: 1px solid #f0f0f0;
    border-radius: 8px 8px 0 0;
    padding: 10px 20px;
}
.day-slot ul {
    float: left;
    list-style: none;
    margin-bottom: 35px;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    position: relative;
    width: 100%;
}
.day-slot li {
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    width: 14.28%;
}
.day-slot li span {
    display: block;
    font-size: 18px;
	font-weight: 600;
    text-transform: uppercase;
}
.day-slot li span.slot-date {
    display: block;
    color: #455A64;
    font-size: 18px;
	font-weight: 600;
}
.day-slot li small.slot-year {
    color: #455A64;
    font-size: 18px;
	font-weight: 600;
}
.day-slot li.left-arrow {
    left: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px !important;
    transform: translateY(-50%);
}
.day-slot li.right-arrow {
    right: -11px;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px !important;
    transform: translateY(-50%);
}
.schedule-cont {
    padding: 20px;
}
.date-booking .bookingrange {
	height: 60px;
    line-height: 48px;
    border-radius: 8px;
}
.date-booking h4 {
	font-weight: 600;
	font-size: 22px;
	line-height: 27px;
	color: #1ec8c8;
}
.date-booking p {
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #000;
}
.time-slot ul {
    list-style: none;
    margin-right: -5px;
    margin-left: -5px;
	margin-bottom: 0;
    padding: 0;
}
.time-slot li {
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    width: 14.28%;
	min-height: 1px;
}
.time-slot li .timing {
    background-color: #FFFFFF;
    border: 1px solid #455A64;
    border-radius: 8px;
    color: #455A64;
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    padding: 14px 5px;
    text-align: center;
	position: relative;
}
.time-slot li .timing:hover {
    background-color: #1ec8c8;
	border: 1px solid #1ec8c8;
	color: #fff;
}
.time-slot li .timing:last-child {
	margin-bottom: 0;
}
.time-slot li .timing.selected {
	background-color: #1ec8c8;
	border: 1px solid #1ec8c8;
	color: #fff;
}
.time-slot li .timing.selected::before {
    color: #fff;
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
	font-size: 12px;
    font-weight: 900;
    position: absolute;
    right: 16px;
    top: 16px;
}
.day-slot .days {
	background: #E5F1FB;
	border: 1px solid #E5F1FB;
	border-radius: 8px;
	padding: 10px 0;
	color: #455A64;
}
.schedule-list {
    border-bottom: 1px solid #cfcfcf;
    margin-bottom: 50px;
    padding-bottom: 50px;
}
.schedule-list:last-child {
    border-bottom: 0;
    margin-bottom: 0;
	padding-bottom: 0;
}
.submit-section.proceed-btn {
	margin: 0 0 30px;
}

/*-----------------
	19. Checkout
-----------------------*/

.info-widget .card-header h4,
.payment-widget  h4 {
	font-weight: 600;
	font-size: 22px;
	color: #1ec8c8;
}
.booking-card .card-header h4 {
	font-size: 20px;
	font-weight: bold;
	color: #000;
}
.payment-widget  h4 {
	margin-bottom: 30px;
}
.booking-info h4 {
	line-height: 22px;
	color: #263238;
	margin-bottom: 15px;
}
.booking-info .rating {
	margin: 0 0 15px;
}
.inf-widget {
    border-top: 1px solid #EBEBEB;
}
.card-label > label {
    background-color: #fff;
    color: #959595;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    margin: 6px auto auto 8px;
    padding: 0 7px;
}
.card-label > input {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .05);
    display: block;
    height: 50px;
    margin-top: -13px;
    padding: 5px 15px 0;
    transition: border-color .3s;
    width: 100%;
}
.exist-customer a {
	color: #1ec8c8;
	font-weight: 500;
}
.info-widget .submit-section .submit-btn {
    padding: 15px 38px;
}
.booking-card, .book-info {
	margin-bottom: 30px;
}
.payment-widget .payment-list + .payment-list {
	margin-bottom: 30px;
}
.terms-accept input[type=checkbox] {
	margin-right: 15px;
}
.exist-customer {
	font-size: 14px;
    font-weight: 500;
    color: #808191;
}
.custom-file {
	height: auto;
}
.custom-file-upload {
	background-color: #fff;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    display: inline-flex;
    transition: border-color .3s;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
    margin-top: 0;
    height: calc(2.3em + 1.21875rem);
    color: #fff;
    position: relative;
}
.custom-file-upload input {
	display: none;
}
.change-user {
    padding: 15px 15px;
    background: #1ec8c8;
	border-radius: 0px 8px 8px 0px;
    position: absolute;
    right: 0;
}
#pricing_select .payment-radio {
	display: inline-flex;
}
.payment-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 600;
    color: #272b41;
    text-transform: capitalize;
}
.payment-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.payment-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 19px;
    margin: 3px 0 0 0;
    border: 2px solid #ddd;
    border-top-color: rgb(221, 221, 221);
    border-right-color: rgb(221, 221, 221);
    border-bottom-color: rgb(221, 221, 221);
    border-left-color: rgb(221, 221, 221);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.payment-radio input:checked ~ .checkmark {
    border-color: #1ec8c8;
}
.payment-radio .checkmark::after {
    position: absolute;
    left: 3px;
    top: 3px;
    content: '';
    width: 9px;
    height: 9px;
    background-color: #1ec8c8;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -o-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.payment-radio input:checked ~ .checkmark::after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}
.booking-date {
    padding: 0;
    list-style: none;
}
.booking-date li {
    position: relative;
    font-size: 18px;
	font-weight: 500;
    color: #455A64;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.booking-date li span {
    float: right;
    color: #455A64;
    font-weight: 500;
    font-size: 18px;
    text-align: right;
}
.booking-date li span a{
	color: #1ec8c8;
	font-size: 14px;
	width: 100%;
	display: inline-block;
}
.booking-fee {
    padding: 0;
    list-style: none;
}
.booking-fee li {
    position: relative;
    font-size: 18px;
    color: #455A64;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.booking-fee li span {
    float: right;
    color: #263238;
    font-weight: 500;
    font-size: 18px;
}
.booking-total ul {
	padding: 0;
	list-style: none;
	margin: 0;
}
.booking-total ul li span {
    font-size: 18px;
    font-weight: bold;
    color: #263238;
}
.booking-total ul li .total-cost {
    color: #1ec8c8;
    font-size: 18px;
	font-weight: bold;
    float: right;
}
.booking-card .card-footer {
	border-radius: 0 0 8px 8px;
}
.total-table .table td {
	padding: 1rem 1.5rem;
}
.booking-summary {
	border-top: 1px solid #EBEBEB;
}
.text-black {
	color: #000 !important;
}
.bill-info .card-title {
	font-weight: 500;
	font-size: 20px;
	margin-bottom: 25px;
}

/*-----------------
	20. Booking Success
-----------------------*/

.success-page-cont {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display:flex;
}
.success-card .card-body {
	padding: 50px 20px;
}
.success-cont {
    text-align: center;
	margin-bottom: 30px;
}
.success-cont img {
	margin-bottom: 30px;
}
.success-cont i {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    color: #fff;
    width: 60px;
    height: 60px;
    border: 2px solid #1ec8c8;
    border-radius: 50%;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    font-size: 30px;
    margin-bottom: 30px;
    background-color: #1ec8c8;
}
.success-cont h3 {
    font-size: 30px;
	font-weight: bold;
	color: #41B883;
	margin-bottom: 20px;
}
.success-cont p {
	font-size:25px;
	color: #000;
    margin-bottom: 20px;
}
.success-cont strong {
    font-weight: 600;
}
.view-inv-btn {
	font-size: 18px;
	font-weight: 600;
	padding: 15px 29px;
	border-radius: 8px;
}

/*-----------------
	21. Invoice View
-----------------------*/

.invoice-content {
    background-color: #fff;
	border: 1px solid #EBEBEB;
    border-radius: 8px;
    margin-bottom: 30px;
	padding: 30px;
}
.invoice-item .invoice-logo {
    margin-bottom: 30px;
}
.invoice-item .invoice-logo img {
    width: auto;
    max-height: 52px;
}
.invoice-item .invoice-text h2 {
	color:#000;
	font-size:36px;
	font-weight:600;
}
.invoice-item .invoice-details {
	text-align:right;
	color: #000;
	font-size: 20px;
	line-height: 33px;
	margin-bottom: 30px;
}
.invoice-item .invoice-details strong {
	color: #000;
}
.invoice-item .invoice-details-two {
	text-align:left
}
.invoice-item .invoice-text {
	padding-top:42px;
	padding-bottom:36px
}
.invoice-item .invoice-text h2 {
	font-weight:400
}
.invoice-info {
	margin-bottom: 30px;
}
.invoice-info p {
	margin-bottom: 0;
}
.invoice-info.invoice-info2 {
    text-align: right;
}
.invoice-item .customer-text {
	font-size: 20px;
	color: #000;
	line-height: 24px;
	font-weight: bold;
	margin-bottom: 18px;
	display: block
}
.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
	color: #000;
	font-weight: 600;
	padding: 10px 20px;
	line-height: inherit
}
.invoice-table tbody  tr:last-child  td:first-child{
	border-bottom-left-radius: 8px;
}
.invoice-table tr th {
	font-weight: bold;
	font-size: 20px;
}
.invoice-table tr td,
.invoice-table-two tr td {
	color: #000;
	font-weight: 400;
	font-size: 20px;
}
.invoice-table-two {
	margin-bottom:0
}
.invoice-table-two tr th {
	font-weight: bold;
	font-size: 22px;
}
.invoice-table-two tr th,
.invoice-table-two tr td {
	border-top: 0;
}
.invoice-table-two tbody tr {
	border-bottom: 0;
}
.invoice-table-two tr td {
	text-align: right;
}
.invoice-info h5 {
    font-size: 16px;
    font-weight: 500;
}
.other-info {
    margin-top: 10px;
}
.other-info h4 {
	font-weight: bold;
	font-size: 22px;
	line-height: 27px;
	margin-bottom: 17px;
}
.other-info p {
	font-size: 18px;
	color: #000;
}

/*-----------------
	22. Schedule Timings
-----------------------*/

.duration-slot {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	margin-bottom: 0;
}
.duration-slot label {
	font-weight: 600;
	color: #000;
	white-space: nowrap;
	margin-right: 40px;
}
.duration-slot .select2-container--default .select2-selection--single .select2-selection__rendered {
	font-weight: 600;
	line-height: 60px;
}
.duration-slot .select2-container .select2-selection--single,
.duration-slot  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 60px;
}
.duration-slot  .select2-container--default .select2-selection--single .select2-selection__arrow b {
	top: 45%;
}
.tab-content.schedule-cont .card-title {
	margin-bottom: 20px;
	font-weight: 600;
}
.doc-times {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.doc-slot-list {
	background-color: #1ec8c8;
	border: 1px solid #1ec8c8;
	border-radius: 8px;
	color: #fff;
	font-size: 15px;
    font-weight: 500;
    margin: 10px 10px 0 0;
    padding: 18px 24px;
}
.doc-slot-list a {
	color: #FFFFFF;
	display: inline-block;
	margin-left: 5px;
}
.doc-slot-list a:hover {
	color: #fff;
}
.schedule-nav .nav-tabs {
	border: 0 !important;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.schedule-nav .nav-tabs li {
	margin: 5px 15px 5px 0;
	display: inline-block;
}
.schedule-nav .nav-tabs li:last-child {
	margin-right: 0;
}
.schedule-nav .nav-tabs > li > a {
	border: 1px solid #455A64;
	border-radius: 8px;
	padding: 12px 15px;
	text-transform: uppercase;
	color: #455A64;
}
.schedule-nav .nav-tabs > li > a:hover {
	border: 1px solid #1ec8c8;
	background: #1ec8c8;
	color: #FFF;
}
.schedule-nav .nav-tabs li a.active {
	background: #1ec8c8;
    border: 1px solid #1ec8c8 !important;
	color: #fff;
}
.hours-info .form-control {
	min-height: auto;
	height: auto;
}
.hours-info .btn.btn-danger.trash {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    height: 38px;
    width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	color: #fff;
}
.edit-link {
    color: #1ec8c8;
    font-size: 16px;
    margin-top: 4px;
	background: rgba(197, 229, 255, 0.25);
    border-radius: 3px;
    width: 40px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.visit-btns {
    color: #272b41;
    background-color: #fff;
    width: 80%;
    margin-bottom: 10px;
    display: block;

    /* padding: 8px 14px; */
    outline: unset;
    cursor: pointer;
}
.visits input:checked ~ .visit-rsn {
    background-color: #1ec8c8;
    border-color: #1ec8c8;
    color: #fff;
    border-radius: 8px;
}
.visits input:checked ~ .visit-rsn:before {
	opacity: 1;
}
.visits input:disabled ~ .visit-rsn {
    background-color: #e30303;
    color: #fff;
    border-radius: 5px;
}
.visits .form-check-input {
	opacity: 0;
}
.visits input.form-check-input {
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	visibility: hidden;
	margin-left: 0;
}
.visits span.visit-rsn:before {
	color: #FFFFFF;
	content: "\f00c";
	font-family: "Font Awesome 5 Free";
	font-size: 10px;
	font-weight: 900;
	position: absolute;
	right: 10px;
	top: 50%;
	border-radius: 50px;
	width: 18px;
	height: 18px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	opacity: 0;
}
.visits span.visit-rsn {
    background-color: #F3F9FF;
    border: 0;
    border-radius: 4px;
    color: #64627C;
    display: block;
    font-size: 14px;
    margin-bottom: 0px;
    padding: 13px 5px;
    text-align: center;
    position: relative;
    font-weight: 600;
}
.visits span.visit-rsn {
    text-align: center;
    border: 0;
    border-radius: 5px;
    padding: 10px 5px;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #455A64;
    background: #FFFFFF;
    min-width: 130px;
    display: inline-block;
    width: 100%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.session-time {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.session-time h3 {
	font-weight: 600;
	font-size: 22px;
	color: #1ec8c8;
}
.session-time h4 {
	font-weight: 600;
	margin-right: 35px;
}
.session-time .btn {
	border: 1px solid #A6A6A6;
	border-radius: 5px;
	color: #000;
	font-weight: 600;
	font-size: 16px;
	min-width: 160px;
	padding: 10px 15px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}
.save-btn {
	border-radius: 8px;
    padding: 10px 15px;
    min-width: 135px;
    font-weight: 600;
    font-size: 16px;
}

/*-----------------
	23. Doctor Dashboard
-----------------------*/

.dash-widget {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	border-radius: 8px;
	padding: 20px;
	margin-bottom: 40px;
}
.dash-widget-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}
.circle-bar {
    margin-right: 15px;
}
.dct-border-rht {
	border-right: 1px solid #f0f0f0;
}
.dash-widget h6 {
    font-size: 20px;
    font-weight: bold;
	line-height: 24px;
	margin-bottom: 0;
}
.dash-widget h3 {
    font-size: 18px;
	font-weight: 500;
	line-height: 22px;
    margin-bottom: 10px;
}
.dash-widget p {
	color: #455A64;
	font-weight: 500;
    font-size: 14px;
	margin-bottom: 0;
}
.circle-bar > div {
	display: inline-block;
	position: relative;
	text-align: center;
}
.circle-bar > div img {
    left: 0;
    position: absolute;
    top: 50%;
    right: 0;
    text-align: center;
    margin: 0 auto;
    transform: translateY(-50%);
}
.circle-bar > div canvas {
	width: 90px !important;
	height: 90px !important;
}
.dash-card .row {
	margin-left: -10px;
	margin-right: -10px;
}
.dash-card .row > div {
	padding-left: 10px;
	padding-right: 10px;
}
.sub-heading {
	font-size: 20px;
    font-weight: bold;
	line-height: 24px;
    color: #1ec8c8;
    margin-bottom: 40px;
}
.appointment-tab {
	margin-bottom: 30px;
}
.appointment-tab  .nav-tabs.nav-tabs-solid > li > a {
	background-color: #FFFFFF;
	border: 1px solid #455A64;
	padding: 15px 30px;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    min-width: 220px;
	margin-bottom: 4px;
}
.appointment-tab  .nav-tabs.nav-tabs-solid > li > a.active,
.appointment-tab  .nav-tabs.nav-tabs-solid > li > a.active:hover,
.appointment-tab  .nav-tabs.nav-tabs-solid > li > a.active:focus,
.appointment-tab  .nav-tabs.nav-tabs-solid > li > a:hover,
.appointment-tab  .nav-tabs.nav-tabs-solid > li > a:focus {
    background-color: #1ec8c8;
    border-color: #1ec8c8;
    color: #fff;
}
.appointment-tab .nav-tabs {
    background-color: #fff;
    padding: 1.5rem;
    border: 1px solid #f0f0f0;
    border-radius: 8px 8px 0 0 !important;
	border-bottom: 0;
}
.appointment-tab .tab-content {
	padding-top: 0;
}
.appointment-tab .card {
	border-radius: 0  0 8px 8px;
}
.submit-btn-bottom {
	margin-bottom: 30px;
}

/*-----------------
	24. Patient Profile
-----------------------*/

.add-new-btn {
    background-color: #1ec8c8;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 10px 20px;
}
.add-new-btn:focus, .add-new-btn:hover,.add-new-btn:active {
	background-color: #1ec8c8;
    color: #fff;
}
.patient-info {
	margin-top: 15px;
}
.patient-info ul {
    padding: 0;
    list-style: none;
	font-size: .875rem;
	margin: 0;
}
.patient-info ul li {
    position: relative;
    font-size: .875rem;
    font-weight: 500;
	font-size: 14px;
    color: #455A64;
    text-transform: capitalize;
}

.patient-info ul li i {
	width: 18px;
}
.patient-info ul li span {
    color: #000;
    float: right;
}

/*-----------------
	25. Add Billing
-----------------------*/

.biller-info, .billing-info {
	margin-bottom: 15px;
}
.add-more-item {
	margin-bottom: 10px;
}
.add-more-item a {
	color: #1ec8c8;
	font-weight: 500;
}
.signature-wrap {
    float: right;
    margin-bottom: 20px;
    text-align: center;
    width: 220px;
}
.signature {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    border: 2px dashed #ccc;
    border-radius: 4px;
	color: #272b41;
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    height: 85px;
	margin-bottom: 15px;
    width: 100%;
}
.signature:hover {
    background-color: #fcfcfc;
}
.sign-name {
	width:100%;
	float:right;
}
.pat-widget-profile .pro-widget-content {
	padding: 0 0 20px;
}
.pat-widget-profile .booking-date li {
	font-size: .875rem;
}
.pat-widget-profile .booking-date li span {
    font-size: .875rem;
}

/*-----------------
	26. Chat
-----------------------*/

.chat-page .content {
	padding: 30px 0 0;
}
.chat-page .content > .container-fluid {
	padding: 0 15px;;
}
.chat-page .footer {
	display: none;
}
.chat-window {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
}
.chat-cont-left {
    position: relative;
    z-index: 4;
	border: 1px solid #EBEBEB;
    border-radius: 8px;
}
.chat-cont-left .chat-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #EBEBEB;
    border-radius: 8px 8px 0 0;
    color: #272b41;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    height: 72px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    padding: 0 10px;
}
.chat-cont-left .chat-header span {
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    color: #1ec8c8;
}
.chat-cont-left .chat-header .chat-compose {
    color: #8a8a8a;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.chat-cont-left .chat-search {
    background-color: #FFFFFF;
    padding: 10px;
    width: 100%;
}
.chat-cont-left .chat-search .input-group {
    width: 100%;
}
.chat-cont-left .chat-search .input-group .form-control {
    background-color: #fff;
    border-radius: 50px;
}
.chat-cont-left .chat-search .input-group .form-control:focus {
    border-color: #ccc;
    box-shadow: none;
}
.chat-cont-left .chat-search .input-group .input-group-prepend {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    bottom: 0;
    color: #666;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    right: 15px;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 4;
}
.chat-window .chat-scroll {
	min-height: 300px;
    max-height: calc(100vh - 274px);
    overflow-y: auto;
}
.chat-cont-left .chat-users-list {
    background-color: #fff;
	padding: 0 10px 0;
}
.chat-cont-left .chat-users-list a.media {
    background: #F5F5F5;
	border-radius: 5px;
    padding: 10px 10px;
	margin-bottom: 10px;
    transition: all 0.2s ease 0s;
}
.chat-cont-left .chat-users-list a.media:last-child {
	border-bottom: 0;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap {
    margin-right: 15px;
    position: relative;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
    height: 45px;
    width: 45px;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap .status {
	bottom: 7px;
	height: 10px;
	right: 4px;
	position: absolute;
	width: 10px;
	border: 2px solid #fff;
}
.chat-cont-left .chat-users-list a.media .media-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name,
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name {
    color: #272b41;
    text-transform: capitalize;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    color: #8a8a8a;
    font-size: 14px;
    line-height: 24px;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child {
    text-align: right;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child .badge-success {
	background: #41B883;
	border-radius: 5px;
	width: 20px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child .last-chat-time {
    color: #8a8a8a;
    font-size: 13px;
}
.chat-cont-left .chat-users-list a.media:hover {
	background-color: #f5f5f6;
}
.chat-cont-left .chat-users-list a.media.read-chat .media-body > div:last-child .last-chat-time {
	color: #8a8a8a;
}
.chat-cont-left .chat-users-list a.media.active {
	background-color: #E5F1FB;
}
.chat-cont-right .chat-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    background-color: #fff;
    border: 1px solid #EBEBEB;
	border-radius: 8px 8px 0 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    height: 72px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    padding: 0 15px;
}
.chat-cont-right .chat-header .back-user-list {
    display: none;
    margin-right: 5px;
    margin-left: -7px;
}
.chat-cont-right .chat-header .media {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.chat-cont-right .chat-header .media .media-img-wrap {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 15px;
}
.chat-cont-right .chat-header .media .media-img-wrap .avatar {
	height: 50px;
	width: 50px;
}
.chat-cont-right .chat-header .media .media-img-wrap .status {
    border: 2px solid #fff;
    bottom: 0;
    height: 10px;
    position: absolute;
    right: 3px;
    width: 10px;
}
.chat-cont-right .chat-header .media .media-body .user-name {
    color: #272b41;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
}
.chat-cont-right .chat-header .media .media-body .user-status {
    color: #666;
    font-size: 14px;
}
.chat-cont-right .chat-header .chat-options {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.chat-cont-right .chat-header .chat-options > a {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    border-radius: 50%;
    color: #8a8a8a;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    height: 30px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    margin-left: 10px;
    width: 30px;
}
.chat-cont-right .chat-body {
    background-color: #FFFFFF;
	border-right: 1px solid #EBEBEB;
    border-left: 1px solid #EBEBEB;
}
.chat-cont-right .chat-body ul.list-unstyled {
    margin: 0 auto;
    padding: 15px;
    width: 100%;
}
.chat-cont-right .chat-body .media .avatar {
    height: 30px;
    width: 30px;
}
.chat-cont-right .chat-body .media .media-body {
	margin-left: 20px;
}
.chat-cont-right .chat-body .media .media-body .msg-box > div {
	padding: 10px 15px;
	border-radius: .25rem;
	display: inline-block;
	position: relative;
}
.chat-cont-right .chat-body .media .media-body .msg-box > div p {
    color: #272b41;
    margin-bottom: 0;
}
.chat-cont-right .chat-body .media .media-body .msg-box + .msg-box {
	margin-top: 5px;
}
.chat-cont-right .chat-body .media.received {
	margin-bottom: 20px;
}
.chat-cont-right .chat-body .media:last-child {
	margin-bottom: 0;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box > div {
	background: #F2F2F2;
	border-radius: 10px 10px 0px 38px;
}
.chat-cont-right .chat-body .media.sent {
    margin-bottom: 20px;
}
.chat-cont-right .chat-body .media.sent .media-body {
	-webkit-box-align: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: flex-end;
    margin-left: 0;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div {
    background-color: #C4E4FF;
    border-radius: 10px 10px 0px 38px;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div p {
    color: #000;
}
.chat-cont-right .chat-body .chat-date {
    font-size: 14px;
    margin: 1.875rem 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}
.chat-cont-right .chat-body .chat-date:before {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    margin-right: 28px;
    position: absolute;
    right: 50%;
    top: 50%;
    width: 100%;
}
.chat-cont-right .chat-body .chat-date:after {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    left: 50%;
    margin-left: 28px;
    position: absolute;
    top: 50%;
    width: 100%;
}
.chat-cont-right .chat-footer {
    background-color: #fff;
    border: 1px solid #EBEBEB;
	border-radius: 0 0 8px 8px;
    padding: 10px 15px;
    position: relative;
}
.chat-cont-right .chat-footer .input-group {
    width: 100%;
}
.chat-cont-right .chat-footer .input-group .form-control {
    background-color: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 8px !important;
}
.chat-cont-right .chat-footer .input-group .form-control:focus {
    background-color: #f5f5f6;
    border: none;
    box-shadow: none;
}
.chat-cont-right .chat-footer .input-group .btn {
    background-color: transparent;
    border: none;
    color: #9f9f9f;
}
.chat-cont-right .chat-footer .input-group .btn.msg-send-btn {
    background-color: #1ec8c8;
    border-color: #1ec8c8;
    border-radius: 8px !important;
    color: #fff;
    margin-left: 10px;
    min-width: 50px;
    font-size: 20px;
}
.chat-cont-right .chat-footer .input-group .btn.msg-send-btn:hover {
    background-color: #0469c8;
    border: 1px solid #0469c8;
}
.msg-typing {
	width: auto;
	height: 24px;
	padding-top: 8px
}
.msg-typing span {
	height: 8px;
	width: 8px;
	float: left;
	margin: 0 1px;
	background-color: #a0a0a0;
	display: block;
	border-radius: 50%;
	opacity: .4
}
.msg-typing span:nth-of-type(1) {
	animation: 1s blink infinite .33333s
}
.msg-typing span:nth-of-type(2) {
	animation: 1s blink infinite .66666s
}
.msg-typing span:nth-of-type(3) {
	animation: 1s blink infinite .99999s
}
.chat-cont-right .chat-body .media.received .media-body .msg-box {
	position: relative;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box {
    padding-left: 50px;
    position: relative;
}
.chat-msg-info {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    clear: both;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 5px 0 0;
}
.chat-msg-info li {
    font-size: 13px;
    padding-right: 16px;
    position: relative;
}
.chat-msg-info li:not(:last-child):after {
	position: absolute;
	right: 8px;
	top: 50%;
	content: '';
	height: 4px;
	width: 4px;
	background: #d2dde9;
	border-radius: 50%;
	transform: translate(50%, -50%)
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box .chat-msg-info li:not(:last-child)::after {
    right: auto;
    left: 8px;
    transform: translate(-50%, -50%);
    background: #aaa;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box > div .chat-time {
    color: #000;
	font-size: 10px;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-time {
    color: #000;
	font-size: 12px;
}
.chat-msg-info li a {
	color: #777;
}
.chat-msg-info li a:hover {
	color: #2c80ff
}
.chat-seen i {
	color: #00d285;
	font-size: 16px;
}
.chat-msg-attachments {
	padding: 4px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin: 0 -1px
}
.chat-msg-attachments > div {
	margin: 0 1px
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info {
    flex-direction: row-reverse;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-attachments {
	flex-direction: row-reverse
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info li {
    padding-left: 16px;
    padding-right: 0;
    position: relative;
}
.chat-attachment img {
    max-width: 100%;
}
.chat-attachment {
	position: relative;
	max-width: 130px;
	overflow: hidden;
}
.chat-attachment {
	border-radius: .25rem;
}
.chat-attachment:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #000;
	content: "";
	opacity: 0.4;
	transition: all .4s;
}
.chat-attachment:hover:before {
	opacity: 0.6;
}
.chat-attach-caption {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	color: #fff;
	padding: 7px 15px;
	font-size: 13px;
	opacity: 1;
	transition: all .4s;
}
.chat-attach-download {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: all .4s;
	color: #fff;
	width: 32px;
	line-height: 32px;
	background: rgba(255, 255, 255, 0.2);
	text-align: center;
}
.chat-attach-download:hover {
	color: #495463;
	background: #fff;
}
.chat-attachment:hover .chat-attach-caption {
	opacity: 0;
}
.chat-attachment:hover .chat-attach-download {
	opacity: 1;
}
.chat-attachment-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: -5px;
}
.chat-attachment-list li {
	width: 33.33%;
	padding: 5px;
}
.chat-attachment-item {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border: 5px solid rgba(230, 239, 251, 0.5);
	height: 100%;
	min-height: 60px;
	text-align: center;
	font-size: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div:hover .chat-msg-actions {
	opacity: 1;
}
.chat-msg-actions {
	position: absolute;
	left: -30px;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;
	transition: all .4s;
	z-index: 2;
}
.chat-msg-actions > a {
    padding: 0 10px;
    color: #495463;
    font-size: 24px;
}
.chat-msg-actions > a:hover {
	color: #2c80ff;
}

@keyframes blink {
	50% {
		opacity: 1
	}
}
.btn-file {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: 20px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    overflow: hidden;
    padding: 0 0.75rem;
    position: relative;
    vertical-align: middle;
}
.btn-file input {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

/*-----------------
	27. Doctor Profile Settings
-----------------------*/

.profile-image img {
	margin-bottom: 1.5rem;
}
.change-photo-btn {
    background-color: #1ec8c8;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 13px;
    font-weight: 600;
    margin: 0 auto;
    padding: 10px 15px;
    position: relative;
    -webkit-transition:all .3s ease 0s;
    -moz-transition:all .3s ease 0s;
    -o-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
    text-align: center;
    width: 220px;
}
.change-photo-btn input.upload {
	bottom: 0;
    cursor: pointer;
    filter: alpha(opacity=0);
	left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
	width: 220px;
}
.dropzone {
    background-color: #FFFFFF;
    border: 1px dashed #EBEBEB;
	border-radius: 8px;
	min-height: 100px;
}
.btn-icon {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    text-align: center;
    position: absolute;
    padding: 0;
    font-size: 10px;
    width: 20px;
    height: 20px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    right: 5px;
    top: 5px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.upload-images {
    position: relative;
    width: 80px;
}
.upload-images img {
	border-radius: 4px;
	height: 80px;
	width: auto;
}
.upload-images .btn-danger {
	background: #DB0C0B;
	border-radius: 5px;
}
.upload-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.upload-wrap .upload-images {
	width: auto;
}
.upload-wrap .upload-images + .upload-images {
	margin-left: 10px;
}
.contact-card .card-body {
	padding-bottom: 0.625rem;
}
.custom_price_cont {
	margin-top: 20px;
}
.btn.btn-danger.trash {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    height: 46px;
    width: 46px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	color: #EB5757;
	border-radius: 8px;
}
.btn.btn-danger.trash:hover {
	color: #FFFFFF;
}
.add-more a {
    color: #ffffff;
    background: #1ec8c8;
    border-radius: 8px;
    padding: 10px 20px;
	text-align: center;
	display: inline-block;
}
.bootstrap-tagsinput {
    border-color: #EBEBEB;
    box-shadow: inherit;
    min-height: 46px;
    width: 100%;
    border-radius: 8px;
}
.bootstrap-tagsinput.focus {
	border-color: #bbb;
}
.bootstrap-tagsinput .tag {
    background-color: #1ec8c8;
    border: 1px solid #1ec8c8;
	border-radius: 8px;
    color: #fff;
    display: inline-flex;
	justify-content: space-between;
    font-size: 12px;
    font-weight: 600;
    margin: 0 2px 1px 0;
    padding: 14px;
	min-width: 171px;
}
.services-card .bootstrap-tagsinput input {
	width: 160px;
}
.submit-section .submit-btn {
    padding: 15px 40px;
    font-weight: 600;
    font-size: 18px;
	border-radius: 8px;
}
.submit-section .submit-btn + .submit-btn {
    margin-left: 15px;
}
.basic-info .sub-heading {
	margin-bottom: 25px;
}

/*-----------------
	28. Calendar
-----------------------*/

.drag-drop .card-header h4 {
	font-weight: bold;
	font-size: 25px;
	line-height: 30px;
	color: #005BEA;
}
.add-category {
	padding: 30px 15px;
	border-radius: 10px;
}
#calendar-events {
    background-color: #FFFFFF;
}
.calendar-events {
    background: rgba(229, 241, 251, 0.27);
	border: 1px solid rgba(229, 241, 251, 0.27);
	border-radius: 10px;
    cursor: move;
    padding: 20px 15px;
	font-size: 18px;
	color: #000;
	margin-bottom: 15px;
}
.calendar-events:hover {
    background-color: #fff;
	border: 1px solid rgba(229, 241, 251, 0.27);
}
.calendar-events i {
    margin-right: 8px;
}
.calendar {
	float: left;
	margin-bottom: 0;
}
.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5rem;
}
.none-border .modal-footer {
	border-top: none;
}
.fc-toolbar h2 {
	font-size: 25px;
	font-weight: 600;
	font-family: 'Roboto', sans-serif;
	line-height: 30px;
	text-transform: uppercase;
}
.fc-day-grid-event .fc-time {
	font-family: 'Roboto', sans-serif;
}
.fc-day {
	background: #fff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
	z-index: 0;
}
.fc th.fc-widget-header {
	background: #1ec8c8;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    line-height: 20px;
    padding: 17px 0;
	text-transform: uppercase;
}
.fc-unthemed td.fc-today {
    background: #C5E5FF !important;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
	border-color: #f3f3f3;
}
.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
    padding: 2px 5px;
}
.fc-button {
	background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    color: #005BEA;
	text-transform: capitalize;
	box-shadow: none !important;
	border-radius: 3px !important;
	margin: 0 3px !important;
	padding: 13px 12px !important;
	height: auto !important;
}
.fc-text-arrow {
	font-family: inherit;
	font-size: 16px;
}
.fc-state-hover {
	background: #f3f3f3;
}
.fc-state-highlight {
	background: #f0f0f0;
}
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	background-color: #1ec8c8 !important;
	color: #fff !important;
	text-shadow: none !important;
}
.fc-cell-overlay {
	background: #f0f0f0;
}
.fc-unthemed .fc-today {
	background: #fff;
}
.fc-event {
	border-radius: 2px;
	border: none;
	color: #fff !important;
	cursor: move;
	font-size: 13px;
	margin: 1px 7px;
	padding: 5px 5px;
	text-align: center;
}
.fc-basic-view td.fc-week-number span {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}
.fc-basic-view td.fc-day-number {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}
.event-form .input-group .form-control {
	height: 40px;
}

/*-----------------
	29. Patient Dashboard
-----------------------*/

.profile-sidebar {
	margin-bottom: 30px;
	overflow: hidden;
}
.pro-widget-content {
    border-bottom: 1px solid #f0f0f0;
    padding: 20px;
    text-align: center;
	margin-bottom: 20px;
}
.profile-info-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    text-align: left;
}
.profile-info-widget .booking-doc-img {
    margin-right: 15px;
}
.profile-info-widget .booking-doc-img img {
    border-radius: 4px;
    height: 90px;
    width: 90px;
    object-fit: cover;
}
.profile-det-info {
	overflow: hidden;
}
.profile-det-info h3, .profile-det-info h3 a {
	font-size: 20px;
	font-weight: bold;
	line-height: 24px;
	margin-bottom: 5px;
	color: #1ec8c8;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.patient-details h5 {
    color: #000;
    font-size: 14px;
	font-weight: 500;
	line-height: 17px;
    font-weight: normal;
    margin-bottom: 8px;
}
.patient-details h5.pat-id {
	color: #455A64;
}
.patient-details h5.pat-id span {
	color: #F2994A;
}
.patient-details.info-loc h5 {
	font-size: 12px;
	line-height: 22px;
	margin-bottom: 3px;
}
.patient-details h5 i {
	width: 18px;
}
.dashboard-menu ul {
    color: #455A64;
    font-size: 18px;
    line-height: 17px;
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: capitalize;
}
.dashboard-menu ul li {
	line-height: inherit;
}
.dashboard-menu > ul > li {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	border-radius: 8px;
	margin-bottom: 20px;
	position: relative;
}
.dashboard-menu > ul > li:last-child {
	margin-bottom: 0;
}
.dashboard-menu ul li a span,
.dashboard-menu ul li a i {
	display: inline-block;
	vertical-align: middle;
}
.dashboard-menu > ul > li > a {
	color: #455A64;
	font-size: 18px;
	font-weight: 500;
	display: flex;
    justify-content: space-between;
	padding: 20px;
	border-radius: 8px;
}
.dashboard-menu > ul > li:hover > a,
.dashboard-menu > ul > li.active > a {
	background: #1ec8c8;
	color: #FFFFFF;
}
.dashboard-menu ul li a i {
    font-size: 18px;
    margin-right: 10px;
}
.unread-msg {
    background-color: #1ec8c8;
    border-radius: 2px;
    color: #272b41;
    font-size: 10px;
    font-style: normal;
    padding: 0 5px;
    position: absolute;
    right: 20px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
}
.patient-dashboard-top{
	margin-bottom: 15px;
}
.patient-dashboard-top,
.patient-graph-box {
	display: flex;
	display: -webkit-flex;
}
.patient-dashboard-top > div {
	width: 100%;
}
.patient-dashboard-top .card-body {
	padding: 20px;
}
.patient-dashboard-top h5 {
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #1ec8c8;
	margin-bottom: 15px;
}
.patient-dashboard-top h6 {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 0;
}
.patient-dashboard-top h6 sub {
	font-size: 12px;
}
.patient-graph-col .graph-box {
    background: #FFFFFF;
	border: 1px solid #EBEBEB;
	border-radius: 8px;
	width: 100%;
	padding: 20px;
	margin-bottom: 25px;
}
.patient-graph-col .graph-box h4 {
    font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #1ec8c8;
	margin-bottom: 25px;
    text-align: center;
}
.patient-graph-col .graph-box > div {
	width: 100%;
}
.graph-img {
    text-align: center;
    margin: 0 auto;
    width: 100%;
}
.graph-status-result {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    align-items: flex-end;
    -webkit-align-items: flex-end;
	margin-top: 25px;
}
.graph-status-result h3 {
    color: #fff;
    margin-bottom: 0;
    line-height: 0.9;
    width: 100%;
}
.graph-status-result span.graph-update-date {
    font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #000;
    text-align: center;
	width: 100%;
}
.patient-graph-col .graph-box.pink-graph {
    background-color: #ffa69e;
    border-bottom: 8px solid #fe9286;
}
.patient-graph-col .graph-box.sky-blue-graph {
    background-color: #a9d4ff;
    border-bottom: 8px solid #8ec4ff;
}
.patient-graph-col .graph-box.orange-graph {
    background-color: #ffb88e;
    border-bottom: 8px solid #ffa775;
}

/*-----------------
	30. Profile Settings
-----------------------*/

.change-avatar {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.change-avatar .profile-img {
	margin-right: 15px;
}
.change-avatar .profile-img img {
	border: 1px solid #A6A6A6;
    border-radius: 8px;
    height: 100px;
    width: 100px;
    object-fit: cover;
}
.change-avatar .change-photo-btn {
    margin: 0 0 10px;
    width: 150px;
}
.widget-profile.pat-widget-profile .profile-info-widget .booking-doc-img {
	padding: 0;
}
.widget-profile.pat-widget-profile .profile-info-widget .booking-doc-img img {
	border: 1px solid #E0E0E0;
    border-radius: 8px;
    height: 100px;
    width: 100px;
}
.success-status {
	background-color: rgba(133, 255, 201, 0.25);
    border-radius: 3px;
    padding: 15px 12px;
	min-width: 110px;
    color: #28A745;
    font-weight: 500;
    font-size: 14px;
}
.warning-status {
	background-color: rgba(242, 153, 74, 0.25);
    border-radius: 3px;
    padding: 15px 12px;
	min-width: 110px;
    color: #F2994A;
    font-weight: 500;
    font-size: 14px;
}
.danger-status {
	background-color: rgba(255, 168, 167, 0.25);
    border-radius: 3px;
    padding: 15px 12px;
	min-width: 110px;
    color: #EB5757;
    font-weight: 500;
    font-size: 14px;
}
.info-status {
	background-color: rgba(17, 148, 247, 0.12);
    border-radius: 3px;
    padding: 15px 12px;
	min-width: 110px;
    color: #1ec8c8;
    font-weight: 500;
    font-size: 14px;
}
.btn-add {
	padding: 12px 30px;
	font-weight: 600;
	font-size: 18px;
}

/*-----------------
	31. Appoitment List
-----------------------*/

.widget-profile {
    background-color: #fff;
    border: 1px solid #EBEBEB;
	border-radius: 8px;
}
.widget-profile .profile-info-widget {
	align-items: center;
}
.widget-profile .profile-info-widget .booking-doc-img {
    display: inline-block;
    width: auto;
}
.widget-profile .profile-info-widget .booking-doc-img img {
    border-radius: 8px;
    height: 76px;
    width: 76px;
}
.appointments-detail .pro-widget-content {
    border-bottom: 0;
	margin: 0;
	padding: 0 0 15px;
}
.appointments-detail .patient-details h5 {
	color: #455A64;
	margin-bottom: 20px;
}
.appointments-detail .profile-det-info h3 {
	margin-bottom: 18px;
}
.appointment-list .profile-info-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    margin-right: auto;
    text-align: left;
}
.appointment-list .profile-info-widget .booking-doc-img img {
    border-radius: 4px;
    height: 120px;
    object-fit: cover;
    width: 120px;
}
.appointments .appointment-list {
	background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
	margin-bottom: 20px;
    padding: 20px;
}
.appointments .appointment-list:last-child {
	margin-bottom: 30px;
}
.appointments .appointment-action {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.appointment-action a + a {
	margin-left: 5px;
}
.appointment-action a {
    font-size: 13px;
	width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
}
.info-details {
    list-style: none;
    margin: 0;
    padding: 0;
}
.info-details li .title {
    color: #272b41;
    font-weight: 500;
}
.info-details li .text {
    color: #757575;
    display: block;
    font-size: 16px;
    overflow: hidden;
}
.info-details li {
	margin-bottom: 10px;
}
.info-details li:last-child {
	margin-bottom: 0;
}

/*-----------------
	32. Reviews
-----------------------*/

.doc-review.review-listing {
	margin: 0;
}
.review-listing.doc-review > ul > li {
	background-color: #fff;
	border: 1px solid #EBEBEB;
    border-radius: 8px;
}

/*-----------------
	33. Voice call
-----------------------*/

.modal-open .main-wrapper {
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
}
.call-main-row {
	bottom: 0;
	left: 0;
	overflow: auto;
	padding-bottom: inherit;
	padding-top: inherit;
	position: absolute;
	right: 0;
	top: 0;
}
.call-main-wrapper {
	display: table;
	height: 100%;
	table-layout: fixed;
	width: 100%;
}
.call-view {
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	position: static;
	vertical-align: top;
	width: 75%;
}
.call-window {
	display: table;
	height: 100%;
	table-layout: fixed;
	width: 100%;
	background-color: #fff;
	border: 1px solid #EBEBEB;
	border-radius: 8px;
}
.fixed-header {
	background-color: #fff;
	border-bottom: 1px solid #EBEBEB;
	border-radius: 8px 8px 0 0;
	padding: 10px 15px;
}
.fixed-header .navbar {
	border: 0 none;
	margin: 0;
	min-height: auto;
	padding: 0;
}
.fixed-header .user-info a {
    color: #000;
    font-weight: 600;
}
.call-window .user-details {
	display: -ms-inline-flexbox;
    display: inline-flex;
	-ms-flex-align: center;
    align-items: center;
}
.typing-text {
	color: #1ec8c8;
	font-size: 12px;
	text-transform: lowercase;
}
.last-seen {
	color: #28a745;
	display: block;
	font-size: 15px;
}
.custom-menu {
	margin-top: 6px;
}
.fixed-header .custom-menu {
	margin: 0 0 1px;
}
.custom-menu.nav > li > a {
    color: #000;
    font-size: 26px;
    line-height: 32px;
    margin-left: 15px;
    padding: 0;
}
.custom-menu.navbar-nav > li > a:hover,
.custom-menu.navbar-nav > li > a:focus {
	background-color: transparent;
}
.custom-menu .dropdown-menu {
	left: auto;
	right: 0;
}
.call-contents {
	display: table-row;
	height: 100%;
}
.call-content-wrap {
	height: 100%;
	position: relative;
	width: 100%;
}
.voice-call-avatar {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	-ms-flex: 2;
	flex: 2;
}
.voice-call-avatar .call-avatar {
	margin: 90px 15px 15px;
	width: 210px;
	height: 210px;
	border-radius: 100%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 15px;
	background-color: #fff;
}
.call-duration {
	display: inline-block;
	font-size: 20px;
	top: 10px;
	position: absolute;
	left: 50%;
    transform: translate(-50%, 0);
	color: #000;
	padding: 5px 10px;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    background: #f3f9ff;
}
.voice-call-avatar .call-timing-count {
	padding: 5px 5px 20px;
	color: #000;
}
.voice-call-avatar .username {
	font-size: 25px;
	font-weight: 600;
	color: #000;
}
.call-footer {
	background-color: #fff;
	border-top: 1px solid #f0f0f0;
	padding: 15px;
}
.call-icons {
	position: relative;
}
.call-icons .call-items {
	border-radius: 5px;
	padding: 0;
	margin: 0;
	list-style: none;
	display: inline-block;
}
.call-icons .call-items .call-item {
	display: inline-block;
	text-align: center;
	margin-right: 5px;
}
.call-icons .call-items .call-item:last-child {
	margin-right: 0;
}
.call-icons .call-items .call-item a {
	color: #005BEA;
	background: #F3F9FF;
	border: 1px solid #E8E8E8;
	width: 60px;
	height: 60px;
	line-height: 60px;
	border-radius: 50px;
	display: inline-block;
	font-size: 20px;
}
.call-icons .call-items .call-item a:hover {
	color: #FFF;
	background: #005BEA;
	border: 1px solid #005BEA;
}
.call-icons .call-items .call-item a i {
	width: 18px;
	height: 18px;
}
.user-video {
	bottom: 0;
	left: 0;
	overflow: auto;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 10;
}
.user-video img {
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	display: block;
	border-radius: 25px;
	padding: 70px 20px 20px;
	margin: 0 auto;
}
.user-video video {
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	display: block;
	margin: 0 auto;
}
.my-video {
	position: absolute;
	z-index: 99;
	top: 20px;
	right: 20px;
}
.my-video ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.my-video ul li {
	float: left;
	width: 64px;
	margin-right: 10px;
}
.my-video ul li img {
	border: 3px solid #fff;
	border-radius: 6px;
}
.end-call {
	position: absolute;
	top: 0;
	right: 0;
}
.end-call a {
    background-color: #EE5755;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    line-height: 10px;
    padding: 18px 57px;
    text-transform: uppercase;
}
.call-users {
	position: absolute;
	z-index: 99;
	top: 20px;
	right: 20px;
}
.call-users ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.call-users ul li {
	float: left;
	width: 64px;
	margin-left: 10px;
}
.call-users ul li img {
	border-radius: 6px;
	padding: 2px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);
}
.call-mute {
	width: 64px;
	height: 64px;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	text-align: center;
	line-height: 64px;
	border-radius: 6px;
	font-size: 30px;
	color: #fff;
	display: none;
	top: 0;
	border: 3px solid transparent;
}
.call-users ul li a:hover .call-mute {
	display: block;
}
.call-details {
	margin: 10px 0 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.call-info {
	margin-left: 10px;
	width: 100%;
}
.call-user-details,
.call-timing {
	display: block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.call-description {
	white-space: nowrap;
	vertical-align: bottom;
}
.call-timing {
	color: #727272;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 14px;
	margin-top: 1px;
	overflow: hidden;
	white-space: pre;
}

/*-----------------
	34. Video Call
-----------------------*/

.content-full {
	height: 100%;
	position: relative;
	width: 100%;
}
.video-window .fixed-header {
	padding: 0;
	border: 0;
}
.video-window .fixed-header .nav > li > a {
	padding: 18px 15px;
}

/*-----------------
	35. Outgoing Call
-----------------------*/

.call-box .call-wrapper {
	height: auto;
	text-align: center;
}
.call-box .call-wrapper .call-avatar {
	margin-bottom: 30px;
	cursor: pointer;
	animation: ripple 2s infinite;
}
.call-box .call-wrapper .call-user {
	margin-bottom: 30px;
}
.call-box .call-wrapper .call-user span {
	display: block;
	font-weight: 500;
	text-align: center;
}
.call-box .call-wrapper .call-items {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.call-box .call-wrapper .call-items .call-item {
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid transparent;
    border-radius: 100%;
    color: #fff;
    line-height: 0;
    margin: 0 5px;
    padding: 15px;
}
.call-box .call-wrapper .call-items .call-item:hover {
	opacity: 0.9;
}
.call-box .call-wrapper .call-items .call-item:first-child {
	margin-top: -30px;
}
.call-box .call-wrapper .call-items .call-item:last-child {
	margin-top: -30px;
}
.call-box .call-wrapper .call-items .call-item.call-end {
	padding: 20px;
	margin: 30px 20px 0;
	background: #f06060;
	border: 1px solid #f06060;
	color: #fff;
	line-height: 0;
	border-radius: 100%;
}
.call-box .call-wrapper .call-items .call-item.call-start {
	padding: 20px;
	margin: 30px 20px 0;
	background: #55ce63;
	border: 1px solid #55ce63;
	color: #fff;
	line-height: 0;
	border-radius: 100%;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-start {
	margin: 0 10px;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-end {
	margin: 0 10px;
}
.call-box .call-avatar {
    border-radius: 100%;
    height: 140px;
    max-width: 140px;
    min-width: 140px;
    position: relative;
    width: 100%;
    border: 10px solid #fafafa;
}
.call-box .btn {
    background: rgba(0, 0, 0, 0);
    transition: all 0.3s ease 0s;
}
@-webkit-keyframes ripple {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
	}
	100% {
		-webkit-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
	}
}
@keyframes ripple {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
	}
	100% {
		-moz-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
		box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
	}
}

/*-----------------
	36. Incoming Call
-----------------------*/

.incoming-btns {
	margin-top: 20px;
}
.call-wrapper {
    position: relative;
    height: calc(100vh - 145px);
}
.call-page .footer {
	display: none;
}
.dropdown-action .dropdown-toggle::after {
	display: none;
}
.call-modal .modal-body {
	padding: 40px;
}
.call-modal .modal-content {
    border: 0;
    border-radius: 10px;
}
.call-box .call-wrapper .call-user h4 {
	font-size: 24px;
}

/*-----------------
	37. Terms and Conditions
-----------------------*/

.terms-text {
    margin-bottom: 20px;
}
.terms-text h4 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}
.terms-text p {
    color: #666;
    display: inline-block;
    font-size: 16px;
}

/*-----------------
	38. Blog
-----------------------*/

.blog {
	border: 1px solid #E5E5E5;
	background-color: #fff;
	border-radius: 8px;
	margin-bottom: 30px;
	padding: 1.5rem;
	position: relative;
}
.blog-image {
	overflow: hidden;
}
.blog-image,
.blog-image > a,
.blog-image img {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
	border-radius: 8px 8px 0 0;
}
.blog-image img {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.blog-image a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.blog-image {
	margin-bottom: 30px;
}
.blog-content {
	position: relative;
}
.blog-title {
	font-size: 22px;
	margin: 0 0 10px;
}
.blog-title a {
	color: #272b41;
}
.blog-title a:hover {
	color: #1ec8c8;
}
.blog-content p {
	color: #263238;
	font-size: 18px;
	margin: 0 0 15px;
}
.read-more {
    display: inline-block;
    font-size: 16px;
    padding: 0;
    color: #808191;
    text-decoration: underline;
    font-weight: bold;
}
.blog-info {
    color: #000;
    font-size: 12px;
	font-weight: 600;
    margin-bottom: 15px;
}
.blog-info a {
	color: #000;
}
.blog-info a:hover {
	color: #005BEA;
}
.blog-info i {
	color: #1ec8c8;
	font-size: 14px;
	margin-right: 5px;
}
.entry-meta {
	color: #757575;
	font-size: 12px;
	list-style: none;
	margin-bottom: 15px;
	padding: 0;
}
.entry-meta li {
    display: inline-block;
    margin-right: 15px;
}
.entry-meta li:last-child {
	margin-right: 0;
}
.blog-pagination .pagination {
	margin-bottom: 30px;
}
.blog-pagination .pagination li a {
    padding: 12px 20px;
    font-weight: 600;
    color: #2E3842;
    margin-right: 10px;
    border-radius: inherit !important;
	border-color: #f0f0f0;
}
.blog-pagination .pagination li.disabled a {
	border-color: #f0f0f0;
}
.blog-pagination .pagination li.active a {
	background: #1ec8c8;
	border-color: transparent;
	color: #ffffff;
}
.post-left ul {
	margin: 0;
	padding-left: 0;
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.post-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.post-left .post-author {
	margin-bottom: 5px;
	margin-right: 20px;
}
.post-left ul li {
	margin-bottom: 5px;
	margin-right: 20px;
}
.post-left ul li:last-child {
	margin-right: 0;
}
.post-author a {
	display: flex;
	align-items: center;
}
.search-form .btn-white {
	background: #FFF;
	border-color: #EBEBEB;
	color: #455A64;
	border-left: 0;
}
.search-form .form-control::placeholder {
	color: #455A64;
	font-size: 12px;
}
.video {
	position: relative;
	padding-bottom: 56%;
	height: 0;
	overflow: hidden;
}
.video iframe,
.video object,
.video embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
}
.latest-posts {
	margin: 0;
	padding: 0;
}
.latest-posts li {
	display: table;
	width: 100%;
	margin-bottom: 20px;
}
.latest-posts li:last-child {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border-bottom: none;
}
.post-thumb {
	border-radius: 4px;
	width: 80px;
	float: left;
	overflow: hidden;
}
.post-thumb a img {
	border-radius: 4px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.post-thumb a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.post-info {
	margin-left: 95px;
}
.post-info h4 {
	font-size: 15px;
	margin: 0 0 5px;
}
.post-info h4 a {
	color: #272b41;
}
.post-info h4 a:hover {
	color: #1ec8c8;
}
.post-info p {
	color: #757575;
	font-size: 12px;
	margin: 0;
}
.post-info p i {
	color: #1ec8c8;
	font-size: 14px;
	margin-right: 4px;
}
.category-widget .categories {
	list-style: none;
	margin: 0;
	padding: 0;
}
.category-widget .categories li {
    margin-bottom: 20px;
}
.category-widget .categories li:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.category-widget .categories li a {
    color: #2E3842;
    font-size: 15px;
    font-weight: 500;
}
.category-widget .categories li a span {
    float: right;
    color: #757575;
}
.category-widget .categories li a:hover {
	color: #1ec8c8;
}
.category-widget .categories > li > a > i {
	color: #1ec8c8;
	font-size: 18px;
	margin-right: 10px;
}
.tags-widget .card-body {
	padding-bottom: 1rem;
}
.tags {
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
}
.tags li {
	float: left;
}
.tag {
	background-color: #fff;
	border-radius: 4px;
	color: #272b41;
	padding: 5px 10px;
	position: relative;
	margin: 0 .5rem .5rem 0;
	font-size: 14px;
	border: 1px solid #ccc;
	display: inline-block;
}
.tag:hover {
	background-color: #1ec8c8;
	border-color: #1ec8c8;
	color: #fff;
}
.comment-by {
	display: block;
	font-size: 14px;
	line-height: 21px;
	margin: 0 0 10px;
}
.comments-list .comment-block p {
    font-size: 14px;
    margin-bottom: 5px;
}
.blogs-section {
	padding: 50px 0;
	background-color: #ffffff;
}
.blogs-section.blog-container {
	padding: 60px 0;
}
.blog-wrapper {
	background: #FFFFFF;
	border: 1px solid #E8EEFF;
	box-sizing: border-box;
	border-radius: 10px 10px 0px 0px;
	margin-bottom: 30px;
}
.blog-wrapper .wrap-image img {
	width: 100%;
	border-radius: 10px 10px 0px 0px;
}
.blog-wrapper .wrap-content {
	padding: 10px;
}
.blog-wrapper .wrap-content:before {
	width: 0;
    height: 0;
    border-left: 149px solid transparent;
    border-right: 149px solid transparent;
    border-bottom: 20px solid #ffffff;
    margin-left: 0;
    position: absolute;
    margin-top: -30px;
    left: 15px;
}
.blog-wrapper .wrap-content .sp-title {
	font-size: 13px;
	color: #2264C5;
	font-weight: 600;
	margin-right: 5px;
}
.blog-wrapper .wrap-content .post-author img {
	width: 20px;
    flex: 0 0 20px;
	margin: 0 5px;
}
.blog-wrapper .wrap-content .post-author span {
	font-size: 12px;
	font-weight: 600;
	color: #000;
}
.blog-wrapper .wrap-content .date-cart {
	background-color: #1860CA;
    padding: 10px 8px;
    font-size: 28px;
    color: #fff;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    width: 65px;
    height: 60px;
    margin: 0;
    position: absolute;
    right: 25px;
    margin-top: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.blog-wrapper .wrap-content .date-cart span {
	font-size: 11px;
    font-weight: 500;
}
.blog-wrapper .wrap-content-body {
	margin-top: 10px;
}
.blog-wrapper .wrap-content-body h3 a {
	font-size: 18px;
	font-weight: 500;
	color: #000;
	line-height: 1.5;
}
.blog-wrapper .wrap-content-body p {
	font-size: 12px;
	color: #000;
}
.blog-wrapper .wrap-content-body a.read-txt {
	width: 110px;
	padding: 10px;
	background: #0CE0FF;
	border-radius: 30px;
	display: block;
	text-align: center;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
}
.blogs-section .view-btn {
    background-color: #1ec8c8;
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    border-radius: 30px;
    border: 1px solid #1ec8c8;
    padding: 6px 20px;
}
.blog-container .blog-wrapper .wrap-content .date-cart {
	top: 50px;
    right: 25px;
}
.blog-container .blog-wrapper .wrap-content-body h3 a {
	color: #1860CA;
}
.blog-container .blog-wrapper .wrap-content .sp-title {
	color: #28DAF4;
    font-size: 14px;
}
/*-----------------
	39. Blog Details
-----------------------*/

.blog-view .blog-title {
	font-size: 24px;
}
.blog-content p:last-child {
	margin-bottom: 0;
}
.blog-view .blog-info {
	border: 0;
	margin-bottom: 20px;
	padding: 0;
}
.social-share {
	float: left;
	list-style: none;
	margin: 0;
	padding: 0;
}
.social-share > li {
	display: inline-block;
	float: left;
	margin-left: 10px;
	text-align: center;
}
.social-share > li:first-child {
	margin-left: 0;
}
.social-share > li > a {
	background: #F4F4F4;
	border: 1px solid #455A64;
	border-radius: 5px;
	color: #455A64;
	display: inline-block;
	font-size: 22px;
	height: 50px;
	line-height: 50px;
	width: 50px;
}
.social-share > li > a:hover {
	background-color: #1ec8c8;
	color: #fff;
	border-color: #1ec8c8;
}
.blog-reply > a {
	color: #999;
	font-size: 12px;
	font-weight: 500;
}
.blog-date {
	color: #999;
	font-size: 12px;
}
.blog-comments .comments-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.blog-comments .comments-list li {
	clear: both;
	padding-left: 80px;
}
.blog-comments .comments-list li .comment {
	margin-bottom: 20px;
}
.blog-comments .comments-list li .comment-author {
	left: 0;
	position: absolute;
}
.blog-comments .comments-list li img.avatar {
	height: 58px;
	width: 58px;
	border-radius: 58px;
}
.blog-comments .comment-btn {
    color: #1ec8c8;
    display: inline-block;
    font-weight: 500;
    font-size: 15px;
}
.blog-author-name {
    color: #272b41;
    font-size: 16px;
    font-weight: 600;
}
.new-comment label {
	font-weight: 500;
}
.comment-submit .btn {
	background-color: #1ec8c8;
	border-color: #1ec8c8;
	border-radius: 0;
	font-size: 18px;
	padding: 8px 26px;
	color: #fff;
}
.about-author-img {
	background-color: #fff;
	height: 100px;
	overflow: hidden;
	position: absolute;
	width: 100px;
}
.author-details {
	margin-left: 120px;
}
.about-author {
	min-height: 100px;
}
.author-details .blog-author-name {
	display: inline-block;
	margin-bottom: 10px;
}
.post-author img {
    border-radius: 100%;
    width: 28px;
    flex: 0 0 28px;
    margin-right: 5px;
}
.post-author a > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.custom-edit-service textarea.form-control{
	min-height: 200px;
}
.custom-edit-service .service-upload {
    border: 1px solid #dcdcdc;
    border-radius: .25rem;
    text-align: center;
    padding: 70px 0;
    margin-bottom: 30px;
    background-color: #fff;
    position: relative;
}
.custom-edit-service .service-upload input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.custom-edit-service .service-upload i{
	font-size: 50px;
    color: #858585;
}
.custom-edit-service .service-upload span {
    font-size: 15px;
    color: #858585;
    margin-top: 14px;
    display: block;
}
.custom-edit-service .upload-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}
.custom-edit-service .upload-wrap li {
    margin: 10px;
}
.custom-edit-service .upload-wrap li:first-child{
	margin-left: 0;
}
.custom-edit-service .upload-wrap li img{
	max-width: 100%;
}

/*-----------------
	40. Blog Grid
-----------------------*/

.grid-blog .blog-title {
    font-size: 18px;
    margin: 0 0 13px;
    line-height: 22px;
	color: #263238;
}
.grid-blog .blog-content {
	padding: 20px;
}
.grid-blog .blog-content p {
	margin: 0 0 10px;
	color: #808191;
    font-size: 13px;
}
.grid-blog .blog-info {
	margin-bottom: 10px;
}
.grid-blog .blog-image {
	margin-bottom: 0;
}
.blog.grid-blog.grid-box {
	padding: 20px;
}
.blog.grid-blog.grid-box .blog-image, .blog-image > a, .blog-image img {
	border-radius: 8px;
}
.blog.grid-blog .blog-image img {
	border-radius: 8px 8px 0 0;
}
.grid-blog.grid-box .blog-content {
    padding: 25px 0 0;
}
.grid-blog.grid-box .entry-meta li {
    margin-top: 0;
    margin-bottom: 25px;
}
.grid-blog.grid-box .blog-title {
    font-size: 22px;
    margin: 0 0 15px;
    line-height: 25px;
    color: #263238;
}
.grid-blog.grid-box .entry-meta {
    color: #000;
    font-size: 12px;
	font-weight: 600;
}
.grid-blog.grid-box .entry-meta i {
	color: #1ec8c8;
}
.grid-blog.grid-box .blog-content p {
    margin: 0;
    line-height: 22px;
    color: #263238;
    font-size: 18px;
}
.blog.grid-blog {
	padding: 0;
}
.grid-blog .blog-content .read-link {
	font-size: 12px;
	text-decoration: underline;
}
.grid-blog .entry-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 0;
}
.grid-blog .entry-meta li {
	margin-bottom: 0;
	margin-top: 13px;
}
.grid-blog .post-author {
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.grid-blog .post-author a:hover {
	color: #1ec8c8
}

/*-----------------
	41. Map List & Grid
-----------------------*/

.map-page .footer {
	display: none;
}
.map-page .header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1030;
}
.map-page .content {
	padding-top: 115px;
}
.map-right {
	bottom: 0;
    height: 100%;
    min-height: 100%;
	padding-right: 30px;
    position: fixed;
    right: 0;
    top: 118px;
    z-index: 1029;
}
.map-listing {
    height: calc(100vh - 145px);
    width: 100%;
}
.map-listing .profile-widget {
    width: 200px !important;
    padding: 0;
    border: 0;
	margin-bottom: 0;
}
.map-listing .pro-content {
	padding: 15px 0 0;
}
.map-right .available-info {
	margin-bottom: 0;
}
.view-icons {
    display: inline-flex;
    float: right;
}
.view-icons a {
	align-items: center;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	color: #212529;
	display: flex;
	font-size: 20px;
	justify-content: center;
	padding: 4px 10px;
	text-align: center;
	margin-left: 10px;
	width: 44px;
	height: 44px;
}
.view-icons a:hover {
	border-color: #1ec8c8;
	color: #1ec8c8;
}
.view-icons a.active {
    background-color: #1ec8c8;
	border-color: #1ec8c8;
	color: #fff;
}


/*-----------------
	42. Loader
-----------------------*/

#loader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	z-index: 999999;
}
.loader {
	display: inline-block;
	height: 50px;
	left: 50%;
	margin-left: -25px;
	margin-top: -25px;
	width: 50px;
	position: absolute;
	top: 50%;
}
.loader span {
	-webkit-animation: loader 1.3s linear infinite;
	animation: loader 1.3s linear infinite;
	background-color: #1ec8c8;
	border-radius: 100%;
	display: inline-block;
	height: 50px;
	position: absolute;
	width: 50px;
}
.loader span:last-child {
	animation-delay: -0.8s;
	-webkit-animation-delay: -0.8s;
}
@keyframes loader {
	0% {
		transform: scale(0, 0);
		opacity: 0.5;
	}
	100% {
		transform: scale(1, 1);
		opacity: 0;
	}
}
@-webkit-keyframes loader {
	0% {
		-webkit-transform: scale(0, 0);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1, 1);
		opacity: 0;
	}
}

/*-----------------
	43. Product
-----------------------*/

.product-custom span.price{
	font-size: 25px;
    font-weight: 600;
    color: #263238;
}
.product-custom span.price-strike{
	font-size: 14px;
	font-weight: 400;
    text-decoration: line-through;
    color: #999;
}
.product-custom .profile-widget {
	padding: 15px;
}
.product-custom .pro-content {
	padding: 15px 0 0;
}
.product-custom .profile-widget:hover:before {
	content: none;
}
.product-custom .cart-icon{
	font-size: 18px;
	width: 40px;
	height: 40px;
	background: #C4E4FF;
	color: #000;
	border-radius: 3px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	float: right;
}
.product-custom .doc-img img {
	border-radius: 8px;
}
.product-custom .profile-widget:hover .cart-icon{
	color: #fff;
	background-color: #0de0fe;
}
.product-description .doctor-img1{
	width: 35%;
}
.product-description .doc-info-cont{
	width: 65%;
}
.product-description .doctor-widget .doc-name {
    font-size: 20px;
	margin-bottom: 15px;
}
.product-description .doctor-widget .doc-info-cont p{
    font-size: 14px;
	color: #000;
}
.feature-product span{
	padding-bottom: 10px;
	display: inline-block;
}
.feature-product ul {
	padding-left: 20px;
}
.feature-product ul li{
	padding: 3px 0;
	color: #000;
}
ul.benifits-col li i{
	font-size: 20px;
	margin-right: 20px;
	color: #1ec8c8;
}
.custom-increment .input-group1{
	display: flex;
	display: -webkit-flex;
	width: 100%;
}
.custom-increment .input-group-btn{
}
.custom-increment.cart .input-group1{
	justify-content: center;
	-webkit-justify-content: center;
}
.custom-increment input[type=text]{
	border: 0;
	border-radius: 0;
	padding: 7px 15px;
	text-align: center;
	width: 70%;
}
.custom-increment.cart input[type=text]{
	width: 60px;
}
.custom-increment input[type=text]:focus, .custom-increment input[type=text]:focus-visible {
	border-color: transparent;
}
.custom-increment button{
	font-size: 14px;
	height: 60px;
    width: 60px;
    background: #C4E4FF;
    color: #000;
    border: 0;
    display: inline-block;
}
.custom-increment button:hover,
.custom-increment button:focus,
.custom-increment button:active {
	background: #dcdcdc !important;
    color: #000 !important;
    border: 0 !important;
}
.custom-increment.cart button{
	font-size: 10px;
}
.custom-increment button.btn-success{
	border-radius: 0 4px 4px 0;
}
.custom-increment button.btn-danger{
	border-radius: 4px 0px 0px 4px;
}
.clinic-group .list-group-item {
	border: 0;
	padding: 10px 0;
	font-size: 18px;
    font-weight: 500;
}
.benifits-col li {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	border-radius: 8px;
	padding: 20px;
	margin-bottom: 20px;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #1ec8c8;
}
.benifits-col li span {
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: #455A64;
	margin-top: 7px;
}
.benifits-col .list-group-item+.list-group-item {
    border-top-width: 1px;
}
.product-infos .widget-title {
    font-size: 20px;
    font-weight: 500;
}
.product-infos .about-widget p {
    font-size: 14px;
	color: #000;
}
.product-infos .experience-list > li .experience-content .timeline-content {
    color: #000;
}

/*-----------------
	44. Cart
-----------------------*/

.view-cart-header{
	position: relative;
}
.view-cart-header .shopping-cart {
  margin: 20px 0;
  float: right;
  background: white;
  width: 320px;
  position: absolute;
  right: 0;
  top: 99%;
  border-radius: 3px;
  padding: 20px;
  z-index: 999;
  box-shadow: 0 2px 20px rgba(0,0,0,.2);
  display: none;
}
.view-cart-header .shopping-cart.show-cart{
	opacity: 1;
	visibility: visible;
}
.view-cart-header .shopping-cart-total {
    float: right;
}
.view-cart-header .shopping-cart-items {
    padding-top: 0px;
}
.view-cart-header .shopping-cart-items li {
	margin-bottom: 10px;
	padding-right: 0;
	display: block;
	border-bottom: 1px solid #e4e4e4;
	padding-bottom: 14px;
	position: relative;
}
.view-cart-header .shopping-cart-items .close-icon{
	position: absolute;
	top: 10px;
	right: 0;
	cursor: pointer;
}
.view-cart-header .shopping-cart-items img {
	float: left;
	width: 70px;
	margin-right: 12px;
	max-width: 100%;
	height: auto;
}
.view-cart-header .shopping-cart-items .item-name {
	display: block;
	padding-top: 10px;
	font-size: 14px;
	padding-right: 20px;
}
.view-cart-header .shopping-cart-items .item-price {
	margin-right: 8px;
	color: #1ec8c8;
	font-size: 13px;
}
.view-cart-header .shopping-cart-items .item-quantity {
	color: #ABB0BE;
	font-size: 13px;
}
.view-cart-header .shopping-cart:after {
	border: 7px solid #fff;
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
    content: "";
    right: 0;
    position: absolute;
    top: 2px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
.view-cart-header .cart-icon {
  color: #515783;
  font-size: 24px;
  margin-right: 7px;
  float: left;
}
.view-cart-header .booking-date li{
	padding: 0;
	display: block;
	width: 100%;
	margin-bottom: 10px;
}
.view-cart-header .booking-date li span{
	float: right;
}
.view-cart-header .booking-total{
	padding-top: 0;
	text-align: center;
}
.view-cart-header .apt-btn{
	font-size: 13px;
	padding: 7px 20px;
}
.product-table .custom-increment button {
    height: 40px;
    width: 40px;
}
.product-table .custom-increment.cart .input-group1 {
    justify-content: unset;
    -webkit-justify-content: unset;
}

/*-----------------
	45. Slider
-----------------------*/

.carousel-fade .carousel-item {
 opacity: 0;
 transition-duration: .6s;
 transition-property: opacity;
}

.carousel-fade  .carousel-item.active,
.carousel-fade  .carousel-item-next.carousel-item-left,
.carousel-fade  .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade  .active.carousel-item-right {
 opacity: 0;
}

.carousel-fade  .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade  .active.carousel-item-prev {
 transform: translateX(0);
 transform: translate3d(0, 0, 0);
}
.carousel-item {
  height: 75vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
	z-index: -1 !important;
}
.carousel-caption {
    position: absolute;
    right: 0;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-bottom: 20px;
    color: #fff;
    top: 110px;
}
.carousel-caption span {
	font-size: 32px;
	font-weight: 700;
	color: rgb(46, 56, 66);
}
.carousel-caption h2 {
	font-size: 54px;
	font-weight: 700;
	color: rgb(30, 92, 146);
}
.carousel-caption p {
	font-size: 18px;
	font-weight: 700;
	color: rgb(46, 56, 66);
}

/*-----------------
	46. Account
-----------------------*/

.account-card {
	border-radius: 10px;
    margin-bottom: 30px;
    font-size: 16px;
    padding: 10px;
    text-align: center;
}
.account-card span {
    font-size: 24px;
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
}
.info-list .title {
    color: #455A64;
	font-size: 14px;
    font-weight: 500;
	margin-bottom: 30px;
}
.info-list .text {
    color: #000;
	font-size: 14px;
    font-weight: 500;
	margin-bottom: 30px;
    display: block;
    overflow: hidden;
}
.account-list {
	margin: 0;
    list-style: none;
    padding: 0;
}
.account-list li {
	padding: 23px;
	border-bottom: 1px solid #EBEBEB;
}
.account-list li h4 {
	margin-bottom: 0;
}
.account-list li span {
	float: right;
    width: 50%;
}
.request_btn {
	min-width: 200px;
	padding: 15px 20px;
	height: 60px;
	border-radius: 8px;
	font-weight: 600;
	font-size: 18px;
}
.acc-title h3 {
	font-weight: 600;
	font-size: 16px;
}
.acc-title a {
	width: 40px;
    height: 40px;
    padding: 8px;
	border-radius: 3px;
}

/*-----------------
	47. About Us
-----------------------*/

.about-section {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 50px 0;
	min-height: 700px;
}
.about-titile {
	font-size: 15px;
	color: #FFFFFF;
	background-color: #1ec8c8;
	border: 1px solid #1ec8c8;
	border-radius: 5px;
	padding: 12px 20px;
	display: inline-block;
}
.about-titile:hover {
	color: #fff;
}
.about-section h3 {
	color: #272B41;
	font-size: 25px;
	font-weight: 600;
}
.about-section p {
	font-size: 16px;
	color: #000;
}
.select-category {
	margin-top: -100px;
	padding: 50px 0;
	padding-top: 0;
}
.category-subox {
	background-color: #FFFFFF;
	box-shadow: 0px 4px 5px 5px #E5F1FB;
	border-radius: 8px;
	padding: 30px;
	text-align: center;
	overflow: hidden;
}
.category-subox h4 {
	color: #15558D;
	font-size: 18px;
	font-weight: 600;
	margin: 0 auto;
	margin-bottom: 20px;
}
.category-subox p {
	font-size: 16px;
	color: #000;
}
.subox-img {
	position: relative;
    padding-top: 90px;
    width: 100%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}
.subox-img .subox-circle {
	width: 160px;
    height: 160px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: inset 0px 4px 5px 5px #e5f1fb;
    position: absolute;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
}
.subox-img .subox-circle img {
	position: absolute;
	left: 50%;
	top: 30%;
	transform: translate(-50%, -30%);
	-webkit-transform: translate(-50%, -30%);
	-ms-transform: translate(-50%, -30%);
}
.category-col {
	position: relative;
}
.category-col:after {
	content: "";
    position: absolute;
    left: 50%;
    bottom: -15px;
    width: 150px;
    height: 26px;
    background-color: #fff;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    border-radius: 10px 10px;
}
.about-page {
	background-color: #fff;
}
.about-page .section-specialities {
	background-color: #e5f1fb;
}
.about-page .section-specialities {
	padding: 50px 0;
}
.section-testimonial {
	padding: 50px 0;
}
.testimonial-slider .slick-slide {
    display: block;
    margin-left: 0;
    padding: 10px;
    width: 300px;
}
.section-testimonial .testimonial-item {
	position: relative;
	margin-bottom: 30px;
}
.testimonial-item  .testimonial-img {
	width: 150px;
	height: 150px;
	margin: 0 auto;
	margin-bottom: 30px;
}
.testimonial-item  .testimonial-img img {
	border-radius: 50%;
}
.testimonial-item  .testimonial-content {
	background: #FFFFFF;
    box-shadow: 0px 4px 5px 5px #e5f1fb;
    border-radius: 8px;
    padding: 20px;
    padding-top: 90px;
    margin-top: -100px;
}
.testimonial-item  .testimonial-content p {
	font-size: 16px;
	color: #000;
}
.testimonial-item  .testimonial-content p.user-name{
	color: #1ec8c8;
	font-weight: 600;
}
.testimonial-slider .slick-dots {
	margin-bottom: 30px;
}
.feature-subox {
	background: #fff;
    box-shadow: 0px 4px 5px 5px #E5F1FB;
    border-radius: 8px;
    padding: 15px 15px 35px;
	margin-bottom: 45px;
}
.feature-subox h4 {
    color: #15558D;
    font-size: 18px;
    margin: 25px 0 0;
	font-weight: 600;
}
.section-featurebox {
    padding: 35px 0 135px;
}
.feature-subox img {
    max-height: 215px;
    width: 100%;
	border-radius: 5px;
}
.feature-col-list {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
}
.feature-col {
    width: 350px;
    margin: 0 15px;
}
.about-page .nav-tabs.nav-tabs-solid > li > a {
	background: #d2e5f5;
}
.about-page .slick-prev,.about-page .slick-next {
	background: #d2e5f5;
}
.about-page .nav-tabs.nav-tabs-solid > li > a.active,
.about-page .nav-tabs.nav-tabs-solid > li > a.active:hover,
.about-page .nav-tabs.nav-tabs-solid > li > a.active:focus,
.about-page .nav-tabs.nav-tabs-solid > li > a:hover,
.about-page .nav-tabs.nav-tabs-solid > li > a:focus {
	background-color: #1ec8c8;
    border-color: #1ec8c8;
    color: #fff;
}

/*-----------------
	48. Contact Us
-----------------------*/
.contact-section {
	padding: 50px 0 60px;
	background: linear-gradient(360deg, rgba(0, 113, 220, 0.06) 40.94%, rgba(229, 241, 251, 0) 100%);
}
.contact-section h3 {
	color: #272B41;
	font-size: 36px;
}
.contact-section p {
	color: #000000;
	max-width: 710px;
	margin: 0 auto;
}
.infor-img {
	position: relative;
	margin: 0 auto;
}
.infor-img .image-circle {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: #0DE0FE;
	box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.08);
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	font-size: 30px;
	margin: 0 auto;
}
.contact-box .infor-details {
	background: #FFFFFF;
	box-shadow: 0px 4px 5px 5px #E5F1FB;
	border-radius: 8px;
	padding: 20px;
	padding-top: 80px;
	margin-top: -50px;
	width: 100%;
}
.contact-box .infor-details label {
	font-size: 16px;
	color: #000000;
	margin-bottom: 20px;
}
.contact-box .infor-details p {
	color: #1ec8c8;
	font-size: 16px;
	font-weight: 600;
}
.contact-form {
	padding: 50px 0 60px;
	background-color: #fff;
}
.contact-form .section-header {
	margin-bottom: 50px;
}
.contact-form form {
	background: #FFFFFF;
    box-shadow: 0px 4px 5px 5px #e5f1fb;
    border-radius: 8px;
    padding: 30px;
	max-width: 1000px;
	width: 100%;
	margin: 0 auto;
}
.contact-form form label span {
	color: #ff0000;
}
.contact-form form input, .contact-form form textarea {
	background: #FFFFFF;
	border: 1px solid #E5F1FB;
	border-radius: 8px;
}
.contact-form form textarea {
	min-height: 200px;
	resize: none;
	padding: 6px 15px;
}
.contact-form .btn {
	font-size: 15px;
    font-weight: 600;
    padding: 11px 30px;
    color: #fff;
    border-radius: 8px;
	border: 1px solid #E5F1FB;
}
.contact-map iframe {
	width: 100%;
	border: none;
	height: 400px;
}

/*-----------------
	49. Responsive
-----------------------*/

@media only screen and (min-width:768px) {
	.avatar-xxl {
		width: 8rem;
		height: 8rem;
	}
	.avatar-xxl .border {
		border-width: 4px !important;
	}
	.avatar-xxl .rounded {
		border-radius: 12px !important;
	}
	.avatar-xxl .avatar-title {
		font-size: 42px;
	}
	.avatar-xxl.avatar-away:before,
	.avatar-xxl.avatar-offline:before,
	.avatar-xxl.avatar-online:before {
		border-width: 4px;
	}
}
@media (min-width: 992px) {
	.container {
		max-width: 1440px;
	}
	.main-nav > li {
		margin-right: 15px;
	}
	.white-font > li > a {
		color: #fff;
	}
	.main-nav > li:last-child {
		margin-right: 0;
	}
	.main-nav li {
		display: block;
		position: relative;
	}
	.main-nav > li > a {
		line-height: 85px;
		padding: 0 !important;
	}
	.header-four .main-nav > li > a {
		line-height: 80px;
		color: #fff;
	}
	.main-nav > li > a > i {
		font-size: 12px;
		margin-right: 12px;
	}
	.main-nav li > ul {
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0 0 3px  rgba(0, 0, 0, 0.1);
		display: block;
		font-size: 14px;
		left: 0;
		margin: 0;
		min-width: 185px;
		opacity: 0;
		padding: 0;
		position: absolute;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		-webkit-transform: translateY(20px);
		-ms-transform: translateY(20px);
		transform: translateY(20px);
		top: 100%;
		visibility: hidden;
		z-index: 1000;
	}
	.main-nav li .submenu::before {
		border: 7px solid #fff;
		border-color: transparent transparent #fff #fff;
		box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
		content: "";
		left: 45px;
		position: absolute;
		top: 2px;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		-webkit-transform: rotate(135deg);
		transform: rotate(135deg);
	}
	.main-nav li.has-submenu:hover > .submenu {
		visibility: visible;
		opacity: 1;
		margin-top: 0;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
	.main-nav .has-submenu.active > a {
		color: #1ec8c8;
	}
	.header-four .main-nav .has-submenu.active > a {
		color: #fff;
    	border-top: 3px solid #E74E84;
	}
	.main-nav .has-submenu.active .submenu li.active > a {
		color: #1ec8c8;
	}
	.main-nav > li .submenu li:first-child a {
		border-top: 0;
	}
	.main-nav > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
		visibility: visible;
		opacity: 1;
		margin-top: -1px;
		margin-right: 0;
	}
	.main-nav > li .submenu > li .submenu {
		left: 100%;
		top: 0;
		margin-top: 10px;
	}
	.main-nav li .submenu a:hover {
		color: #1ec8c8;
		letter-spacing: 0.5px;
		padding-left: 20px;
	}
	.main-nav > .has-submenu > .submenu > .has-submenu > .submenu::before {
		top: 20px;
		margin-left: -35px;
		box-shadow: 1px 1px 0 0 rgba(0,0,0,.15);
		border-color: transparent #fff #fff transparent;
	}
	.header-navbar-rht .dropdown-toggle.show + .dropdown-menu {
		visibility: visible;
		opacity: 1;
		margin-top: 0;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
	.header-navbar-rht li .dropdown-menu {
		border-radius: 5px;
		padding: 0;
		margin: 0;
		min-width: 200px;
		visibility: hidden;
		opacity: 0;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		display: block;
		-webkit-transform: translateY(20px);
		-ms-transform: translateY(20px);
		transform: translateY(20px);
	}
	.header-five .main-nav .has-submenu.active > a {
		color: #28DAF4;
	}
	.header-five .main-nav .has-submenu.active > a:after {
		content: "";
		position: absolute;
		width: 70px;
		background: #28DAF4;
		left: -7px;
		height: 2px;
		z-index: 9;
		bottom: 25px;
	}
}
@media (min-width: 1200px) {
	.aval-features .custom-contain {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}
	.main-nav > li {
		margin-right: 25px;
	}
}
@media (min-width: 1600px) {
	.blog-wrapper .wrap-content:before {
		border-left: 221px solid transparent;
		border-right: 221px solid transparent;
	}
}

@media only screen and (max-width: 1399px) {
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name,
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 180px;
	}
	.row.row-grid {
		margin-left: -8px;
		margin-right: -8px;
	}
	.row.row-grid > div {
		padding-right: 8px;
		padding-left: 8px;
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.invoice-table tr td,
	.invoice-table-two tr td {
		font-size: 16px;
	}
	.invoice-table-two tr th, .invoice-table tr th,
	.invoice-item .invoice-details {
		font-size: 18px;
	}
	.visits span.visit-rsn {
		min-width: 139px;
	}
	.grid-blog.grid-box .blog-title {
		font-size: 21px;
	}
	.drag-drop .card-header h4 {
		font-size: 20px;
	}
	.add-category {
		padding: 20px 15px;
	}
	.calendar-events {
		font-size: 16px;
	}
}
@media only screen and (max-width: 1199px) {
	.header-nav {
		padding-left: 20px;
		padding-right: 20px;
	}
	.container-fluid {
		padding-left: 20px;
		padding-right: 20px;
	}
	.main-nav li a {
		font-size: 13px;
	}
	.header-navbar-rht li {
		padding-right: 15px;
	}
	.header-navbar-rht li a.header-login {
		width: 100px;
	}
	.main-nav > li > a > i {
		margin-right: 6px;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name,
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 150px;
	}
	.row.row-grid > div {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.dash-widget {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.dash-widget-info {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.circle-bar {
		margin: 0 0 15px;
	}
	.header-navbar-rht li.contact-item {
		display: none;
	}
	.map-right {
		height: 450px;
		position: static;
		min-height: inherit;
		margin-bottom: 30px;
		padding-right: 15px;
	}
	.map-listing {
		height: 450px;
	}
	.map-page .content {
		padding-top: 100px;
	}
	.day-slot li span.slot-date, .day-slot li span, .day-slot li small.slot-year {
		font-size: 15px;
	}
	.btn-facebook img, .btn-google img {
		width: 40px;
	}
}
@media only screen and (max-width: 991.98px) {
	.container {
		max-width: 100%;
	}
	.main-nav {
		padding: 0;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
	}
	.main-nav ul {
		background-color: #066bca;
		display: none;
		list-style: none;
		margin: 0;
		padding-left: 0;
	}
	.main-nav > li {
		border-bottom: 1px solid #0266c4;
		margin-left: 0;
	}
	.main-nav li + li {
		margin-left: 0;
	}
	.main-nav > li > a {
		line-height: 1.5;
		padding: 15px 20px !important;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
	}
	.main-nav > li.active > a {
		color: #FFF;
	}
	.main-nav > li > a > i {
		float: right;
		margin-top: 5px;
	}
	.main-nav > li .submenu li a {
		border-top: 0;
		color: #fff;
		padding: 10px 15px 10px 35px;
	}
	.main-nav > li .submenu ul li a {
		padding: 10px 15px 10px 45px;
	}
	.main-nav > li .submenu > li.has-submenu > a::after {
		content: "\f078";
	}
	.main-nav .has-submenu.active > a {
		color: #cde7ff;
	}
	.main-nav .has-submenu.active .submenu li.active > a {
		color: #cde7ff;
	}
	.min-header .header-nav {
		padding: 0 15px;
	}
	.section-header h2 {
		font-size: 32px;
	}
	.login-left {
		display: none;
	}
	.patient-dashboard-top h5 {
		font-size: 18px;
	}
	.main-menu-wrapper {
		order: 3;
		width: 260px;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 1060;
		transform: translateX(-260px);
		transition: all 0.4s;
		background-color: #1ec8c8;
	}
	.menu-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.navbar-header {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
	#mobile_btn {
		display: inline-block;
	}
	.footer-widget {
		margin-bottom: 30px;
	}
	.chat-cont-left, .chat-cont-right {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
		width: 100%;
	}
	.chat-cont-right {
		position: absolute;
		right: -100%;
		top: 0;
		opacity: 0;
		visibility: hidden;
	}
	.chat-cont-right .chat-header {
		justify-content: start;
		-webkit-justify-content: start;
		-ms-flex-pack: start;
	}
	.chat-cont-right .chat-header .back-user-list {
		display: block;
	}
	.chat-cont-right .chat-header .chat-options {
		margin-left: auto;
	}
	.chat-window.chat-slide .chat-cont-left {
		left: -115%;
	}
	.chat-window.chat-slide .chat-cont-right {
		right: 0;
		opacity: 1;
		visibility: visible;
	}
	.pro-content .title {
		font-size: 16px;
	}
	.profile-widget .verified {
		margin-left: 0px;
	}
	.session-time h3 {
		font-size: 20px;
	}
	.session-time h4 {
		margin-right: 12px;
	}
	.dct-appoinment .user-tabs .nav-tabs > li > a, .user-tabs .nav-tabs > li > a {
		width: 200px;
		font-size: 14px;
		padding: 17px 17px;
		margin-right: 10px;
	}
	.success-cont img {
		margin-bottom: 25px;
	}
	.success-cont h3 {
		font-size: 26px;
	}
	.success-cont p {
		font-size: 22px;
	}
	.visits span.visit-rsn {
		padding: 18px 5px;
		font-size: 16px;
	}
	.section-specialities .nav-tabs {
		margin-bottom: 40px;
	}
	.section-specialities .tab-content {
		padding-top: 30px;
	}
	.section-specialities .nav-tabs.nav-tabs-solid > li {
		margin-bottom: 10px;
	}
	.slick-prev, .slick-next, .pharmacy-home-slider .swiper-button-prev,
	.pharmacy-home-slider .swiper-button-next {
		top: -40px;
	}
	.section-header {
		margin-bottom: 40px;
	}
	.day-slot li.left-arrow {
		left: -10px;
	}
	.container {
		max-width: 100%;
	}
	.dash-widget-info {
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.appointments .appointment-action {
		margin-top: 10px;
	}
	.appointments .appointment-list {
		display: block;
	}
	.day-slot li span.slot-date, .day-slot li span, .day-slot li small.slot-year {
		font-size: 13px;
	}
	.date-booking h4 {
		font-size: 20px;
	}
	.banner-wrapper {
		max-width: 100%;
		padding: 60px 0;
	}
	.banner-wrapper .banner-header h1 {
		font-size: 32px;
		line-height: 50px;
	}
	.dct-border-rht {
		border-bottom: 1px solid #f0f0f0;
		border-right: 0;
		margin-bottom: 20px;
		padding-bottom: 15px;
	}
	.dr-img {
		display: none;
	}
	.doctor-search {
		padding: 30px 0;
	}
	.doctor-search-section {
		padding-top: 85px;
	}
	.shapes {
		display: none;
	}
	.search-box-3 {
		position: relative;
	}
	.header-top {
		display: none;
	}
	.banner-img {
		display: none;
	}
	.banner-wrapper .banner-header {
		text-align: center;
	}
	.search-box {
		max-width: 650px;
		margin: 0 auto;
		text-align: center;
	}
	.search-box .search-location {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		width: 50%;
	}
	.card-label > label {
		font-size: 12px;
	}
	.newsletter .mail-box {
		-ms-flex: 0 0 350px;
		flex: 0 0 300px;
		width: 300px;
	}
	.footer .footer-top {
		padding: 50px 0 20px;
	}
	.time-slot li .timing.selected::before {
		display: none;
	}
	.review-listing .recommend-btn {
		float: none;
	}
	.dash-widget {
		flex-direction: unset;
		text-align: left;
	}
	.circle-bar {
		margin: 0 15px 0 0;
	}
	.call-wrapper {
		height: calc(100vh - 140px);
	}
	.sidebar-overlay.opened {
		display: block;
	}
	.about-content {
		margin-bottom: 30px;
		text-align: center;
	}
	.slide-image{
		padding-left: 0;
		padding-right: 0;
	}
	.custom-short-by > div{
		margin-right: 0;
	}
	.product-description .doctor-img1{
		width: 100%;
		margin-right: 0;
		margin-bottom: 30px;
	}
	.product-description .doc-info-cont{
		width: 100%;
	}
	.product-description .doc-info-left{
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
	}
	.clinics-section {
		padding: 40px 0 25px;
	}
	.specialities-section {
		padding: 60px 0 35px;
	}
	.our-doctors-section {
		padding: 40px 0;
	}
	.clinic-features-section {
		padding: 40px 0;
	}
	.our-blog-section {
		padding: 40px 0;
	}
	.section-search-3 .banner-info {
		padding: 60px 0 130px;
	}
	.section-search-3 .doctor-search-form .banner-btn {
		padding: 18px 30px;
	}
	.pop-box .body-section h3 {
		font-size: 20px;
	}
	.section-header-three h2 {
		font-size: 28px;
	}
	.doc-background, .pat-background {
		min-height: 250px;
	}
	.doctor-divison h3 {
		font-size: 20px;
	}
	.doctor-divison p {
		font-size: 12px;
	}
	.doctors-body .inner-section > span {
		font-size: 12px;
	}
	.book-best-doctors .doctors-body .average-ratings {
		padding: 1px 8px;
		font-size: 10px;
		margin-left: 7px;
	}
	.book-best-doctors .doctors-body h4 {
		font-size: 18px;
	}
	.book-best-doctors .amt-txt {
		font-size: 16px;
	}
	.latest-blog .blog-wrap .blog-wrap-body h3 {
		font-size: 18px;
	}
	.header-four .nav-item a.header-login {
		margin: 15px 20px;
	}
	.header-four .nav-item a.header-login i {
		float: unset;
	}
	.doc-form {
		position: absolute;
		top: 85px;
		left: 20px;
    	padding: 37px 0;
		width: 600px;
	}
	.dot-slider .slick-slide {
		height: 375px;
	}
	.dot-slider .profile-widget {
		margin-bottom: 0;
	}
	.app-form h2 {
		font-size: 30px;
	}
	.app-form .doctor-search-form input {
		min-height: 50px;
	}
	.app-form .banner-btn {
		padding: 10px 30px;
		min-height: 50px;
		justify-content: center;
		align-items: center;
		display: flex;
	}
	.category-sec {
		padding: 50px 0;
		margin-top: 0;
	}
	.set-category {
		padding: 10px;
	}
	.set-category .info-holder h3 {
		font-size: 20px;
	}
	.set-category .info-holder p {
		font-size: 11px;
	}
	.set-category .img-holder {
		margin-right: 10px;
	}
	.set-category .img-holder a {
		width: 50px;
		height: 50px;
	}
	.set-category .img-holder a img {
		width: 20px;
	}
	.select-box {
		padding: 20px;
	}
	.select-box p {
		font-size: 14px;
		margin-bottom: 20px;
	}
	.select-box .book-now {
		padding: 8px 20px;
	}
	.select-box .image-holder {
		margin-right: 15px;
	}
	.aval-features .custom-contain {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}
	.aval-features .feature {
		align-items: center;
		justify-content: center;
		display: flex;
		padding: 40px;
	}
	.aval-features .feature .info-box {
		padding: 20px;
	}
	.aval-features .feature .info-box h2 {
		font-size: 30px;
	}
	.footer.footer-four .footer-top {
		padding: 30px 0 0;
	}
	.aval-features .custom-contain {
		max-width: 100%;
	}
	.header-trans.header-five .main-menu-wrapper {
		margin-left: auto;
	}
	.home-search-section .banner-header h2 {
		font-size: 34px;
	}
	.facility-section .operat-img {
		width: 100%;
	}
	.facility-section .visit-doctor {
		padding: 15px;
		margin-bottom: 20px;
	}
	.facility-section .visit-doctor .inner-details img {
		bottom: -13px;
	}
	.visit-doctor .inner-details .info h1 {
		font-size: 20px;
	}
	.visit-doctor .inner-details .info p {
		font-size: 12px;
		max-width: 100%;
	}
	.visit-doctor .inner-details .count h1 {
		width: 55px;
		height: 55px;
		font-size: 20px;
	}
	.clinic-wrap {
		height: 200px;
	}
	.heading-wrapper h1 {
		font-size: 30px;
		max-width: 100%;
	}
	.clinic-wrap .wrapper-overlay img {
		margin-bottom: 20px;
	}
	.doc-booking .book-slider .slick-prev, .doc-booking .book-slider .slick-next {
		top: -100px;
	}
	.blog-wrapper .wrap-content .date-cart {
		font-size: 26px;
	}
	.home-search-section .doctor-search {
		padding: 40px 0;
	}
	.facility-section {
		padding: 40px 0 20px;
	}
	.clinic-speciality {
		padding: 40px 0 10px;
	}
	.browse-section.brower-category {
		padding: 40px 0 20px;
	}
	.doctor-divison.provider-select .doc-background, .doctor-divison.provider-select .pat-background {
		padding: 40px 30px;
	}
	.doc-booking {
		padding: 40px 0;
	}
	.blogs-section.blog-container {
		padding: 40px 0 10px;
	}
	.footer.footer-five .footer-top {
		padding: 40px 0 10px;
	}
	.heading-wrapper .nav-arrow {
		display: none;
	}
	.heading-wrapper {
		margin-bottom: 30px;
		text-align: center;
	}
	.blog-wrapper .wrap-content:before {
		border-left: 178px solid transparent;
		border-right: 170px solid transparent;
	}
	.divider .carousel-inner {
		height: 430px;
	}
	.fc-button {
		padding: 10px 9px !important;
	}
	.fc-toolbar h2 {
		font-size: 20px;
	}
	.about-section {
		min-height: unset;
		background: linear-gradient(360deg, rgba(0, 113, 220, 0.06) 40.94%, rgba(229, 241, 251, 0) 100%);
	}
	.select-category {
		padding: 50px 0 20px;
		margin: 0;
	}
	.contact-section h3 {
		font-size: 36px;
	}
	.contact-section p {
		font-size: 18px;
	}
	.contact-box .infor-details p {
		font-size: 14px;
	}
	.category-subox {
		margin-bottom: 30px;
	}
	.category-col:after {
		bottom: 15px;
	}
}
@media only screen and (max-width: 849.98px) {
	.row.row-grid > div {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media only screen and (max-width: 767.98px) {
	body {
		font-size: 0.875rem;
	}
	h1, .h1 {
		font-size: 2rem;
	}
	h2, .h2 {
		font-size: 1.75rem;
	}
	h3, .h3 {
		font-size: 1.375rem;
	}
	h4, .h4 {
		font-size: 1rem;
	}
	h5, .h5 {
		font-size: 0.875rem;
	}
	h6, .h6 {
		font-size: 0.75rem;
	}
	.content {
		padding: 15px 0 0;
	}
	.account-page .content {
		padding: 15px 0;
	}
	.container-fluid {
		padding-left: 15px;
		padding-right: 15px;
	}
	.carousel-item {
		height: auto;
		min-height: auto;
	}
	.card {
		margin-bottom: 20px;
	}
	.profile-sidebar {
		margin-bottom: 20px;
	}
	.appointment-tab {
		margin-bottom: 20px;
	}
	.features-slider .slick-dots, .features-img img {
		text-align: center;
		margin: 0 auto;
	}
	.doctor-slider {
		margin-top: 25px;
	}
	.breadcrumb-bar {
		height: auto;
	}
	.section-header {
		margin-bottom: 30px;
	}
	.login-right {
		margin: 0 15px;
		padding: 15px;
	}
	.section-features, .newsletter-detail {
		padding: 50px 0;
	}
	.section-doctor {
		padding: 50px 0 20px;
	}
	.home-tile-section {
		padding: 50px 0 30px;
	}
	.section-blogs, .section-specialities {
		padding: 0 0 50px;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name,
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 250px;
	}
	.chat-window .chat-cont-right .chat-header .media .media-body {
		display: none;
	}
	.divider .carousel-inner {
		height: 100%;
	}
	.divider .form-group {
		margin-bottom: 10px;
	}
	.banner-wrapper .banner-header h1 {
		font-size: 28px;
	}
	.pat-widget-profile .profile-info-widget {
		flex-direction: column;
		text-align: center;
	}
	.widget-profile.pat-widget-profile .profile-info-widget .booking-doc-img img {
		margin-bottom: 15px;
	}
	.patient-details h5 {
		font-size: 13px;
	}
	.clinic-group .list-group-item {
		font-size: 16px;
	}
	.sub-heading {
		margin-bottom: 20px;
	}
	.footer .footer-top {
		padding: 40px 0 20px;
	}
	.footer-widget {
		margin-bottom: 20px;
	}
	.footer .footer-bottom .copyright {
		padding: 20px 0;
	}
	.section-header h2 {
		font-size: 28px;
	}
	.section-header .sub-title {
		font-size: 0.875rem;
	}
	.speicality-item p {
		font-size: 0.875rem;
	}
	.section-header p {
		font-size: 0.9375rem;
	}
	.doctor-widget .doctor-img1 {
		margin: 0 0 15px 0;
	}
	.patient-info {
		text-align: center;
	}
	.appointments-detail .profile-info-widget .booking-doc-img {
		margin-right: 0;
	}
	.section-newsletter {
		text-align: center;
	}
	.newsletter {
		justify-content: center;
	}
	.footer-title {
		font-size: 1.125rem;
	}
	.search-box .search-item {
		flex-direction: column;
	}
	.search-box {
		margin: 0 auto;
	}
	.search-box form {
		-ms-flex-direction: column;
		flex-direction: column;
		word-wrap: break-word;
		background-clip: border-box;
	}
	.search-box .search-location {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		width: 100%;
	}
	.search-box .search-info {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		width: 100%;
	}
	.search-box .search-btn {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		min-height: 46px;
		width: 100%;
	}
	.search-box .search-btn span {
		display: inline-block;
		margin-left: 5px;
		text-transform: uppercase;
	}
	.section-search {
		background: #f9f9f9;
	}
	.day-slot li span {
		font-size: 16px;
		text-transform: unset;
	}
	.time-slot li .timing span {
		display: block;
	}
	.submit-section.proceed-btn {
		margin-bottom: 20px;
	}
	.day-slot li small.slot-year {
		display: none;
	}
	.success-cont h3 {
		font-size: 22px;
	}
	.success-cont p {
		font-size: 20px;
	}
	.view-inv-btn {
		font-size: 14px;
		padding: 10px 30px;
	}
	.invoice-info.invoice-info2 {
		text-align: left;
	}
	.invoice-item .invoice-details {
		text-align: left;
	}
	.specialities-slider .slick-slide {
		margin-right: 15px;
	}
	.about-content a {
		padding: 12px 20px;
	}
	.submit-section .submit-btn {
		padding: 10px 20px;
		font-size: 15px;
	}
	.policy-menu {
		margin-top: 10px;
		text-align: left;
	}
	.booking-doc-info .booking-doc-img {
		width: 75px;
	}
	.booking-doc-info .booking-doc-img img {
		height: 75px;
		width: 75px;
	}
	.search-box .form-text {
		margin-bottom: 10px;
	}
	.btn.btn-danger.trash {
		margin-bottom: 20px;
	}
	.nav-tabs.nav-tabs-bottom > li > a.active, .nav-tabs.nav-tabs-bottom > li > a.active:hover, .nav-tabs.nav-tabs-bottom > li > a.active:focus {
		background-color: #f5f5f5;
	}
	.nav-tabs.nav-justified > li > a.active,
	.nav-tabs.nav-justified > li > a.active:hover,
	.nav-tabs.nav-justified > li > a.active:focus {
		border-color: transparent transparent transparent #1ec8c8;
		border-left-width: 2px;
	}
	.nav-tabs {
		border-bottom: 0;
		position: relative;
		background-color: #fff;
		padding: 5px 0;
		border-radius: 3px;
	}
	.nav-tabs .nav-item {
		margin-bottom: 0;
	}
	.nav-tabs > li > a {
		border-width: 2px;
		border-left-color: transparent;
	}
	.nav-tabs .nav-link {
		border-width: 2px;
	}
	.nav-tabs > li > a:hover,
	.nav-tabs > li > a:focus {
		background-color: #fafafa;
	}
	.nav-tabs > li.open:not(.active) > a,
	.nav-tabs > li.open:not(.active) > a:hover,
	.nav-tabs > li.open:not(.active) > a:focus {
		background-color: #fafafa;
	}
	.nav-tabs.nav-tabs-solid {
		padding: 5px;
	}
	.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
		border-radius: 5px;
	}
	.nav-tabs-justified {
		border-bottom: 1px solid #ddd;
	}
	.nav-tabs-justified > li > a.active,
	.nav-tabs-justified > li > a.active:hover,
	.nav-tabs-justified > li > a.active:focus {
		border-width: 0 0 0 2px;
		border-left-color: #1ec8c8;
	}
	.section-specialities .nav-tabs {
		margin-bottom: 10px;
		padding: 0;
	}
	.review-listing > ul li .comment .comment-body .meta-data span.comment-date {
		margin-bottom: 5px;
	}
	.review-listing > ul li .comment .comment-body .meta-data .review-count {
		position: unset;
	}
	.my-video ul li {
		width: 50px;
	}
	.call-users ul li {
		width: 50px;
	}
	.call-mute {
		font-size: 20px;
		height: 50px;
		line-height: 50px;
		width: 50px;
	}
	.call-duration {
		font-size: 24px;
	}
	.voice-call-avatar .call-avatar {
		height: 100px;
		width: 100px;
	}
	.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active,
	.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover,
	.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
		color: #FFF;
	}
	.doctor-widget {
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}
	.doc-info-right {
		margin-left: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.doc-info-left {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.clinic-services {
		display: none;
	}
	.doctor-img {
		margin: 0 auto 20px;
	}
	.doctor-action {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	.row.row-grid > div {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.call-icons .call-items .call-item a {
		width: 50px;
		height: 50px;
		line-height: 50px;
	}
	.end-call a {
		padding: 13px 38px;
	}
	.breadcrumb-bar .breadcrumb-title {
		font-size: 18px;
	}
	.dash-widget h6 {
		font-size: 15px;
	}
	.dash-widget h3 {
		font-size: 20px;
	}
	.dash-widget p {
		font-size: 13px;
	}
	.doctor-widget .doc-name {
		font-size: 18px;
	}
	.exp-title, .booking-total ul li span, .booking-total ul li .total-cost {
		font-size: 14px;
	}
	.invoice-item .customer-text {
		font-size: 16px;
	}
	.call-wrapper {
		height: calc(100vh - 115px);
	}
	.appointment-tab .nav-tabs {
		padding: 1.5rem;
	}
	.submit-btn-bottom {
		margin-bottom: 20px;
	}
	.service-list ul li {
		width: 50%;
	}
	.grid-blog .blog-title {
		min-height: inherit;
	}
	.blog-title {
		font-size: 20px;
	}
	.blog-view .blog-title {
		font-size: 22px;
	}
	.full-slide-home .slick-prev{
		left: 15px;
	}
	.full-slide-home .slick-next{
		right: 15px;
	}
	.search-box1 form p{
		margin-bottom: 15px !important;
	}
	.search-box1 .search-location1{
		margin-right: 0;
		margin-bottom: 15px;
	}
	.product-description .doctor-widget{
		text-align: left;
	}
	.pharmacy-home-slider .swiper-button-prev,
	.pharmacy-home-slider .swiper-button-next {
		display: none;
	}
	.banner-header h2 {
		font-size: 26px;
		line-height: 40px;
	}
	.section-heading {
		margin-bottom: 15px;
	}
	.owl-theme .owl-dots, .owl-nav button {
		margin-top: 10px;
	}
	.clinics-section {
		padding: 30px 0 10px;
	}
	.specialities-section {
		padding: 40px 0 15px;
	}
	.our-doctors-section {
		padding: 30px 0;
	}
	.clinic-features-section {
		padding: 30px 0;
	}
	.our-blog-section {
		padding: 30px 0;
	}
	.section-heading h2 {
		font-size: 20px;
	}
	.slide-nav-1 button.owl-next,
	.slide-nav-1 button.owl-prev,
	.slide-nav-2 button.owl-next,
	.slide-nav-2 button.owl-prev,
	.slide-nav-3 button.owl-next,
	.slide-nav-3 button.owl-prev,
	.slide-nav-4 button.owl-next,
	.slide-nav-4 button.owl-prev {
		width: 28px;
		height: 28px;
	}
	.section-search-3 .banner-info {
		padding: 20px 0 100px;
	}
	.pop-box .body-section h3 {
		font-size: 18px;
	}
	.section-header-three h2 {
		font-size: 24px;
	}
	.section-header-three p {
		font-size: 14px;
	}
	.section-header-three {
		margin-bottom: 40px;
	}
	.doctor-category {
		padding: 40px 0 10px;
		margin-top: -110px;
	}
	.clinic-specialities {
		padding: 40px 0;
	}
	.browse-section {
		padding: 40px 0;
	}
	.doc-background, .pat-background {
		padding: 20px 15px;
		min-height: 200px;
	}
	.doctor-divison > .d-flex {
		display: block !important;
	}
	.doctor-divison h3 {
		font-size: 16px;
	}
	.doctor-divison p {
		font-size: 11px;
	}
	.book-section {
		padding: 40px 0;
	}
	.latest-blog {
		padding: 40px 0 10px;
	}
	.footer.custom-footer .footer-top {
		padding: 40px 0 10px;
	}
	.app-form h2 {
		font-size: 20px;
	}
	.doc-form {
		padding: 10px;
		left: 0;
	}
	.app-form {
		display: none;
	}
	.dot-slider .slick-slide {
		height: 100%;
	}
	.dot-slider .profile-widget {
		margin: 0;
	}
	.category-sec {
		padding: 30px 0 0;
	}
	.set-category {
		margin-bottom: 30px;
	}
	.clinic-section {
		padding: 30px 0 0;
	}
	.sec-header h2 {
		font-size: 24px;
	}
	.browse-speciality {
		padding: 30px 0 15px;
	}
	.select-box {
		margin-bottom: 30px;
		border-radius: 60px;
	}
	.select-box::before {
		border-radius: 55px;
	}
	.select-box.theme {
		border-radius: 60px;
	}
	.select-box.theme::before {
		border-radius: 55px;
	}
	.set-category h1 {
		bottom: 0;
	}
	.patient-select {
		padding: 30px 0 0;
	}
	.select-box h2 {
		font-size: 24px;
	}
	.select-box p {
		font-size: 12px;
	}
	.select-box .book-now {
		font-size: 13px;
	}
	.book-doctor {
		padding: 30px 0;
	}
	.book-slider .provider-info h3 {
		font-size: 18px;
	}
	.book-slider .provider-info h5 {
		font-size: 12px;
	}
	.book-slider .provider-info .side-arrow {
		width: 30px;
		height: 30px;
	}
	.book-slider .available-info li{
		font-size: 13px;
	}
	.book-slider .content-info .view-btn, .book-slider .content-info .view-btn:hover, .book-slider .content-info .book-btn, .book-slider .content-info .book-btn:hover {
		font-size: 12px;
	}
	.book-doctor .row.mt-4 {
		margin-top: 0 !important;
	}
	.book-doctor .view-btn, .blogs-section .view-btn {
		font-size: 14px;
	}
	.aval-features .feature {
		padding: 30px;
	}
	.aval-features .feature .info-box h2 {
		font-size: 24px;
		margin-bottom: 10px;
	}
	.aval-features .feature .info-box p {
		margin-bottom: 15px;
		font-size: 13px;
	}
	.aval-features .feature .info-box a {
		font-size: 12px;
		width: 100px;
	}
	.aval-features .col-md-6.ps-0 {
		padding-left: 15px !important;
	}
	.aval-slider .slick-list {
		padding: 30px 0;
	}
	.blogs-section {
		padding: 30px 0;
	}
	.blog-wrapper .wrap-content .date-cart {
		font-size: 22px;
		width: 60px;
    	height: 55px;
		padding: 5px;
	}
	.blog-wrapper .wrap-content .date-cart span {
		font-size: 10px;
	}
	.blog-wrapper .wrap-content-body h3 {
		font-size: 17px;
	}
	.home-search-section .banner-header h2 {
		font-size: 30px;
	}
	.heading-wrapper h1 {
		font-size: 24px;
	}
	.clinic-wrap .wrapper-overlay h3 {
		font-size: 20px;
		text-align: center;
	}
	.facility-section .operat-img {
		width: 100%;
		margin-bottom: 20px;
	}
	.visit-doctor .inner-details .info h1, .visit-doctor .inner-details .count h1 {
		font-size: 18px;
	}
	.clinic-wrap .wrapper-overlay img {
		margin: 0 auto 0;
	}
	.clinic-wrap .wrapper-overlay p {
		text-align: center;
	}
	.doc-booking .book-slider .slick-list {
		padding-top: 50px;
	}
	.doc-booking .book-slider .slick-prev, .doc-booking .book-slider .slick-next {
		top: 0;
	}
	.book-slider .provider-info h3 a {
		font-size: 20px;
	}
	.blog-container .blog-wrapper .wrap-content-body h3 a {
		font-size: 17px;
	}
	.blog-wrapper .wrap-content:before {
		border-left: 170px solid transparent;
		border-right: 160px solid transparent;
	}
	.pop-box {
		margin-bottom: 30px;
	}
	.date-booking .bookingrange {
		margin-top: 15px;
	}
	.schedule-calendar-col .form-control {
		margin-top: 5px;
	}
	.invoice-table-two tr th, .invoice-table tr th, .invoice-item .invoice-details {
		font-size: 16px;
		line-height: 29px;
	}
	.other-info h4 {
		font-size: 18px;
	}
	.other-info p {
		font-size: 16px;
	}
	.btn-facebook img, .btn-google img {
		width: 35px;
	}
	.load-more a {
		width: auto;
		height: auto;
		padding: 15px 40px;
		font-size: 15px;
	}
	.grid-blog.grid-box .blog-title {
		font-size: 20px;
		line-height: 25px;
	}
	.blog-content p, .grid-blog.grid-box .blog-content p {
		font-size: 15px;
	}
	.read-more {
		font-size: 14px;
	}
	.voice-call-avatar .username {
		font-size: 22px;
	}
	.about-section p, .testimonial-item .testimonial-content p {
		font-size: 14px;
	}
	.about-section {
		min-height: unset;
	}
	.about-titile {
		font-size: 13px;
	}
	.about-section h3 {
		font-size: 20px;
	}
	.category-subox p {
		font-size: 14px;
	}
	.contact-box {
		margin-bottom: 30px;
	}
	.contact-section {
		padding-bottom: 20px;
	}
	.category-subox h4 {
		font-size: 16px;
	}
	.contact-box .infor-details {
		padding-top: 65px;
	}
	.category-subox {
		margin-bottom: 30px;
	}
	.contact-section h3 {
		font-size: 1.5rem;
	}
	.contact-section p {
		font-size: 16px;
	}
}

@media only screen and (max-width: 575.98px) {
	body {
		font-size: 0.8125rem;
	}
	h1, .h1 {
		font-size: 1.75rem;
	}
	h2, .h2 {
		font-size: 1.5rem;
	}
	h3, .h3 {
		font-size: 1.25rem;
	}
	h4, .h4 {
		font-size: 1rem;
	}
	h5, .h5 {
		font-size: 0.875rem;
	}
	h6, .h6 {
		font-size: 0.75rem;
	}
	.card {
		margin-bottom: 0.9375rem;
	}
	.card-body {
		padding: .25rem;
	}
	.card-header {
		padding: .75rem .25rem;
	}
	.card-footer {
		padding: .75rem .25rem;
	}
	.header-nav {
		padding-left: 15px;
		padding-right: 15px;
	}
	.header-navbar-rht {
		display: none;
	}
	.main-nav li.login-link {
		display: block;
	}
	.navbar-header {
		width: 100%;
	}
	#mobile_btn {
		left: 0;
		margin-right: 0;
		padding: 0 15px;
		position: absolute;
		z-index: 99;
	}
	.navbar-brand.logo {
		width: 100%;
		text-align: center;
		margin-right: 0;
	}
	.navbar-brand.logo img {
		height: 40px;
	}
	.search-box form {
		display: block;
	}
	.search-box .search-location {
		width: 100%;
		-ms-flex: none;
		flex: none;
	}
	.search-box .search-info {
		width: 100%;
		-ms-flex: none;
		flex: none;
	}
	.banner-wrapper .banner-header h1 {
		font-size: 26px;
		line-height: 40px;
	}
	.slick-next {
		right: 0px;
	}
	.section-specialities .nav-tabs {
		margin-bottom: 25px;
	}
	.section-header h2 {
		font-size: 1.5rem;
		line-height: 30px;
	}
	.section-header .sub-title {
		font-size: 0.875rem;
	}
	.voice-call-avatar .username {
		font-size: 20px;
	}
	.success-cont img {
		width: 55px;
	}
	.speicality-item p {
		font-size: 0.875rem;
	}
	.section-header p {
		font-size: 0.9375rem;
	}
	.dashboard-menu > ul > li > a {
		font-size: 16px;
	}
	.login-right .form-control {
		min-height: 50px;
	}
	.duration-slot label {
		white-space: unset;
	}
	.success-cont p {
		font-size: 20px;
	}
	.appointment-tab .nav-tabs.nav-tabs-solid > li > a,
	.card-table .card-body .table > thead > tr > th {
		font-size: 15px;
	}
	.change-avatar.pro-setting {
		flex-direction: column;
		align-items: start;
	}
	.change-avatar.pro-setting .custom-file {
		margin-top: 20px;
	}
	.info-list .text, .info-list .title {
		font-size: 13px;
	}
	.footer-title {
		font-size: 1.125rem;
	}
	.product-custom span.price {
		font-size: 20px;
	}
	.doc-slot-list {
		font-size: 13px;
	}
	.booking-schedule.schedule-widget {
		overflow-x: auto;
	}
	.booking-schedule.schedule-widget > div {
		width: 730px;
	}
	.booking-schedule .day-slot ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.booking-schedule .day-slot li {
		-ms-flex: 0 0 100px;
		flex: 0 0 100px;
		width: 100px;
	}
	.booking-schedule .time-slot ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.booking-schedule .time-slot li {
		-ms-flex: 0 0 100px;
		flex: 0 0 100px;
		width: 100px;
	}
	.booking-schedule .time-slot li .timing span {
		display: inline-block;
	}
	.booking-schedule .day-slot li.right-arrow {
		right: -20px;
	}
	.booking-date li, .booking-fee li, .booking-date li span, .booking-fee li span {
		font-size: 16px;
	}
	.booking-info h4, .booking-info .rating {
		margin-bottom: 8px;
	}
	.info-widget .card-header h4, .payment-widget h4 {
		font-size: 20px;
	}
	.booking-doc-info .booking-doc-img {
		width: 70px;
	}
	.booking-doc-info .booking-doc-img img {
		height: 70px;
		width: 70px;
	}
	.call-icons {
		text-align: center;
	}
	.session-time {
		flex-direction: column;
		justify-content: start;
		align-items: baseline;
	}
	.schedule-calendar-col .form-control {
		width: 100%;
		margin-top: 10px;
	}
	.session-tim .btn {
		font-size: 16px;
		min-width: 135px;
		height: 50px;
		padding: 12px 20px;
	}
	.voice-call-avatar .call-avatar {
		height: 80px;
		width: 80px;
		padding: 5px;
	}
	.call-duration {
		display: block;
		margin-top: 0;
		margin-bottom: 10px;
		position: inherit;
	}
	.end-call {
		margin-top: 10px;
		position: inherit;
	}
	.user-tabs .med-records {
		min-width: 110px;
	}
	.pagination-lg .page-link {
		font-size: 1rem;
		padding: 0.5rem 0.625rem;
	}
	.row.row-grid > div {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.calendar-events {
		font-size: 15px;
	}
	.calendar-events {
		padding: 15px 15px;
	}
	.add-category {
		padding: 14px 15px;
	}
	.fc-toolbar h2 {
		font-size: 18px;
	}
	.fc th.fc-widget-header {
		font-size: 10px;
	}
	.social-share {
		margin-top: 20px;
	}
	.social-share > li > a {
		height: 35px;
		line-height: 35px;
		width: 35px;
	}
	.edit-link {
		font-size: 14px;
		margin-top: 2px;
	}
	.invoice-content {
		padding: 1.25rem;
	}
	.change-avatar .profile-img img {
		height: 80px;
		width: 80px;
	}
	.submit-btn-bottom {
		margin-bottom: 0.9375rem;
	}
	.service-list ul li {
		width: 100%;
	}
	.slick-prev, .slick-next, .pharmacy-home-slider .swiper-button-prev, .pharmacy-home-slider .swiper-button-next {
		top: -50px;
	}
	.doctor-slider .slick-slide, .doctor-slider1 .slick-slide {
		padding: 0;
	}
	.newsletter {
		flex-direction: column;
	}
	.newsletter .mail-box {
		width: 100%;
		flex: auto;
	}
	.subscribe-btn {
		height: 50px;
		width: 100%;
	}
	.newsletter .form-control {
		margin-bottom: 20px;
	}
	.blog {
		padding: 1.25rem;
	}
	.blog-title {
		font-size: 18px;
	}
	.blog-view .blog-title {
		font-size: 20px;
	}
	.blog-info i {
		font-size: 14px;
	}
	.post-left ul li {
		margin-right: 10px;
	}
	.post-left ul li:last-child {
		margin-right: 10px;
	}
	.about-author-img {
		height: 60px;
		width: 60px;
	}
	.author-details {
		margin-left: 80px;
	}
	.blog-comments .comments-list li img.avatar {
		border-radius: 40px;
		height: 40px;
		width: 40px;
	}
	.blog-comments .comments-list li {
		padding: 10px 0 0 50px;
	}
	.read-more {
		font-size: 14px;
	}
	.nav-tabs.nav-tabs-solid > li > a {
		padding: 13px 27px;
	}
	.footer .footer-menu ul li a, .footer-contact-info {
		font-size: 14px;
	}
	.footer .footer-widget .footer-about-content p, .policy-menu, .footer .footer-bottom .copyright-text p {
		font-size: 15px;
	}
	.visits span.visit-rsn {
		min-width: 100px;
		font-size: 13px;
	}
	.visits span.visit-rsn:before {
		right: 2px;
	}
	.login-btn {
		padding: 12px 30px;
		font-size: 17px;
	}
	.btn-facebook img, .btn-google img {
		margin-right: 10px;
		width: 25px;
	}
	.btn-add {
		height: 50px;
		padding: 0 30px;
		min-width: auto;
		line-height: 50px;
		font-size: 15px;
	}
}

@media only screen and (max-width:479px) {
	.specialities-slider .slick-slide {
		margin-right: 10px;
	}
	.speicality-img {
		width: 120px;
		height: 120px;
	}
	.speicality-img img {
		width: 50px;
	}
	.clinic-booking a.view-pro-btn {
		width: 100%;
		margin-bottom: 15px;
	}
	.clinic-booking a.apt-btn {
		width: 100%;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name,
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 120px;
	}
	.section-specialities {
		background-color: #fff;
	}
	.login-header h3 a {
		float: none;
		font-size: 15px;
		margin-top: 10px;
		text-align: center;
		display: block;
	}
	.login-header h3 {
		text-align: center;
	}
	.appointments .appointment-list {
		text-align: center;
	}
	.appointment-list .profile-info-widget {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.appointment-list .profile-info-widget {
		text-align: center;
	}
	.appointment-list .profile-info-widget .booking-doc-img {
		margin: 0 0 15px;
	}
	.appointment-list .profile-info-widget .booking-doc-img img {
		border-radius: 50%;
		height: 100px;
		width: 100px;
	}
	.appointment-list .profile-det-info {
		margin-bottom: 15px;
	}
	.appointments .appointment-action {
		margin-top: 0;
	}
	.dct-appoinment .user-tabs .nav-tabs > li > a, .user-tabs .nav-tabs > li > a {
		width: 100%;
		font-size: 14px;
		padding: 10px 15px;
		height: auto;
		margin-right: 0;
	}
	.review-listing .recommend-btn span {
		display: block;
		margin-bottom: 10px;
	}
	.review-listing > ul li .comments-reply {
		margin-left: 0;
	}
	.schedule-nav .nav-tabs li {
		display: block;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		margin-right: 0;
	}
	.fc-header-toolbar .fc-left {
		margin-bottom: 10px;
	}
	.fc-header-toolbar .fc-right {
		margin-bottom: 10px;
	}
	.section-header-three h2 {
		font-size: 15px;
	}
	.section-header-three p {
		font-size: 11px;
	}
	.section-search-3 .banner-info {
		padding: 20px 0 70px;
	}
	.special-box .special-body h4 {
		font-size: 15px;
	}
	.special-box .special-footer .doc-count, .special-box .special-footer .clin-count {
		font-size: 13px;
	}
	.view-all-more .btn {
		font-size: 14px;
		padding: 6px 20px;
	}
	.section-header-three {
		margin-bottom: 30px;
	}
	.doctor-divison h3 {
		font-size: 14px;
		margin-bottom: 15px;
	}
	.doctor-divison p {
		font-size: 10px;
		margin-bottom: 20px;
	}
	.doctor-divison .doc-bok-btn {
		font-size: 12px;
		padding: 5px 10px;
	}
	.book-best-doctors .doctors-body h4 {
		font-size: 16px;
	}
	.book-best-doctors .loc-details img {
		margin-right: 6px;
	}
	.book-best-doctors .available-info {
		font-size: 11px;
	}
	.book-best-doctors .data-info {
		font-size: 10px;
	}
	.book-best-doctors .amt-txt {
		font-size: 14px;
	}
	.book-best-doctors .doctors-body .book-btn {
		font-size: 12px;
		padding: 5px 10px;
		width: 80px;
	}
	.latest-blog .blog-wrap .blog-wrap-body h3 {
		font-size: 16px;
	}
	.latest-blog .blog-wrap .blog-wrap-body p {
		font-size: 12px;
	}
	.latest-blog .blog-footer .read-txt, .latest-blog .blog-footer .cal-txt {
		font-size: 12px;
	}
	.aval-features .feature .info-box h2 {
		font-size: 20px;
	}
	.home-search-section .banner-header h2 {
		font-size: 28px;
	}
	.blog-wrapper .wrap-content:before {
		border-left: 125px solid transparent;
		border-right: 125px solid transparent;
	}
	.feature-col {
		max-width: calc(100% - 30px);
    	width: auto;
	}
}
